import React, { useEffect, useState } from "react";
import { MultiSelect } from "../../../component/CheckOut/Intake/MultiSelect";
import BottomBtnSection from "../components/BottomBtnSection";
import { MixPanelIntake } from "../../../component/API/automation_api";

const AnySymotoms = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  setlastdata,
  goto,
}) => {
  const [SympData, setSympData] = useState([
    "Excess body hair (including your face)",
    "Acne",
    "Hairfall",
    "Irregular periods",
    "Weight fluctuations",
    "IBS",
  ]);

  const [MultiSelectData, setMultiSelectData] = useState([]);
  useEffect(() => {
    setMultiSelectData(pagevalues?.experience_symptoms);
  }, []);
  useEffect(() => {
    setPagevalues((prev) => ({
      ...prev,
      experience_symptoms: [...MultiSelectData],
    }));
    setlastdata((prev) => ({
      ...prev,
      reported_symptoms: MultiSelectData,
    }));
  }, [MultiSelectData]);

  return (
    <>
      <div className="intake_body">
        <div className="container any_symptoms_main">
          <div className="any_symptoms_title">
            Are you experiencing any of the following symptoms?
          </div>
          <div className="any_symp_desc">Select all that apply.</div>
          <MultiSelect
            {...{
              list: SympData,
              onMultiClick: (el) => setMultiSelectData(el),
              MultiSelectData: MultiSelectData,
              multisel: true,
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              // if (pagevalues.experience_symptoms.length <= 0) {
              //   return
              // }
              if (singleBeginRange) {
                setRangeCount(4);
              } else {
                MixPanelIntake({
                  step_name:
                    "Are you experiencing any of the following symptoms?",
                  section_name: "Menstrual History",
                  value: pagevalues.experience_symptoms || "N/A",
                  step_index: 4,
                  total_steps: 5,
                  remaining: lastdata,
                });
                if (
                  MultiSelectData.includes(
                    "Excess body hair (including your face)"
                  )
                ) {
                  setRangeCount(11);
                  setPages("excessbodyhair");
                } else {
                  setPages("familyhsitory");
                  setRangeCount(12);
                }
              }
            },
            goBackClick: () => {
              setRangeCount(9);
              setPages("descperois");
            },
          },
        }}
      />
    </>
  );
};

export default AnySymotoms;
