import React, { useEffect, useState } from "react";

export const SkLoader = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount >= 80) {
          clearInterval(interval);
          return 80;
        }
        return prevCount + 1;
      });
    }, 10); // Adjust this time to control the speed of the counter

    return () => clearInterval(interval);
  }, []);

  const circumference = 2 * Math.PI * 9; // Circle circumference, given radius of 9
  const offset = circumference - (count / 100) * circumference;
  return (
    <div
      style={{
        position: "relative",
        width: "40px",
        height: "40px",
      }}
    >
      <svg
        // style={{
        //   position: "absolute",
        //   top: 0,
        //   left: 0,
        //   transform: "rotate(-90deg)",
        // }}
        width="40"
        height="40"
        viewBox="0 0 20 20"
        fill="none"
        className="circle_svg_api"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10"
          cy="10"
          r="9"
          style={{
            transform: "rotate(-20deg)",
            transformOrigin: "center",
          }}
          stroke="#5F1D1B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="56.5"
          strokeDashoffset={56.5 - (count / 100) * 56.5}
        />
        <text
          x="10"
          y="12"
          textAnchor="middle"
          fontSize="5"
          fill="#5F1D1B"
          fontWeight="bold"
          style={{
            transform: "rotate(72deg)",
            transformOrigin: "center",
          }}
        >
          {count}%
        </text>
      </svg>
    </div>
  );
};
