import React, { useEffect } from "react";
import { Icons } from "../../component/Icons/Icons";
import { useState } from "react";
import { dateFormatter } from "../Reports";
import { LinkConverter } from "../../component/API/new_api";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { SkLoader } from "../../component/Skloader/SkLoader";

export const ScansV2 = ({ data }) => {
  const [modaldata, setModaldata] = useState({});
  const options = [
    {
      name: "Upload from files",
      type: "file",
      id: "scan_upload_file",
      accept: ".pdf,image/*",
      svg: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0007 8.33301H10.834"
            stroke="#5F1D1B"
            stroke-width="1.25"
            stroke-linecap="round"
          />
          <path
            d="M1.66797 5.79102C1.66797 5.05518 1.66797 4.68768 1.7263 4.38102C1.85066 3.72255 2.17058 3.11685 2.64435 2.64294C3.11811 2.16903 3.72371 1.84891 4.38214 1.72435C4.68964 1.66602 5.05797 1.66602 5.79297 1.66602C6.11464 1.66602 6.2763 1.66602 6.4313 1.68018C7.09884 1.74287 7.73196 2.00541 8.24797 2.43352C8.36797 2.53268 8.4813 2.64602 8.70964 2.87435L9.16797 3.33268C9.84797 4.01268 10.188 4.35268 10.5946 4.57852C10.8182 4.70309 11.0553 4.80152 11.3013 4.87185C11.7496 4.99935 12.2305 4.99935 13.1913 4.99935H13.503C15.6963 4.99935 16.7938 4.99935 17.5063 5.64102C17.5724 5.69935 17.6346 5.76157 17.693 5.82768C18.3346 6.54018 18.3346 7.63768 18.3346 9.83102V11.666C18.3346 14.8085 18.3346 16.3802 17.358 17.356C16.3813 18.3318 14.8105 18.3327 11.668 18.3327H8.33464C5.19214 18.3327 3.62047 18.3327 2.64464 17.356C1.6688 16.3793 1.66797 14.8085 1.66797 11.666V5.79102Z"
            stroke="#5F1D1B"
            stroke-width="1.25"
          />
        </svg>
      ),
    },
    {
      name: "Take a picture",
      type: "file",
      capture: true,
      id: "scan_capture",
      accept: ".pdf,image/*",
      svg: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99902 13.334C11.3797 13.334 12.499 12.2147 12.499 10.834C12.499 9.45327 11.3797 8.33398 9.99902 8.33398C8.61831 8.33398 7.49902 9.45327 7.49902 10.834C7.49902 12.2147 8.61831 13.334 9.99902 13.334Z"
            stroke="#5F1D1B"
            stroke-width="1.25"
          />
          <path
            d="M8.14768 17.5H11.851C14.4518 17.5 15.7527 17.5 16.6868 16.8875C17.09 16.6235 17.4372 16.2825 17.7085 15.8842C18.3327 14.9675 18.3327 13.69 18.3327 11.1367C18.3327 8.58337 18.3327 7.30587 17.7085 6.38921C17.4372 5.99087 17.09 5.64992 16.6868 5.38587C16.0868 4.99171 15.3352 4.85087 14.1843 4.80087C13.6352 4.80087 13.1627 4.39254 13.0552 3.86337C12.973 3.47576 12.7596 3.1284 12.4509 2.88C12.1422 2.63159 11.7572 2.49738 11.361 2.50004H8.63768C7.81435 2.50004 7.10518 3.07087 6.94352 3.86337C6.83602 4.39254 6.36352 4.80087 5.81435 4.80087C4.66435 4.85087 3.91268 4.99254 3.31185 5.38587C2.90896 5.65001 2.56208 5.99095 2.29102 6.38921C1.66602 7.30587 1.66602 8.58254 1.66602 11.1367C1.66602 13.6909 1.66602 14.9667 2.29018 15.8842C2.56018 16.2809 2.90685 16.6217 3.31185 16.8875C4.24602 17.5 5.54685 17.5 8.14768 17.5Z"
            stroke="#5F1D1B"
            stroke-width="1.25"
          />
          <path
            d="M15.8324 8.33398H14.999"
            stroke="#5F1D1B"
            stroke-width="1.25"
            stroke-linecap="round"
          />
        </svg>
      ),
    },
  ];
  const [uploadmodal, setUploadmodal] = useState(false);
  const [ScanList, setScanList] = useState([]);
  const store = useSelector((state) => state);
  const [imagetoverify, setimagetoverify] = useState({});
  const [upload2ctr, setUpload2ctr] = useState(false);
  const formDataImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadmodal(false);
        let obj = {
          name: `Scan_${dateFormatter(new Date(), "DDMMSYYYY")}`,
          base_64: reader.result,
          type: file.type.split("/")[1],
          loading: 0,
          _id: ScanList.length + 1,
        };
        setimagetoverify(obj);
        setUpload2ctr(true);
        // setScanList((prev) => [...prev, obj]);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (data.length) {
      setScanList(data);
    }
  }, [data]);

  const DoneScan = () => {
    setScanList((prev) => [...prev, imagetoverify]);
    setUpload2ctr(false);
    LinkConverter(
      {
        ...imagetoverify,
      },
      store.checktoken_data.token,
      (link) => {
        console.log(link);
        setScanList((prev) =>
          prev.map((item) =>
            item._id === imagetoverify._id
              ? { ...item, url: link, loading: 100 }
              : item
          )
        );
      },
      (err) => {},
      (loading) => {
        console.log(loading);

        setScanList((prev) =>
          prev.map((item) =>
            item._id === imagetoverify._id
              ? { ...item, loading: loading }
              : item
          )
        );
      }
    );
  };
  // console.log(imagetoverify);

  return (
    <>
      {modaldata?.name ? (
        <div className="prescriptionsv2_modal_section">
          <div>
            <div className="prescriptionsv2_modal_header">
              <div>{modaldata?.name}</div>
              <div
                onClick={() => {
                  setModaldata({});
                }}
                className="prescriptionsv2_modal_header_close"
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.292787 1.29259C0.480314 1.10512 0.734622 0.999806 0.999786 0.999806C1.26495 0.999806 1.51926 1.10512 1.70679 1.29259L5.99979 5.58559L10.2928 1.29259C10.385 1.19708 10.4954 1.1209 10.6174 1.06849C10.7394 1.01608 10.8706 0.988496 11.0034 0.987342C11.1362 0.986189 11.2678 1.01149 11.3907 1.06177C11.5136 1.11205 11.6253 1.18631 11.7192 1.2802C11.8131 1.37409 11.8873 1.48574 11.9376 1.60864C11.9879 1.73154 12.0132 1.86321 12.012 1.99599C12.0109 2.12877 11.9833 2.25999 11.9309 2.382C11.8785 2.504 11.8023 2.61435 11.7068 2.70659L7.41379 6.99959L11.7068 11.2926C11.8889 11.4812 11.9897 11.7338 11.9875 11.996C11.9852 12.2582 11.88 12.509 11.6946 12.6944C11.5092 12.8798 11.2584 12.985 10.9962 12.9873C10.734 12.9895 10.4814 12.8888 10.2928 12.7066L5.99979 8.41359L1.70679 12.7066C1.51818 12.8888 1.26558 12.9895 1.00339 12.9873C0.741188 12.985 0.490376 12.8798 0.304968 12.6944C0.11956 12.509 0.0143906 12.2582 0.0121121 11.996C0.00983372 11.7338 0.110629 11.4812 0.292787 11.2926L4.58579 6.99959L0.292787 2.70659C0.105316 2.51907 0 2.26476 0 1.99959C0 1.73443 0.105316 1.48012 0.292787 1.29259Z"
                    fill="#5F1D1B"
                  />
                </svg>
              </div>
            </div>
            <div className="prescriptionsv2_modal_body">
              {modaldata?.url.endsWith(".pdf") ? (
                <iframe src={`${modaldata?.url}#toolbar=0&navpanes=0`} />
              ) : (
                <img
                  src={modaldata?.url}
                  onError={(e) => {
                    e.target.onerror = null; // Prevents looping if fallback also fails
                    e.target.src =
                      "https://placehold.co/200x200/939393/5f1d1b?text=NoImageFound"; // Set fallback image
                  }}
                />
              )}
            </div>
            <div className="prescriptionsv2_modal_footer">
              <div className="reportsv2_modal_footer_btn">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 10.0003L12.1667 4.16699V7.50033C6.33333 8.33366 3.83333 12.5003 3 16.667C5.08333 13.7503 8 12.417 12.1667 12.417V15.8337L18 10.0003Z"
                    fill="#5F1D1B"
                  />
                </svg>
                Share
              </div>
              <div
                className="prescriptionsv2_modal_footer_btn download"
                onClick={() => {
                  // console.log(modaldata);
                  // setModaldata(modaldata);
                  window.open(modaldata?.url, "_blank");
                  // downloadFile(modaldata?.url);
                }}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5 16.6663H15.5M10.5 3.33301V13.333M10.5 13.333L13.4167 10.4163M10.5 13.333L7.58333 10.4163"
                    stroke="#FFFBF2"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Download
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {uploadmodal ? (
        <div
          className="scanv2_upload_modal"
          // onClick={() => {
          //   setUploadmodal(false);
          // }}
        >
          <div>
            <div
              className="scanv2_upload_modal_box"
              // onClick={(e) => {
              //   e.stopPropagation();
              //   e.preventDefault();
              // }}
            >
              <div className="scanv2_upload_modal_box_title">
                Upload new scan
              </div>
              <div className="scanv2_upload_modal_box_list">
                {options.map((el, i) => (
                  <label key={i} htmlFor={el?.id}>
                    <input
                      type={el.type}
                      id={el?.id}
                      capture={el?.capture}
                      accept={el?.accept || ""}
                      multiple={false}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                      onChange={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        formDataImage(e);
                      }}
                    />
                    <div className="scanv2_upload_modal_box_inps">
                      <div
                        className="input_icon"
                        onClick={() => {
                          // setMsgType(el.type);
                        }}
                      >
                        {el.svg}
                      </div>
                      <div className="input_name">{el.name}</div>
                      <div className="input_svg">
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.292787 9.70741C0.105316 9.51988 0 9.26557 0 9.00041C0 8.73524 0.105316 8.48094 0.292787 8.29341L3.58579 5.00041L0.292787 1.70741C0.110629 1.5188 0.00983372 1.2662 0.0121121 1.00401C0.0143906 0.741809 0.11956 0.490997 0.304968 0.305589C0.490376 0.120181 0.741189 0.0150115 1.00339 0.0127331C1.26558 0.0104547 1.51818 0.111249 1.70679 0.293408L5.70679 4.29341C5.89426 4.48094 5.99957 4.73524 5.99957 5.00041C5.99957 5.26557 5.89426 5.51988 5.70679 5.70741L1.70679 9.70741C1.51926 9.89488 1.26495 10.0002 0.999786 10.0002C0.734622 10.0002 0.480314 9.89488 0.292787 9.70741Z"
                            fill="#5F1D1B"
                          />
                        </svg>
                      </div>
                    </div>
                  </label>
                ))}
              </div>
              <div
                className="scanv2_upload_modal_btn_close"
                onClick={() => {
                  setUploadmodal(false);
                }}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {upload2ctr ? (
        <div className="scanv2_verify_modal">
          <div>
            <div className="scanv2_verify_modal_top">
              <div
                className="scanv2_verify_modal_top_svg"
                onClick={() => {
                  setimagetoverify({});
                  setUpload2ctr(false);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.7072 14.7074C12.8947 14.5199 13 14.2656 13 14.0004C13 13.7352 12.8947 13.4809 12.7072 13.2934L9.41421 10.0004L12.7072 6.70741C12.8894 6.5188 12.9902 6.2662 12.9879 6.00401C12.9856 5.74181 12.8804 5.491 12.695 5.30559C12.5096 5.12018 12.2588 5.01501 11.9966 5.01273C11.7344 5.01045 11.4818 5.11125 11.2932 5.29341L7.29321 9.29341C7.10574 9.48094 7.00043 9.73524 7.00043 10.0004C7.00043 10.2656 7.10574 10.5199 7.29321 10.7074L11.2932 14.7074C11.4807 14.8949 11.735 15.0002 12.0002 15.0002C12.2654 15.0002 12.5197 14.8949 12.7072 14.7074Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            {imagetoverify.type == "pdf" ? (
              <iframe src={`${imagetoverify.base_64}#toolbar=0&navpanes=0`} />
            ) : (
              <img src={imagetoverify.base_64} />
            )}
            <div className="scanv2_verify_ctr">
              <label htmlFor={"retake"}>
                <input
                  type={"file"}
                  id={"retake"}
                  capture={false}
                  accept={".pdf,image/*"}
                  multiple={false}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={(e) => {
                    formDataImage(e);
                  }}
                />

                <div className="scanv2_verify_retake">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.07934 5.06902C8.87434 1.27902 15.0443 1.31902 18.8623 5.13802C22.6823 8.95802 22.7223 15.131 18.9263 18.926C15.1303 22.721 8.95834 22.682 5.13834 18.862C4.06371 17.792 3.25177 16.4875 2.76629 15.0508C2.28081 13.6142 2.13501 12.0845 2.34034 10.582C2.36726 10.3848 2.47141 10.2064 2.62988 10.086C2.78835 9.96562 2.98815 9.9131 3.18534 9.94002C3.38253 9.96694 3.56095 10.0711 3.68134 10.2296C3.80174 10.388 3.85426 10.5878 3.82734 10.785C3.65302 12.0583 3.77642 13.3547 4.18782 14.5723C4.59923 15.7899 5.28744 16.8954 6.19834 17.802C9.44334 21.046 14.6663 21.065 17.8663 17.866C21.0653 14.666 21.0463 9.44302 17.8023 6.19802C14.5593 2.95602 9.33934 2.93502 6.13934 6.13002L6.88734 6.13302C6.98583 6.13348 7.08327 6.15334 7.17409 6.19145C7.2649 6.22957 7.34733 6.2852 7.41664 6.35517C7.48596 6.42514 7.54082 6.50807 7.57809 6.59924C7.61536 6.69041 7.6343 6.78803 7.63384 6.88652C7.63338 6.98501 7.61353 7.08245 7.57541 7.17327C7.53729 7.26409 7.48166 7.34651 7.41169 7.41583C7.34173 7.48515 7.25879 7.54 7.16762 7.57727C7.07645 7.61454 6.97883 7.63348 6.88034 7.63302L4.33434 7.62102C4.13664 7.61997 3.94735 7.5409 3.80765 7.40101C3.66795 7.26112 3.58913 7.07172 3.58834 6.87402L3.57534 4.33002C3.57481 4.23153 3.59369 4.1339 3.6309 4.0427C3.6681 3.95151 3.72291 3.86854 3.79218 3.79852C3.86145 3.7285 3.94384 3.67282 4.03463 3.63464C4.12542 3.59647 4.22285 3.57655 4.32134 3.57602C4.41983 3.5755 4.51746 3.59438 4.60866 3.63158C4.69985 3.66879 4.78283 3.72359 4.85284 3.79286C4.92286 3.86214 4.97854 3.94452 5.01672 4.03531C5.05489 4.12611 5.07481 4.22353 5.07534 4.32202L5.07934 5.06902Z"
                      fill="#5F1D1B"
                    />
                  </svg>
                </div>
              </label>
              <div
                className="scanv2_verify_tick"
                onClick={() => {
                  DoneScan();
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.00015 16.17L5.53015 12.7C5.34317 12.513 5.08957 12.408 4.82515 12.408C4.56072 12.408 4.30712 12.513 4.12015 12.7C3.93317 12.887 3.82813 13.1406 3.82812 13.405C3.82813 13.5359 3.85391 13.6656 3.90402 13.7866C3.95412 13.9075 4.02756 14.0174 4.12015 14.11L8.30015 18.29C8.69015 18.68 9.32015 18.68 9.71015 18.29L20.2901 7.71002C20.4771 7.52304 20.5822 7.26944 20.5822 7.00502C20.5822 6.74059 20.4771 6.48699 20.2901 6.30002C20.1032 6.11304 19.8496 6.008 19.5851 6.008C19.3207 6.008 19.0671 6.11304 18.8801 6.30002L9.00015 16.17Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="scanv2_section">
        {ScanList.length == 0 ? (
          <div className="scanv2_section_emp">
            <Icons img_var={"scanv2_empty"} />
            <div className="scanv2_txt">
              Nothing to show! Once your scans become available or you upload,
              they will be displayed here.
            </div>
            <div
              className="upload_btn"
              onClick={() => {
                setUploadmodal(true);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0003 10.8317H10.8337V14.9984C10.8337 15.2194 10.7459 15.4313 10.5896 15.5876C10.4333 15.7439 10.2213 15.8317 10.0003 15.8317C9.77931 15.8317 9.56735 15.7439 9.41107 15.5876C9.25479 15.4313 9.16699 15.2194 9.16699 14.9984V10.8317H5.00033C4.77931 10.8317 4.56735 10.7439 4.41107 10.5876C4.25479 10.4313 4.16699 10.2194 4.16699 9.99837C4.16699 9.77736 4.25479 9.5654 4.41107 9.40912C4.56735 9.25284 4.77931 9.16504 5.00033 9.16504H9.16699V4.99837C9.16699 4.77736 9.25479 4.5654 9.41107 4.40912C9.56735 4.25284 9.77931 4.16504 10.0003 4.16504C10.2213 4.16504 10.4333 4.25284 10.5896 4.40912C10.7459 4.5654 10.8337 4.77736 10.8337 4.99837V9.16504H15.0003C15.2213 9.16504 15.4333 9.25284 15.5896 9.40912C15.7459 9.5654 15.8337 9.77736 15.8337 9.99837C15.8337 10.2194 15.7459 10.4313 15.5896 10.5876C15.4333 10.7439 15.2213 10.8317 15.0003 10.8317Z"
                  fill="#FFFBF2"
                />
              </svg>
              Upload
            </div>
          </div>
        ) : (
          <>
            <div className="scanv2_section_list">
              {ScanList.map((el, i) => (
                <div
                  className="scanv2_section_list_item"
                  key={i}
                  onClick={() => {
                    // window.open(el.url, "_blank");
                    // console.log(el);
                    setModaldata(el);
                  }}
                >
                  {/* {false ? ( */}
                  {el?.url?.match("http") ? (
                    <>
                      {el?.type == "pdf" ? (
                        <div
                          className="scanv2_section_list_item_image"
                          style={{
                            backgroundImage:
                              `url("https://res.cloudinary.com/doht5pjdp/image/fetch/f_auto,w_500,c_limit/` +
                              el.url +
                              `")`,
                          }}
                        >
                          {/* <iframe src={el.url} /> */}
                          {/* <img src={el.url} /> */}
                        </div>
                      ) : (
                        <div className="scanv2_section_list_item_image">
                          <img src={el.url} />
                        </div>
                      )}
                      <div className="scanv2_section_list_item_type">
                        .{el?.type}
                      </div>
                      <div className="scanv2_section_list_item_name">
                        {el.name}
                      </div>
                      <div className="scanv2_section_list_item_date">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.6">
                            <path
                              d="M3.16667 5.94284H12.5M4.37302 2V3.02869M11.1667 2V3.02857M13.1667 4.82857V12.2C13.1667 13.1941 12.3707 14 11.3889 14H4.27778C3.29594 14 2.5 13.1941 2.5 12.2V4.82857C2.5 3.83445 3.29594 3.02857 4.27778 3.02857H11.3889C12.3707 3.02857 13.1667 3.83445 13.1667 4.82857Z"
                              stroke="#5F1D1B"
                              stroke-width="1.33333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </svg>

                        {dateFormatter(el.date, "WKL, MMS DD")}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="scanv2_section_list_item_image">
                        <SkLoader />
                      </div>

                      <div className="scanv2_section_list_item_type">
                        .{el?.type}
                      </div>
                      <div className="scanv2_section_list_item_name">
                        <Skeleton height="100%" width="100%" />
                      </div>
                      <div className="scanv2_section_list_item_loading">
                        <Skeleton height="100%" width="100%" />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
            <div
              className="scanv2_section_btn"
              onClick={() => {
                setUploadmodal(true);
              }}
            >
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0003 10.8317H10.8337V14.9984C10.8337 15.2194 10.7459 15.4313 10.5896 15.5876C10.4333 15.7439 10.2213 15.8317 10.0003 15.8317C9.77931 15.8317 9.56735 15.7439 9.41107 15.5876C9.25479 15.4313 9.16699 15.2194 9.16699 14.9984V10.8317H5.00033C4.77931 10.8317 4.56735 10.7439 4.41107 10.5876C4.25479 10.4313 4.16699 10.2194 4.16699 9.99837C4.16699 9.77736 4.25479 9.5654 4.41107 9.40912C4.56735 9.25284 4.77931 9.16504 5.00033 9.16504H9.16699V4.99837C9.16699 4.77736 9.25479 4.5654 9.41107 4.40912C9.56735 4.25284 9.77931 4.16504 10.0003 4.16504C10.2213 4.16504 10.4333 4.25284 10.5896 4.40912C10.7459 4.5654 10.8337 4.77736 10.8337 4.99837V9.16504H15.0003C15.2213 9.16504 15.4333 9.25284 15.5896 9.40912C15.7459 9.5654 15.8337 9.77736 15.8337 9.99837C15.8337 10.2194 15.7459 10.4313 15.5896 10.5876C15.4333 10.7439 15.2213 10.8317 15.0003 10.8317Z"
                    fill="#5F1D1B"
                  />
                </svg>
              </div>
              Upload
            </div>
          </>
        )}
      </div>
    </>
  );
};
