import React, { useEffect, useState } from "react";
import {
  addAppoiuntment,
  dateFormatter,
} from "../../../../component/API/new_api";
import CalendlyModal from "../../../../component/Calendly/CalendlyModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCalendlyUrl } from "../../../../utils/utils";
import { useNavigate } from "react-router-dom";

const ConsultCard = ({
  type,
  data,
  ProductImage,
  calendly_deault_url = "",
}) => {
  const store = useSelector((state) => state);
  const [meet_state, setmeet_state] = useState(0);

  const [calendlyModalState, setcalendlyModalState] = useState(false);
  const [curr_active, setcurr_active] = useState("");
  const [calendlyData, setcalendlyData] = useState({
    url: "",
    onSubmit: () => "",
  });
  const navigate = useNavigate();
  const [curr_state, setcurr_state] = useState(1);
  useEffect(() => {
    // setmeet_state(
    //   {
    //     "Order Confirmed": 1,
    //     "Test Booked": 2,
    //     "Sample Collected": 3,
    //     "Reports Available": 4,
    //   }[data.product_status] || 1
    // )
    return () => {};
  }, []);
  console.log({ consult: data });

  return (
    <div className="home_card" type="test">
      <div className="title">
        {ProductImage[data.product_id]?.name || "Consult"}
      </div>
      <div
        className="img_holder"
        style={
          ProductImage
            ? {
                // backgroundImage: "url(" + data?.img + ")",
                backgroundImage:
                  "url(" + ProductImage[data.product_id]?.banner + ")",
              }
            : {}
        }
      >
        {ProductImage[data.product_id]?.banner ? (
          ""
        ) : (
          <div className="svg_floater_main">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 64 65"
              fill="none"
            >
              <path
                d="M64 32.9972C64 50.6712 49.674 64.9972 32 64.9972C14.326 64.9972 0 50.6712 0 32.9972C0 15.3232 14.326 0.997192 32 0.997192V4.99719C16.536 4.99719 4 17.5332 4 32.9972C4 48.4612 16.536 60.9972 32 60.9972C47.464 60.9972 60 48.4612 60 32.9972H64Z"
                fill="#5F1D1B"
                // fill="#1D1D1D"
              />
            </svg>
          </div>
        )}
      </div>
      <div className="details">
        <div className="stage">
          {["Schedule", "Meet"].map((el, i) => (
            <>
              {i == (data.appointment[0]?.calendlyData?.location ? 1 : 0) ? (
                <div className="active">{el}</div>
              ) : (
                <div className="unactive">{el}</div>
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="8"
                viewBox="0 0 5 8"
                fill="none"
              >
                <path
                  d="M1 7L4 4L1 1"
                  stroke="#5F1D1B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </>
          ))}
        </div>

        {data?.product_name ? (
          <div className="product_name">{data.product_name}</div>
        ) : (
          ""
        )}
        {data.appointment[0]?.calendlyData?.location ? (
          <>
            <div className="txt">
              Your call is scheduled for{" "}
              {new Date().toString().split(" ").slice(1, 4) ===
              new Date(data?.appointment[0]?.calendlyData?.startTime)
                .toString()
                .split(" ")
                .slice(1, 4)
                ? "Today"
                : dateFormatter(
                    data?.appointment[0]?.calendlyData?.startTime,
                    "DD/MM/YYYY"
                  )}
            </div>
            <div className="time">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g opacity="0.6">
                  <path
                    d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                    stroke="#5F1D1B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
              {dateFormatter(
                data?.appointment[0]?.calendlyData?.startTime,
                "dddd, MMF DD, hh:mm A"
              )}
              {/* Saturday, May 16, 11:00 AM */}
            </div>
            <div
              className="btn type1"
              onClick={() =>
                window.open(data?.appointment[0]?.calendlyData?.location)
              }
            >
              Join
            </div>
            <div
              className="btn type2"
              onClick={() => {
                let url =
                  calendly_deault_url ||
                  getCalendlyUrl(
                    data?.discount_code + "_" + (data?.product_id || ""),
                    store?.checktoken_data?.customer?.address?.state,
                    data?.product_id || ""
                  );

                setcalendlyModalState(true);
                setcalendlyData({
                  // url: items.calendlyData.eventUrl,
                  // url: "https://calendly.com/arvahealth/testing-events",
                  url: url.link,
                  onSubmit: (evenetData) => {
                    addAppoiuntment(
                      store.checktoken_data.token,
                      data.appointment[0]._id,
                      {
                        ...evenetData,
                        type: data?.product_type,
                        doctor_name: url.name,
                      },
                      (success) => {
                        window.location.reload();
                      },
                      (err) => {
                        toast.error("calendy not updated");
                      }
                    );
                  },
                });
              }}
            >
              Reschedule
            </div>
          </>
        ) : (
          <>
            <div className="txt">
              Schedule your call with Fertility Health Expert.
            </div>

            <div
              className="btn"
              onClick={() => {
                if (!store?.checktoken_data?.customer?.intake) {
                  return navigate("/intake");
                }
                // console.log(
                //   store?.checktoken_data?.customer?.address?.state,
                //   data?.product_id || ""
                // );
                let url =
                  calendly_deault_url ||
                  getCalendlyUrl(
                    data?.discount_code + "_" + (data?.product_id || ""),
                    store?.checktoken_data?.customer?.address?.state,
                    data?.product_id || ""
                  );

                setcalendlyModalState(true);
                setcalendlyData({
                  // url: items.calendlyData.eventUrl,
                  // url: "https://calendly.com/arvahealth/testing-events",
                  url: url.link,
                  onSubmit: (evenetData) => {
                    addAppoiuntment(
                      store.checktoken_data.token,
                      data.appointment[0]._id,
                      {
                        ...evenetData,
                        type: data?.product_type,
                        doctor_name: url.name,
                      },
                      (success) => {
                        window.location.reload();
                      },
                      (err) => {
                        toast.error("calendy not updated");
                      }
                    );
                  },
                });
              }}
            >
              Schedule
            </div>
          </>
        )}
      </div>
      <CalendlyModal
        {...{
          ...calendlyData,
          isOpen: calendlyModalState,
          setIsOpen: (state) => setcalendlyModalState(state),
          // onSubmit: (data) => {
          //
          //   {
          //     "event": {
          //         "uri": "https://api.calendly.com/scheduled_events/da36d149-2402-4b2b-8581-903c90f15c3e"
          //     },
          //     "invitee": {
          //         "uri": "https://api.calendly.com/scheduled_events/da36d149-2402-4b2b-8581-903c90f15c3e/invitees/b96b5765-9153-463a-8cc0-950c9fc3ba44"
          //     }
          // }
          // },
        }}
      />
    </div>
  );
};

export default ConsultCard;
