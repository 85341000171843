import React, { useEffect, useState } from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import { MultiSelect } from "../../../component/CheckOut/Intake/MultiSelect";
import { MixPanelIntake } from "../../../component/API/automation_api";

export const FatherStrength = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  goto,
}) => {
  const [SympData, setSympData] = useState([
    "Easy",
    "Challenging",
    "Challenging due to male factor",
    "I don’t know",
  ]);
  const [MultiSelectData, setMultiSelectData] = useState("");

  useEffect(() => {
    setMultiSelectData(pagevalues?.getpregnentstatus);
  }, []);

  useEffect(() => {
    setPagevalues((prev) => ({
      ...prev,
      getpregnentstatus: MultiSelectData,
    }));
  }, [MultiSelectData]);
  return (
    <>
      <div className="intake_body">
        <div className="container any_symptoms_main">
          <div className="any_symptoms_title">
            How easy was it for them to get pregnant overall?
          </div>
          <div className="any_symp_desc">Take your best guess.</div>
          <MultiSelect
            {...{
              list: SympData,
              onMultiClick: (el) => setMultiSelectData(el),
              MultiSelectData: MultiSelectData,
              multisel: false,
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (!pagevalues.getpregnentstatus) {
                return;
              }
              MixPanelIntake({
                step_name: "How easy was it for them to get pregnant overall?",
                section_name: "Family History",
                value: pagevalues.getpregnentstatus,
                step_index: 2,
                total_steps: 3,
                remaining: lastdata,
              });
              setPages("mommenopause");
              if (singleBeginRange) {
                setRangeCount(2);
              } else {
                setRangeCount(15);
              }
            },
            goBackClick: () => {
              setRangeCount(13);
              setPages("momage");
            },
          },
        }}
      />
    </>
  );
};
