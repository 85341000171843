import React, { useEffect, useState } from "react";
import { MultiSelect } from "../../../component/CheckOut/Intake/MultiSelect";
import BottomBtnSection from "../components/BottomBtnSection";
import { MixPanelIntake } from "../../../component/API/automation_api";

export const DescPeriod = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  goto,
}) => {
  const [SympData, setSympData] = useState([
    "Spotting",
    "Light",
    "Moderate",
    "Heavy",
  ]);

  const [MultiSelectData, setMultiSelectData] = useState("");

  useEffect(() => {
    setMultiSelectData(pagevalues.descperiod);
  }, []);
  useEffect(() => {
    setPagevalues((prev) => ({
      ...prev,
      descperiod: MultiSelectData,
    }));
  }, [MultiSelectData]);
  return (
    <>
      <div className="intake_body">
        <div className="container ofterngetperiod_main">
          <div className="ofterngetperiod_title">
            How would you describe your period?
          </div>

          <MultiSelect
            {...{
              list: SympData,
              onMultiClick: (el) => setMultiSelectData(el),
              MultiSelectData: MultiSelectData,
              multisel: false,
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (!pagevalues.descperiod) {
                return;
              }
              MixPanelIntake({
                step_name: "How would you describe your period?",
                section_name: "Menstrual History",
                value: pagevalues.descperiod,
                step_index: 3,
                total_steps: 5,
                remaining: lastdata,
              });
              if (singleBeginRange) {
                setRangeCount(3);
              } else {
                setRangeCount(10);
              }
              setPages("symptoms");
            },
            goBackClick: () => {
              setRangeCount(8);
              setPages("averageCyclelength");
            },
          },
        }}
      />
    </>
  );
};
