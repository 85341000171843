import React from "react";
import BottomBtnSection from "../components/BottomBtnSection";

const BmiCalculator = ({ setPages, setRangeCount }) => {
  return (
    <>
      <div className="intake_body">
        <div className="container bmi_calculator_main">
          <div className="bmi_calculator_title">Let’s calculate your BMI</div>
          <div className="bmi_calculator_desc">
            Input values that are closest to you right now. Don’t worry, it
            doesn’t need to be perfect!
          </div>
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              setRangeCount(3);
              setPages("tall");
            },
            goBackClick: () => {
              // setBeginBtn(false);
              setRangeCount(1);
              setPages("birthdate");
            },
          },
        }}
      />
    </>
  );
};

export default BmiCalculator;
