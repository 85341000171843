import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavbarMain } from "../../component/NavbarMain";
import { BottomNav } from "../../component/BottomNav";
import { useNavigate } from "react-router-dom";
import {
  CheckTokenUser,
  Series,
  get_blogs_list,
} from "../../component/API/api";

import {
  MixPanelJoinCommunity,
  MixPanelReferal,
  MixPanelUsertype,
  userProperties,
} from "../../component/API/automation_api";
import { HomeNewHeader } from "./components/HomeNewHeader";
import HomeNewNext from "./components/HomeNewNext";
import HomeNewUpcomin from "./components/HomeNewUpcomin";
import { checkToken, getGuides } from "../../component/API/new_api";
import GuideCard from "./components/Cards/GuideCard";
import SlickSlider from "../../component/SlickSlider/SlickSlider";

export const HomeNew = ({ setloading, ProductImages }) => {
  const [guide_data, setguide_data] = useState({});
  const [pageloaded, setpageloaded] = useState(false);
  const [pagevalues, setPagevalues] = useState({
    name: "",
  });
  const [show_next, setshow_next] = useState({});
  const store = useSelector((state) => state);

  // const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    document.getElementById("title").innerHTML = "Arva Health";
  }, []);
  // const [NavBarActive, setNavBarActive] = useState("");
  const navigate = useNavigate();
  const [AllData, setAllData] = useState({
    last_recorder_order: {},
    last_order: {
      published: false,
    },
    last_intake: {},
    user_data: {},
    base_color: "#5F1D1B",
    linear_grad: "linear-gradient(180deg, #FFECCB 0%, #FFB88F 100%)",
    fert_name: "",
    extradata: {
      join_community: false,
      guide: false,
      chat_council: false,
    },
    user_clicked: {},
    discount: {},
    third_obj: {
      ac_title: "",
      title: "",
      desc: "",
    },
  });

  const [FurtherDataMixPanel, setFurtherDataMixPanel] = useState({
    sign_up_date: "",
    user_id: "",
    last_order_type: "",
    name: "",
    birthdate: "",
    is_test_user: false,
    user_current_goal: "",
    is_paid_user: false,
    reported_symptoms: "",
    reported_conditions: "",
    is_ultrasound_done: false,
    is_birth_control: false,
  });
  const [orders, setorders] = useState({
    completed: [],
    inprogress: [],
  });
  const [BlankScreen, setBlankScreen] = useState(false);

  const process_me = ({ success, token }) => {
    if (success.customer == null) {
      navigate("/404");
    }
    MixPanelUsertype(success);

    let t_completed = [];
    let t_inprogress = [];
    success.orderItems.filter((item) =>
      item.product_status.toLowerCase() == "order completed"
        ? // ||
          //   item.product_status.toLowerCase() == "reports published"
          t_completed.push(item)
        : t_inprogress.push(item)
    );
    let current_date = new Date();
    // current_date.setDate(current_date.getDate() - 2);
    current_date.setHours(0, 0, 0, 0);
    t_inprogress
      .map((el) => {
        if (["coaching", "consult"].includes(el.product_type.toLowerCase())) {
          return el.appointment.map((el1) => {
            return {
              ...el,
              appointment: [el1],
              test: true,
              not_show: true,
            };
          });
        } else if (["guide"].includes(el.product_type.toLowerCase())) {
          return {};
        } else {
          return {
            ...el,
            not_show: false,
          };
        }
      })
      .flat();
    t_inprogress = t_inprogress
      .sort(
        (a, b) =>
          new Date(a.consideredDate).valueOf() -
          new Date(b.consideredDate).valueOf()
      )
      .reverse();
    let orders = {
      care_history: t_completed,
      upcoming: [],
      next: [],
    };
    t_inprogress.map((el) => {
      if (el?.consideredDate) {
        let date = new Date(el?.consideredDate);
        date.setHours(0, 0, 0, 0);
        // if (date?.valueOf() >= current_date?.valueOf()) {
        orders.next.push(el);
        // } else {
        //   orders.upcoming.push(el);
        // }
      } else if (el?.appointment) {
        let date = new Date(el?.appointment[0]?.calendlyData?.consideredDate);
        date.setHours(0, 0, 0, 0);
        if (date?.valueOf() >= current_date?.valueOf()) {
          orders.next.push(el);
        } else {
          orders.upcoming.push(el);
        }
      } else {
        // orders.upcoming.push(el);
      }
      return null;
    });
    const order_type = ["test", "consult", "coaching"];

    orders.upcoming.sort((a, b) => {
      return (
        order_type.indexOf(a.product_type) - order_type.indexOf(b.product_type)
      );
    });
    let tt_show_next = t_inprogress.slice(0, 1)[0];
    if (orders.next.length == 0) {
      orders.next.push(orders.upcoming.shift());
    }
    setorders({
      ...orders,
      mext: orders.next.sort(
        (a, b) =>
          new Date(
            a.consideredDate ||
              a.appointment[0].calendlyData.consideredDate ||
              a.orderDate
          ).valueOf() -
          new Date(
            b.consideredDate ||
              b.appointment[0].calendlyData.consideredDate ||
              b.orderDate
          ).valueOf()
      ),
      completed: t_completed,
      inprogress: t_inprogress.slice(1),
    });
    dispatch({
      type: "setCheckToken",
      payload: { ...success, token: token },
    });
  };

  const check_user_data = (token) => {
    setpageloaded(false);
    checkToken(
      token || "",
      (success) => {
        localStorage.setItem("user_me", JSON.stringify({ success, token }));
        process_me({ success, token });
        setpageloaded(true);
      },
      (err) => {}
    );
  };
  useEffect(() => {
    setpageloaded(false);

    const user_me = localStorage.getItem("user_me");
    if (user_me) {
      process_me(JSON.parse(user_me));
    }

    if (store.checktoken_data.token) {
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        navigate("/login");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);

  useEffect(() => {
    let obj = {};
    const checkGoal =
      AllData?.user_data?.tracking_arva ||
      AllData?.last_intake?.data?.tracking_arva;
    if (["I’m exploring options like Egg Freezing"].includes(checkGoal)) {
      obj = {
        fert_name: "Fertility preservation",
        base_color: "#5F1D1B",
        linear_grad: "linear-gradient(180deg, #FFE3DB 0%, #FFA977 100%)",
        third_obj: {
          ac_title: "Freeze your eggs",
          title: "Partner clinics in your city",
          desc: "Arva-certified, curated quality clinics, with transparent pricing, an Egg Freezing concierge service, and 24x7 support (all kinds!).",
        },
      };
    } else if (
      [
        "Just curious to learn about my fertility",
        "I don’t want biological kids. I’m here to learn more about my body.",
      ].includes(checkGoal)
    ) {
      obj = {
        fert_name: "Proactive fertility care",
        base_color: "#2B3A33",
        linear_grad: "linear-gradient(180deg, #EBF5DD 0%, #E7EDE0 100%)",
        third_obj: {
          ac_title: "Ongoing fertility care",
          title: "Optimise your fertility health",
          desc: "Evidence-based programs with holisitic health coaches designed to optimise your health and maximise your conception potential.",
        },
      };
    } else if (
      [
        "I want kids but I won’t get started for at least another year",
      ].includes(checkGoal)
    ) {
      obj = {
        fert_name: "Pre-conception",
        base_color: "#5F1D1B",
        linear_grad: "linear-gradient(180deg, #FFECCB 0%, #FFB88F 100%)",
        third_obj: {
          ac_title: "Ongoing fertility care",
          title: "Optimise your fertility health",
          desc: "Evidence-based programs with holisitic health coaches designed to optimise your health and maximise your conception potential.",
        },
      };
    } else if (
      [
        "I’m trying for kids as we speak (with fertility treatment)",
        "I’m trying for kids as we speak (without assistance)",
      ].includes(checkGoal)
    ) {
      obj = {
        fert_name: "Conception",
        base_color: "#5F1D1B",
        linear_grad:
          "linear-gradient(180deg, #FFF0D7 0%, #F9D6B9 61.5%, #F2C0B2 100%)",
        third_obj: {
          ac_title: "Ongoing fertility care",
          title: "Partner clinics in your city",
          desc: "Arva-certified, curated quality clinics, with transparent pricing, a concierge service, and 24x7 support (all kinds!).",
        },
        // linear_grad: "linear-gradient(180deg, #FFECCB 0%, #FFB88F 100%)",
      };
    } else {
      obj = {
        fert_name: "",
        base_color: "#5F1D1B",
        linear_grad: "#FFFBF2",
        third_obj: {
          ac_title: "Freeze your eggs",
          title: "Partner clinics in your city",
          desc: "Arva-certified, curated quality clinics, with transparent pricing, an Egg Freezing concierge service, and 24x7 support (all kinds!).",
        },
      };
    }
    setAllData((prev) => ({
      ...prev,
      ...obj,
    }));
  }, [FurtherDataMixPanel]);
  // const [guide_bought, setguide_bought] = useState(second)
  //
  useEffect(() => {
    if (store?.checktoken_data?.token) {
      getGuides(
        store?.checktoken_data?.token,
        (data) => {
          let guide = data?.filter(
            (el) => el.slug === "comprehensive-egg-freezing-guide"
          )[0];
          let bought = store.checktoken_data.orderItems.filter(
            (el) => el.product_id.match("GU01") || el.product_id.match("DC02")
          );
          setguide_data(
            guide
              ? {
                  ...guide,
                  bought: bought.length > 0,
                }
              : {}
          );
        },
        () => {}
      );
    }
    // }, []);
  }, [store?.checktoken_data]);
  console.log("next", orders.next);
  return (
    <>
      {BlankScreen ? (
        ""
      ) : (
        <>
          <div className="home_new_main">
            <div className="main_section">
              <div className="container">
                <HomeNewHeader
                  name={
                    store?.checktoken_data?.customer?.user?.first_name || ""
                  }
                />
                <HomeNewNext
                  ProductImages={ProductImages}
                  card_to_show={
                    ["test", "consultation", "coaching", "clinic", "guide"][1]
                  }
                  guide_data={
                    pageloaded
                      ? {
                          ...guide_data,
                          img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/guide.png",
                          title: "Egg Freezing Guide 101",
                          chip: "Guide",
                          btnTxt: "Read",
                          description:
                            "Find people on a similar journey, no matter where you are in your fertility journey.",

                          btnType: "type1",
                        }
                      : {}
                  }
                  data={orders?.next ? orders.next.filter((el) => el) : []}
                  // data={[]}
                />
                {orders?.upcoming?.length > 0 ? (
                  <HomeNewUpcomin
                    data={[...orders.upcoming]}
                    hide={{}}
                    ProductImages={ProductImages}
                  />
                ) : (
                  ""
                )}
                {orders?.care_history?.length > 0 ? (
                  <div
                    className="care_history"
                    onClick={() => navigate("/care-history")}
                  >
                    Care History
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.29279 14.7069C7.10532 14.5194 7 14.2651 7 13.9999C7 13.7348 7.10532 13.4804 7.29279 13.2929L10.5858 9.99992L7.29279 6.70692C7.11063 6.51832 7.00983 6.26571 7.01211 6.00352C7.01439 5.74132 7.11956 5.49051 7.30497 5.3051C7.49038 5.11969 7.74119 5.01452 8.00339 5.01224C8.26558 5.00997 8.51818 5.11076 8.70679 5.29292L12.7068 9.29292C12.8943 9.48045 12.9996 9.73475 12.9996 9.99992C12.9996 10.2651 12.8943 10.5194 12.7068 10.7069L8.70679 14.7069C8.51926 14.8944 8.26495 14.9997 7.99979 14.9997C7.73462 14.9997 7.48031 14.8944 7.29279 14.7069Z"
                        fill="#5F1D1B"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="container ">
              <div className="reffer_title">For You</div>
              <SlickSlider
                list={[
                  orders?.next?.filter((el) => el).length > 0
                    ? {
                        img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/guide.png",
                        title: "Egg Freezing Guide 101",
                        chip: "Guide",
                        btnTxt: "Read",
                        description:
                          "Find people on a similar journey, no matter where you are in your fertility journey.",
                      }
                    : null,
                  {
                    img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/for%20you/img1.png",
                    title: "A community that gets it",

                    btnTxt: "Join",
                    description:
                      "Find people on a similar journey, no matter where you are in your fertility journey.",
                    goto: () => {
                      MixPanelJoinCommunity();
                      window.open(
                        "https://chat.whatsapp.com/HCpqQhJQLpx0vMEO6sEjT4"
                      );
                    },
                  },
                  {
                    img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/Frame%20723.png",
                    title: "Get referred to the best clinics",
                    description:
                      "If you decide fertility treatment is right for you, you’ll be referred to our partner clinics.",
                    goto: () => {
                      navigate("/clinic/all");
                    },
                    btnTxt: "Read",
                  },
                  {
                    img: "https://arva-lab-reports.s3.ap-south-1.amazonaws.com/Products/png/productimage_1708497852389.png",
                    title: "Give a friend 20% off",
                    description:
                      "If you believe every person should make informed decisions about their reproductive future—do share the love with family & friends. You have no idea who might need it!",
                    goto: () => {
                      window.open(
                        "https://api.whatsapp.com/send/?text=" +
                          encodeURIComponent(
                            `Hi there! 👋\n\nI wanted to share something exciting with you. If you're looking for quality health products, check out Arva Health! 🏥✨\n\nAs a friend of mine, you can get a special 25% discount on your order! Just use the code *FRIENDSOFARVA* at checkout. 🛒💰\n\nHere’s the link to their website: www.arva.health\n\nHappy shopping and stay healthy! 🌿`
                          )
                      );
                    },
                    btnTxt: "Refer",
                  },
                ]
                  .filter((el) => el)
                  .map((el) => (
                    <GuideCard
                      data={{
                        img: el.img,
                        chip: el?.chip || "",
                        title: el?.title || "",
                        description: el?.description || "",
                        goto: el?.goto || "",
                        btnTxt: el?.btnTxt || "",
                        btnType: "type3",
                      }}
                      guide_data={guide_data}
                    />
                  ))}
              />
              {/* <div className="home_referal_section">
                <div
                  className="image"
                  style={{
                    backgroundImage:
                      "url(https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/Frame%20750.png)",
                  }}
                ></div>
                {/* <img src="" /> */}
              {/* <div className="home_referal_title">A community that gets it</div>
              <div className="home_referal_desc">
                Find people on a similar journey, no matter where you are in
                your fertility journey.
              </div>
              <div
                className="btn type2"
                style={{ margin: "16px 0 0 0" }}
                onClick={() => {
                  // navigate("/referral");
                  // toast.success("Error occured")
                  MixPanelJoinCommunity();
                  window.open(
                    "https://chat.whatsapp.com/HCpqQhJQLpx0vMEO6sEjT4"
                  );
                }}
              >
                Join
              </div> */}
              {/* </div> */}
              {/* <div className="home_referal_section">
                <img src="https://arva-lab-reports.s3.ap-south-1.amazonaws.com/Products/png/productimage_1708497852389.png" />
                <div className="home_referal_title">Give a friend 20% off</div>
                <div className="home_referal_desc">
                  If you believe every person should make informed decisions
                  about their reproductive future—do share the love with family
                  & friends. You have no idea who might need it!
                </div>
                <div
                  className="btn type2"
                  style={{ margin: "16px 0 0 0" }}
                  onClick={() => {
                    navigate("/referral");
                    // toast.success("Error occured")
                  }}
                >
                  Refer
                </div>
              </div> */}
              {/* <div className="home_referal_section">
                <img src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/Frame%20723.png" />
                <div className="home_referal_title">
                  Get referred to the best clinics
                </div>
                <div className="home_referal_desc">
                  If you decide fertility treatment is right for you, you’ll be
                  referred to our partner clinics.
                </div>
                <div
                  className="btn type2"
                  style={{ margin: "16px 0 0 0" }}
                  onClick={() => {
                    navigate("/clinic/all");
                  }}
                >
                  Explore
                </div>
              </div> */}
            </div>
          </div>
          <BottomNav />
        </>
      )}
    </>
  );
};
