import React, { useEffect, useState } from "react";

import { MultiSelect } from "../../../component/CheckOut/Intake/MultiSelect";
import BottomBtnSection from "../components/BottomBtnSection";
import {
  MixPanelIntake,
  MixPanelIntakeCompleted,
} from "../../../component/API/automation_api";

export const ExcessBodyHair = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  setBeginBtn,
  singleBeginRange,
  setBeginPage,
  lastdata,
  goto,
}) => {
  const [SympData, setSympData] = useState([
    "Upper lip",
    "Chin",
    "Chest",
    "Cheeks / Sideburns",
    "Upper abdomen",
    "Lower abdomen",
    "Legs",
    "Arms",
    "Upper back",
    "Lower back",
  ]);
  const [MultiSelectData, setMultiSelectData] = useState([]);

  useEffect(() => {
    setMultiSelectData(pagevalues?.exphair);
  }, []);
  useEffect(() => {
    setPagevalues((prev) => ({
      ...prev,
      exphair: [...(MultiSelectData || [])],
    }));
  }, [MultiSelectData]);
  return (
    <>
      <div className="intake_body">
        <div className="container ofterngetperiod_main">
          <div className="ofterngetperiod_title">
            Select all the areas where you experience excess body hair
          </div>

          <MultiSelect
            {...{
              list: SympData,
              onMultiClick: (el) => setMultiSelectData(el),
              MultiSelectData: MultiSelectData,
              multisel: true,
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              // if (pagevalues.exphair.length <= 0) {
              //   return;
              // }
              MixPanelIntake({
                step_name:
                  "Select all the areas where you experience excess body hair",
                section_name: "Menstrual History",
                value: pagevalues.exphair || "N/A",
                step_index: 5,
                total_steps: 5,
                remaining: lastdata,
              });
              MixPanelIntakeCompleted({
                section_name: "Menstrual History",
                remaining: lastdata,
              });
              setBeginBtn(true);
              if (singleBeginRange) {
                setRangeCount(5);
              } else {
                setRangeCount(12);
              }

              setPages("familyhsitory");
            },
            goBackClick: () => {
              setRangeCount(10);
              setPages("symptoms");
            },
          },
        }}
      />
    </>
  );
};
