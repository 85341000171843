import React, { useEffect, useState } from "react";
import { NavbarMain } from "../component/NavbarMain";
import TrackingArva from "../component/CheckOut/Intake/TrackingArva";
import { ProfileType } from "./ProfileType/ProfileType";
import { useNavigate, useLocation } from "react-router-dom";
import { CheckTokenUser } from "../component/API/api";
import {
  MixPanelLogOut,
  MixPanelReferal,
  MixPanelUsertype,
  userProperties,
} from "../component/API/automation_api";
import { useDispatch, useSelector } from "react-redux";
import { BottomNav } from "../component/BottomNav";
import { date_formatter } from "../component/CustInput/CustInput";
import { checkToken } from "../component/API/new_api";

export const Profile = ({ setloading }) => {
  const store = useSelector((state) => state);
  console.log(store);

  const navigate = useNavigate();
  const location = useLocation();
  const [pagevalues, setPagevalues] = useState({
    name: "",
  });
  const [NavBarActive, setNavBarActive] = useState("Profile");
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();

  const [TrackFertility, setTrackFertility] = useState(false);
  const [PageValueintake, setPageValueintake] = useState({
    birth_date: "",
    tall_feet: "",
    tall_inch: "",
    weight: "",
    waist_inch: "",
    experience_symptoms: [],
    oftengetperois: [],
    averagecyclelength: "",
    descperiod: "",
    exphair: [],
    momage: "",
    getpregnentstatus: "",
    mommenopause: "",
    prev_dignosed_with_PCOS: "",
    birthcontrol: "",
    medicalcondition: "",
    medication: "",
    tracking_arva: [],
    dr_prev_dignosed: "",
  });
  const fertility_main = "fertility-test-for-women";
  const [PageValueUser, setPageValueUser] = useState({
    f_name: "",
    l_name: "",
    email: "",
    phone: "",
    address: "",
    apt: "",
    city: "",
    zipcode: "",
    preferred_time: 8,
    date_time: new Date(),
    discount: "",
    menstralstate: "Select",
    landmark: "",
    birth_date: "",
    order_updates: true,
    pause_all: false,
    reminders: true,
    content_updates: true,
    feature_updates: true,
  });
  const [Pages, setPages] = useState("");
  const [MiniNavi, setMiniNavi] = useState(false);
  const [BlankScreen, setBlankScreen] = useState(true);
  const [AllData, setAllData] = useState({
    last_recorder_order: {},
    last_order: {},
    last_intake: {},
    user_data: {},
    discount: {},
    all_order: [],
    version: "",
  });
  const [FurtherDataMixPanel, setFurtherDataMixPanel] = useState({
    sign_up_date: "",
    user_id: "",
    last_order_type: "",
    name: "",
    birthdate: "",
    is_test_user: false,
    user_current_goal: "",
    is_paid_user: false,
    reported_symptoms: "",
    reported_conditions: "",
    is_ultrasound_done: false,
    is_birth_control: false,
  });

  useEffect(() => {
    // setloading("type1");
    if (current_store?.checktoken_data?.updated) {
      setloading(false);
      setBlankScreen(false);
      setAllData((prev) => ({
        ...prev,
        ...current_store?.checktoken_data,
      }));
    }
  }, [current_store?.checktoken_data?.updated]);

  // useEffect(() => {
  //   scrollToTop();
  //   const data = localStorage.getItem("user_token");

  //   if (data) {
  //     if (!current_store?.checktoken_data?.updated) {
  //       setloading("type1");
  //       CheckTokenUser(
  //         {
  //           token: data,
  //         },
  //         (res) => {
  //           if (res.status) {
  //             setloading(false);
  //             setBlankScreen(false);
  //             setFurtherDataMixPanel((prev) => ({
  //               ...prev,
  //               sign_up_date: res?.response?.user_data?.created_at,
  //               user_id: res?.response?.user_data?._id,
  //               last_order_type: res?.response?.last_order?.data?.endPoint
  //                 ? res?.response?.last_order?.data?.endPoint == fertility_main
  //                   ? "During Menstrual Cycle"
  //                   : "Any Day"
  //                 : "",
  //               name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
  //                 ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
  //                   " " +
  //                   res?.response?.last_order?.data?.pagevaluesinput?.l_name
  //                 : "",
  //               birthdate:
  //                 res?.response?.last_order?.data?.pagevaluesinput?.birthdate ||
  //                 "",
  //               user_current_goal:
  //                 res?.response?.user_data?.tracking_arva ||
  //                 res?.response?.last_intake?.data?.tracking_arva ||
  //                 "",
  //               is_ultrasound_done: res?.response?.last_intake?.data
  //                 ?.prev_dignosed_with_PCOS
  //                 ? res?.response?.last_intake?.data?.prev_dignosed_with_PCOS ==
  //                   "Yes"
  //                 : false || false,
  //               is_birth_control: res?.response?.last_intake?.data?.birthcontrol
  //                 ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
  //                 : false || false,
  //               reported_symptoms:
  //                 res?.response?.last_intake?.data?.experience_symptoms.length >
  //                 0
  //                   ? res?.response?.last_intake?.data?.experience_symptoms.join(
  //                       ","
  //                     )
  //                   : "",
  //               reported_conditions:
  //                 res?.response?.last_intake?.data?.dr_prev_dignosed.length > 0
  //                   ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
  //                       ","
  //                     )
  //                   : "",
  //             }));
  //             userProperties({
  //               sign_up_date: res?.response?.user_data?.created_at,
  //               first_visit_date: res?.response?.user_data?.created_at,
  //               last_order_type: res?.response?.last_order?.data?.endPoint
  //                 ? res?.response?.last_order?.data?.endPoint == fertility_main
  //                   ? "During Menstrual Cycle"
  //                   : "Any Day"
  //                 : "",
  //               user_id: res?.response?.user_data?._id,
  //               name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
  //                 ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
  //                   " " +
  //                   res?.response?.last_order?.data?.pagevaluesinput?.l_name
  //                 : "",
  //               gender: "Female",
  //               date_of_birth:
  //                 res?.response?.last_order?.data?.pagevaluesinput?.birthdate ||
  //                 "",
  //               is_test_user: false,
  //               is_paid_user: res?.response?.last_order ? true : false,

  //               user_current_goal:
  //                 res?.response?.last_intake?.data?.tracking_arva || "",
  //               is_ultrasound_done: res?.response?.last_intake?.data
  //                 ?.prev_dignosed_with_PCOS
  //                 ? res?.response?.last_intake?.data?.prev_dignosed_with_PCOS ==
  //                   "Yes"
  //                 : false || false,
  //               is_birth_control: res?.response?.last_intake?.data?.birthcontrol
  //                 ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
  //                 : false || false,
  //               reported_symptoms:
  //                 res?.response?.last_intake?.data?.experience_symptoms.length >
  //                 0
  //                   ? res?.response?.last_intake?.data?.experience_symptoms.join(
  //                       ","
  //                     )
  //                   : "",

  //               reported_conditions:
  //                 res?.response?.last_intake?.data?.dr_prev_dignosed.length > 0
  //                   ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
  //                       ","
  //                     )
  //                   : "",
  //             });
  //           }
  //           //

  //           if (!res.status) {
  //             setloading(false);
  //             localStorage.clear();
  //             return navigate("/login");
  //           }
  //           const LastIndex = res.response.all_order.findIndex(
  //             (el) =>
  //               el.custom_id == res?.response?.last_recorder_order?.custom_id
  //           );
  //           setAllData((prev) => ({
  //             ...prev,
  //             last_order: res?.response?.last_order || {},
  //             last_recorder_order: res?.response?.last_recorder_order || {},
  //             last_intake: res?.response?.last_intake || {},
  //             user_data: res?.response?.user_data || {},
  //             discount: res?.response?.discount || {},
  //             all_order: res?.response?.all_order || [],
  //             version: res?.response?.version || "",
  //             prev_order_data: res?.response?.all_order[LastIndex + 1],
  //           }));
  //           dispatch({
  //             type: "setCheckToken",
  //             payload: {
  //               last_order: res?.response?.last_order || {},
  //               last_recorder_order: res?.response?.last_recorder_order || {},
  //               last_intake: res?.response?.last_intake || {},
  //               user_data: res?.response?.user_data || {},
  //               discount: res.response.discount || {},
  //               all_order: res?.response?.all_order || [],
  //               updated: true,
  //               version: res?.response?.version || "",
  //               prev_order_data: res?.response?.all_order[LastIndex + 1] || {},
  //             },
  //           });
  //           // setstore({
  //           //   token:
  //           //     "eyJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjExMTExMTExMTEifQ.hMFoZxcnyQGD2QONzteuJBG2vcX3nL-VjySoe8BOhq4",
  //           //   // email: res.response.user.email,
  //           //   // image: res.response.user.image,
  //           // });
  //         }
  //       );
  //     }
  //   } else {
  //     navigate("/login");
  //     setloading(false);
  //   }
  // }, []);
  //
  // useEffect(() => {
  //   if (AllData) {
  //     setPageValueintake((prev) => ({
  //       ...AllData?.last_intake?.data,
  //     }));
  //     if (AllData.user_data.f_name) {
  //       setPageValueUser((prev) => ({
  //         ...prev,
  //         ...AllData.user_data,
  //         birth_date: AllData?.last_order?.data?.pagevaluesinput?.birthdate,
  //       }));
  //     } else {
  //       setPageValueUser((prev) => ({
  //         ...prev,
  //         ...AllData?.last_order?.data?.pagevaluesinput,
  //         ...AllData?.user_data,
  //         birth_date: AllData?.last_order?.data?.pagevaluesinput?.birthdate,
  //       }));
  //     }
  //   }
  // }, [AllData]);
  // //
  //
  const [ActiveProfile, setActiveProfile] = useState(true);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // const gobackForall = () => {
  //   scrollToTop();
  //   setTrackFertility(false)
  // }
  //
  const process_me = ({ success, token }) => {
    if (success.customer == null) {
      navigate("/404");
    }
    MixPanelUsertype(success);
    dispatch({
      type: "setCheckToken",
      payload: { ...success, token: token },
    });
  };

  const [LogOutBtn, setLogOutBtn] = useState(false);
  const check_user_data = (token) => {
    checkToken(
      token || "",
      (success) => {
        localStorage.setItem("user_me", JSON.stringify({ success, token }));
        process_me({ success, token });
      },
      (err) => {}
    );
  };
  useEffect(() => {
    const user_me = localStorage.getItem("user_me");
    if (user_me) {
      process_me(JSON.parse(user_me));
    }
    if (store.checktoken_data.token) {
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        // window.open("http://localhost:3000/", "_self");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);

  // useEffect(() => {
  //   const data = localStorage.getItem("urlLogin");
  //   try {
  //     if (JSON.parse(data)) {
  //       setLogOutBtn(true);
  //     }
  //   } catch (error) {}
  // }, []);

  // useEffect(() => {
  //   let data = localStorage.getItem("add_goal_from_home");
  //   if (data) {
  //     data = JSON.parse(data);
  //     try {
  //       if (data) {
  //         setPages("tracking");
  //         setTrackFertility(true);
  //         setMiniNavi(true);
  //         setActiveProfile(false);
  //       }
  //     } catch (error) {}
  //   }
  // }, []);

  //

  return (
    <>
      {/* <NavbarMain
        {...{
          setNavBarActive: setNavBarActive,
          NavBarActive: NavBarActive,
          NavDropActive: !TrackFertility,
          MiniNavi: TrackFertility,
          setMiniNavi: setTrackFertility,
          ActiveProfile: ActiveProfile,
          setActiveProfile: setActiveProfile,
        }}
      /> */}
      {BlankScreen && false ? (
        ""
      ) : (
        <div className="profile_main">
          {TrackFertility ? (
            <>
              <div className="profile_back_contain">
                <div
                  onClick={() => {
                    setTrackFertility(false);
                    if (location.pathname === "/profile") {
                      let data = localStorage.getItem("add_goal_from_home");
                      if (data) {
                        data = JSON.parse(data);
                        try {
                          if (data) {
                            navigate("/home");
                            localStorage.setItem("add_goal_from_home", "");
                          } else {
                            // setActiveProfile(true);
                          }
                        } catch (error) {
                          // setActiveProfile(true);
                        }
                      } else {
                        // setActiveProfile(true);
                      }
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
                      stroke="#5f1d1b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {
                  [
                    { name: "Goal", navi: "tracking" },
                    {
                      name: "Profile & medical history",
                      navi: "profilemedical",
                    },
                    { name: "Notifications", navi: "notifications" },
                    { name: "Lab reports", navi: "labreports" },
                  ].filter((el, i) => el.navi == Pages)[0]?.name
                }

                {Pages === "profilemedical" ? (
                  <div
                    style={{
                      margin: "0 0 0 auto",
                    }}
                    onClick={() => {
                      navigate("/intake");
                    }}
                  >
                    <svg
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#5f1d1b"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M18 10L21 7L17 3L14 6M18 10L8 20H4V16L14 6M18 10L14 6"
                          stroke="#5f1d1b"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <ProfileType
                {...{
                  page_name: Pages,
                  otherData: {
                    setPageValueIntake: setPageValueintake,
                    // PageValueIntake: PageValueintake,
                    PageValueIntake: {
                      tracking_arva:
                        store?.checktoken_data?.customer?.intake
                          ?.reproductive_health?.goal || "",
                    },
                    setPageValue2: setPageValueUser,
                    PageValue2: PageValueUser,
                    lastdata: FurtherDataMixPanel,
                    setlastdata: setFurtherDataMixPanel,
                    AllData: AllData,
                    // setActiveProfile:setActiveProfile
                  },
                }}
              />
            </>
          ) : (
            <div className="profile_inner_main_page">
              <div className="profile_main_box_sup_title">Profile</div>
              <div className="profile_main_box">
                {store?.checktoken_data?.customer?.user?.first_name ? (
                  <div className="profile_main_title">
                    {/* {AllData?.last_order
                      ? AllData?.last_order?.data?.sheet_data[2]
                      : ""} */}
                    {[
                      store?.checktoken_data?.customer.user?.first_name,
                      store?.checktoken_data?.customer.user?.last_name,
                    ]
                      .join(" ")
                      .trim()}
                  </div>
                ) : (
                  ""
                )}
                {store?.checktoken_data?.customer?.intake?.reproductive_health
                  ?.goal ? (
                  <div className="profile_main_desc">
                    {/* I’m trying for kids as we speak <br></br> (without assistance) */}
                    {/* {AllData?.user_data?.tracking_arva
                      ? AllData?.user_data?.tracking_arva
                      : AllData?.last_intake
                      ? AllData?.last_intake?.data?.tracking_arva
                      : ""} */}
                    {
                      store?.checktoken_data?.customer?.intake
                        ?.reproductive_health?.goal
                    }
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="profile_main_box_btn"
                  onClick={() => {
                    // if (true) {
                    if (
                      // AllData?.user_data?.tracking_arva ||
                      // AllData?.last_intake?.data?.tracking_arva
                      true
                    ) {
                      //
                      setPages("tracking");
                      setTrackFertility(true);
                      setMiniNavi(true);
                      setActiveProfile(false);
                    } else {
                      if (AllData?.last_order?.data?.pagedatavalues) {
                        localStorage.setItem(
                          "OrderDetailPurchase",
                          JSON.stringify(
                            AllData?.last_order?.data?.pagedatavalues
                          )
                        );
                        navigate("/intake");
                      } else {
                        navigate("/home");
                      }
                    }
                  }}
                >
                  Change goal
                </div>
              </div>
              <div className="profile_main_account">
                <div className="profile_main_account_title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M5.9745 20.7713C8.42265 19.3876 11.1878 18.6624 14 18.6667C16.9167 18.6667 19.6548 19.4308 22.0255 20.7713M17.5 11.6667C17.5 12.5949 17.1313 13.4852 16.4749 14.1415C15.8185 14.7979 14.9283 15.1667 14 15.1667C13.0717 15.1667 12.1815 14.7979 11.5251 14.1415C10.8687 13.4852 10.5 12.5949 10.5 11.6667C10.5 10.7384 10.8687 9.84817 11.5251 9.19179C12.1815 8.53541 13.0717 8.16667 14 8.16667C14.9283 8.16667 15.8185 8.53541 16.4749 9.19179C17.1313 9.84817 17.5 10.7384 17.5 11.6667ZM24.5 14C24.5 15.3789 24.2284 16.7443 23.7007 18.0182C23.1731 19.2921 22.3996 20.4496 21.4246 21.4246C20.4496 22.3996 19.2921 23.1731 18.0182 23.7007C16.7443 24.2284 15.3789 24.5 14 24.5C12.6211 24.5 11.2557 24.2284 9.98182 23.7007C8.70791 23.1731 7.55039 22.3996 6.57538 21.4246C5.60036 20.4496 4.82694 19.2921 4.29926 18.0182C3.77159 16.7443 3.5 15.3789 3.5 14C3.5 11.2152 4.60625 8.54451 6.57538 6.57538C8.54451 4.60625 11.2152 3.5 14 3.5C16.7848 3.5 19.4555 4.60625 21.4246 6.57538C23.3938 8.54451 24.5 11.2152 24.5 14Z"
                      stroke="#1D1D1D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div>Account</div>
                </div>

                <div className="profile_main_account_list">
                  {[
                    store?.checktoken_data?.customer?.intake
                      ? {
                          name: "Profile & medical history",
                          linkto: "profilemedical",
                        }
                      : "",
                    // { name: "Notifications", linkto: "notifications" },
                    { name: "Lab reports", linkto: "labreports" },
                    //   "Research consent",
                  ]
                    .filter((el) => el)
                    .map((el, i) => (
                      <div
                        key={i}
                        className="profile_main_account_list_box"
                        onClick={() => {
                          setPages(el.linkto);
                          setTrackFertility(true);
                          setActiveProfile(false);
                          // localStorage.setItem("NotToShowWatsapp",el.linkto)
                        }}
                      >
                        <div className="profile_main_account_list_box_title">
                          {el.name}
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M7.5 15L12.5 10L7.5 5"
                            stroke="#454545"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    ))}
                  <div
                    className="profile_main_account_list_box"
                    onClick={() => {
                      window.open(
                        "https://arvahealth.typeform.com/researchconsent"
                      );
                    }}
                  >
                    <div className="profile_main_account_list_box_title">
                      Research consent
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.5 15L12.5 10L7.5 5"
                        stroke="#454545"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div
                    className="profile_main_account_list_box"
                    onClick={() => {
                      window.open("https://wa.me/918867608376");
                      // localStorage.setItem("NotToShowWatsapp",el.linkto)
                    }}
                  >
                    <div className="profile_main_account_list_box_title">
                      Help
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.5 15L12.5 10L7.5 5"
                        stroke="#454545"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {/* <div className="profile_main_account">
                <div className="profile_main_account_title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M4.66602 4.66675V10.5001H5.34502M5.34502 10.5001C6.11951 8.58456 7.50764 6.98014 9.29188 5.93825C11.0761 4.89636 13.1556 4.47589 15.2045 4.74274C17.2533 5.00959 19.1558 5.94867 20.6136 7.41282C22.0715 8.87696 23.0023 10.7834 23.2603 12.8334M5.34502 10.5001H10.4993M23.3327 23.3334V17.5001H22.6548M22.6548 17.5001C21.8792 19.4145 20.4907 21.0176 18.7067 22.0586C16.9226 23.0995 14.8437 23.5195 12.7955 23.2528C10.7472 22.986 8.84525 22.0476 7.38731 20.5844C5.92936 19.1213 4.99776 17.2159 4.73835 15.1667M22.6548 17.5001H17.4993"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div>Refer a friend</div>
                </div>
                <div className="profile_main_refer">
                  <div>
                    {AllData?.discount?.codes_used || 0} friends referred
                  </div>
                </div>
                <div
                  className="profile_main_refer_btn"
                  onClick={() => {
                 
                    MixPanelReferal({
                      cta: "Refer",
                      screen: "Profile",
                      remaining: FurtherDataMixPanel,
                    });
                    navigate("/referral");
                  }}
                >
                  Refer
                </div>
              </div> */}
              <div className="profile_main_account">
                <div className="profile_main_account_title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M10.5003 13.9998L12.8336 16.3332L17.5003 11.6665M9.14115 5.47966C9.97801 5.41287 10.7725 5.08381 11.4115 4.53933C12.1335 3.92364 13.0514 3.58545 14.0003 3.58545C14.9492 3.58545 15.8671 3.92364 16.5891 4.53933C17.2282 5.08381 18.0226 5.41287 18.8595 5.47966C19.8054 5.55491 20.6935 5.96479 21.3644 6.63574C22.0354 7.30669 22.4452 8.19478 22.5205 9.14066C22.5873 9.97752 22.9163 10.772 23.4608 11.411C24.0765 12.1331 24.4147 13.0509 24.4147 13.9998C24.4147 14.9487 24.0765 15.8666 23.4608 16.5887C22.9163 17.2277 22.5873 18.0221 22.5205 18.859C22.4452 19.8049 22.0354 20.693 21.3644 21.3639C20.6935 22.0349 19.8054 22.4447 18.8595 22.52C18.0226 22.5868 17.2282 22.9158 16.5891 23.4603C15.8671 24.076 14.9492 24.4142 14.0003 24.4142C13.0514 24.4142 12.1335 24.076 11.4115 23.4603C10.7725 22.9158 9.97801 22.5868 9.14115 22.52C8.19527 22.4447 7.30717 22.0349 6.63622 21.3639C5.96528 20.693 5.5554 19.8049 5.48015 18.859C5.41335 18.0221 5.0843 17.2277 4.53981 16.5887C3.92413 15.8666 3.58594 14.9487 3.58594 13.9998C3.58594 13.0509 3.92413 12.1331 4.53981 11.411C5.0843 10.772 5.41335 9.97752 5.48015 9.14066C5.5554 8.19478 5.96528 7.30669 6.63622 6.63574C7.30717 5.96479 8.19527 5.55491 9.14115 5.47966Z"
                      stroke="#1D1D1D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div>About</div>
                </div>
                <div className="profile_main_account_list">
                  {["About Arva Health", "Privacy policy"].map((el, i) => (
                    <div
                      key={i}
                      className="profile_main_account_list_box"
                      onClick={() => {
                        if (el == "Privacy policy") {
                          window.open(
                            "https://www.arva.health/legal/privacy-policy"
                          );
                        } else {
                          window.open("https://www.arva.health/about-us");
                        }
                      }}
                    >
                      <div className="profile_main_account_list_box_title">
                        {el}
                      </div>
                    </div>
                  ))}
                  <div
                    className="profile_main_account_list_box_title2"
                    onClick={() => {
                      window.open(
                        "https://www.arva.health/legal/terms-of-service"
                      );
                    }}
                  >
                    Terms of service
                  </div>
                </div>
              </div>
              {LogOutBtn && false ? (
                ""
              ) : (
                <div
                  className="profile_main_logout_btn"
                  onClick={() => {
                    // Clear user properties
                    // MixPanelLogOut();
                    // localStorage.setItem("user_token", "");
                    // localStorage.setItem("user_token_home", "");
                    // localStorage.setItem("user_me", "");
                    localStorage.clear();
                    dispatch({
                      type: "setCheckToken",
                      payload: { token: "", checktoken_data: {} },
                    });

                    navigate("/");
                  }}
                >
                  Log out
                </div>
              )}

              <div class="profile_main_list">
                <div className="profile_main_list_data">
                  {AllData?.user_data?.phone
                    ? `+91 ${AllData?.user_data?.phone}`
                    : ""}
                </div>
              </div>

              {/* <div className="profile_main_list">
                <div>Version {AllData?.version}</div>
                <div>Arva Health {date_formatter(new Date(), "year_long")}</div>
              </div> */}
            </div>
          )}
          <BottomNav />
        </div>
      )}
    </>
  );
};
