import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// import { NavbarMain } from "../component/NavbarMain";
// import { AllBlogsMaterial } from "./Blogs/AllBlogsMaterial";
// import { ArticleContent } from "./Blogs/ArticleContent";
import {
  CheckTokenUser,
  get_blogs_data,
  get_blogs_list,
} from "../../../component/API/api";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";

// import {
//   MixPanelArticle,
//   userProperties,
// } from "../component/API/automation_api";
import { useDispatch, useSelector } from "react-redux";

import { date_formatter } from "../../../component/CustInput/CustInput";
import { BottomNav } from "../../../component/BottomNav";
import { CommonFooter } from "../../../component/CommonFooter";
import { ExpNav } from "../../../component/Explore/ExpNav";
import { AllBlogsMaterial } from "../../Blogs/AllBlogsMaterial";
import { ArticleContent } from "../../Blogs/ArticleContent";
import { NavbarMain } from "../../../component/NavbarMain";
import {
  MixPanelUsertype,
  userProperties,
} from "../../../component/API/automation_api";
import {
  SeeMoreOrLessComp,
  SeeMoreOrLoess,
} from "../../../component/Explore/SeeMoreOrLoess";
import { checkToken, getArticle } from "../../../component/API/new_api";

export const Article = ({}) => {
  const setloading = (a) => {
    return a;
  };
  const params = useParams();
  const store = useSelector((state) => state);
  // const seemoreorlessid = useRef();
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [NavBarActive, setNavBarActive] = useState("");
  const [TrackFertility, setTrackFertility] = useState(false);
  const [Fertility_type, setFertility_type] = useState("");
  const navigate = useNavigate();
  const [List, setList] = useState([]);
  const [ListFilter, setListFilter] = useState({});
  const [Token, setToken] = useState("");
  const [filteredResult, setfilteredResult] = useState([]);
  const refData = useRef();
  const [BlankScreen, setBlankScreen] = useState(true);
  useEffect(() => {
    const tok = localStorage.getItem("user_token");
    setToken(tok);
  }, []);
  //

  useEffect(() => {
    if (
      !current_store.blogsanity_data.blog_list?.length &&
      store.checktoken_data.token
    ) {
      //
      setloading("type1");
      // get_blogs_list((data) => {
      //   //
      //   setList(data.response.data.explore.article);
      //   if (data.status) {
      //     setBlankScreen(false);
      //     setloading(false);
      //     dispatch({
      //       type: "setBlogSanity",
      //       payload: {
      //         blog_list: data.response.data.explore.article || {},
      //         qanda_list: data.response.data.explore.QA || {},
      //         guide_list: data.response.data.explore.guide || {},
      //         updated: true,
      //       },
      //     });
      //   } else {
      //     setloading(false);
      //   }
      // });
      getArticle(
        store.checktoken_data.token,
        (data) => {
          let t_articles = data.map(
            ({ name, category, link, image, topicSort = 1 }) => ({
              imageUrl: image,
              topic: category,
              topicSort: topicSort,
              _id: name,
              title: name,
              description: "",
              slug: link,
            })
          );
          dispatch({
            type: "setBlogSanity",
            payload: {
              blog_list: t_articles,
            },
          });
          setList(t_articles);
          if (data?.length) {
            setBlankScreen(false);
            setloading(false);
            // dispatch({
            //   type: "setBlogSanity",
            //   payload: {
            //     blog_list: data.response.data.explore.article || {},
            //     qanda_list: data.response.data.explore.QA || {},
            //     guide_list: data.response.data.explore.guide || {},
            //     updated: true,
            //   },
            // });
          } else {
            setloading(false);
          }
        },
        () => {}
      );
    } else {
      //
      setList(current_store.blogsanity_data.blog_list);

      setBlankScreen(false);
      setloading(false);
    }
  }, [store]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // useEffect(() => {
  //

  let CardClick = (obj) => {
    setFertility_type(obj);
    scrollToTop();
    // if (Fertility_type?._id && !TrackFertility) {
    setloading("type1");
    // MixPanelArticle({
    //   ar_name: obj.name,
    //   article_id: obj.slug,
    //   screen_name: window.location.pathname.slice(1),
    //   remaining: FurtherDataMixPanel,
    // });
    console.log({
      article_name: obj.name,
      // section_name
    });

    // get_blogs_data({ slug: obj.slug }, (res) => {
    //   //
    //   if (res.status) {
    //     setloading(false);
    //     setTrackFertility(true);
    //     // scrollToTop();
    //     setActiveProfile(false);
    //   } else {
    //     setloading(false);
    //   }
    //   setListFilter(res.response.data);
    // });
    // }
  };
  // }, [TrackFertility, Fertility_type]);

  //
  useEffect(() => {
    // const id_data = ListFilter?.data?.relatedArticles?.map((el) => el?._ref)
    const dataList = List?.filter((item) => {
      // return id_data?.includes(item._id)
      return ListFilter?.data?.relatedArticles?.some(
        (relatedArticle) => relatedArticle._ref === item._id
      );
    });
    setfilteredResult(dataList);
  }, [List, ListFilter]);

  const [HeightChild, setHeightChild] = useState(0);

  useEffect(() => {
    setHeightChild(refData?.current?.getBoundingClientRect()?.height);
  }, [ListFilter?.data?.content]);
  const [FurtherDataMixPanel, setFurtherDataMixPanel] = useState({
    sign_up_date: "",
    user_id: "",
    last_order_type: "",
    name: "",
    birthdate: "",
    is_test_user: false,
    user_current_goal: "",
    is_paid_user: false,
    reported_symptoms: "",
    reported_conditions: "",
    is_ultrasound_done: false,
    is_birth_control: false,
  });
  //
  //
  const fertility_main = "fertility-test-for-women";
  useEffect(() => {
    setloading("type1");
    if (current_store?.checktoken_data?.updated) {
      setloading(false);
      // setBlankScreen(false);
      setFurtherDataMixPanel((prev) => ({
        ...prev,
        sign_up_date: current_store?.checktoken_data?.user_data?.created_at,
        user_id: current_store?.checktoken_data?.user_data?._id,
        last_order_type: current_store?.checktoken_data?.last_order?.data
          ?.endPoint
          ? current_store?.checktoken_data?.last_order?.data?.endPoint ==
            fertility_main
            ? "During Menstrual Cycle"
            : "Any Day"
          : "",
        name: current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
          ?.f_name
          ? current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
              ?.f_name +
            " " +
            current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
              ?.l_name
          : "",
        birthdate:
          current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
            ?.birthdate || "",
        // user_current_goal:
        //   current_store?.checktoken_data?.last_intake?.data?.tracking_arva ||
        //   "",
        user_current_goal:
          current_store?.checktoken_data?.user_data?.tracking_arva ||
          current_store?.checktoken_data?.last_intake?.data?.tracking_arva ||
          "",
        is_ultrasound_done: current_store?.checktoken_data?.last_intake?.data
          ?.prev_dignosed_with_PCOS
          ? current_store?.checktoken_data?.last_intake?.data
              ?.prev_dignosed_with_PCOS == "Yes"
          : false || false,
        is_birth_control: current_store?.checktoken_data?.last_intake?.data
          ?.birthcontrol
          ? current_store?.checktoken_data?.last_intake?.data?.birthcontrol ==
            "Yes"
          : false || false,
        reported_symptoms:
          current_store?.checktoken_data?.last_intake?.data?.experience_symptoms
            .length > 0
            ? current_store?.checktoken_data?.last_intake?.data?.experience_symptoms.join(
                ","
              )
            : "",
        reported_conditions:
          current_store?.checktoken_data?.last_intake?.data?.dr_prev_dignosed
            .length > 0
            ? current_store?.checktoken_data?.last_intake?.data?.dr_prev_dignosed?.join(
                ","
              )
            : "",
      }));
    } else {
      setloading(false);
    }
  }, [current_store?.checktoken_data?.updated]);
  useEffect(() => {
    const data = localStorage.getItem("user_token");
    if (data) {
      if (!current_store?.checktoken_data?.updated) {
        setloading("type1");
        CheckTokenUser(
          {
            token: data,
          },
          (res) => {
            //
            if (res.status) {
              setloading(false);

              setFurtherDataMixPanel((prev) => ({
                ...prev,
                sign_up_date: res?.response?.user_data?.created_at,
                user_id: res?.response?.user_data?._id,
                last_order_type: res?.response?.last_order?.data?.endPoint
                  ? res?.response?.last_order?.data?.endPoint == fertility_main
                    ? "During Menstrual Cycle"
                    : "Any Day"
                  : "",
                name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
                  ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
                    " " +
                    res?.response?.last_order?.data?.pagevaluesinput?.l_name
                  : "",
                birthdate:
                  res?.response?.last_order?.data?.pagevaluesinput?.birthdate ||
                  "",
                user_current_goal:
                  res?.response?.user_data?.tracking_arva ||
                  res?.response?.last_intake?.data?.tracking_arva ||
                  "",
                is_ultrasound_done: res?.response?.last_intake?.data
                  ?.prev_dignosed_with_PCOS
                  ? res?.response?.last_intake?.data?.prev_dignosed_with_PCOS ==
                    "Yes"
                  : false || false,
                is_birth_control: res?.response?.last_intake?.data?.birthcontrol
                  ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
                  : false || false,
                reported_symptoms:
                  res?.response?.last_intake?.data?.experience_symptoms.length >
                  0
                    ? res?.response?.last_intake?.data?.experience_symptoms.join(
                        ","
                      )
                    : "",
                reported_conditions:
                  res?.response?.last_intake?.data?.dr_prev_dignosed.length > 0
                    ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
                        ","
                      )
                    : "",
              }));
              userProperties({
                sign_up_date: res?.response?.user_data?.created_at,
                first_visit_date: res?.response?.user_data?.created_at,
                last_order_type: res?.response?.last_order?.data?.endPoint
                  ? res?.response?.last_order?.data?.endPoint == fertility_main
                    ? "During Menstrual Cycle"
                    : "Any Day"
                  : "",
                user_id: res?.response?.user_data?._id,
                name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
                  ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
                    " " +
                    res?.response?.last_order?.data?.pagevaluesinput?.l_name
                  : "",
                gender: "Female",
                date_of_birth:
                  res?.response?.last_order?.data?.pagevaluesinput?.birthdate ||
                  "",
                is_test_user: false,
                is_paid_user: res?.response?.last_order ? true : false,

                user_current_goal:
                  res?.response?.last_intake?.data?.tracking_arva || "",
                is_ultrasound_done: res?.response?.last_intake?.data
                  ?.prev_dignosed_with_PCOS
                  ? res?.response?.last_intake?.data?.prev_dignosed_with_PCOS ==
                    "Yes"
                  : false || false,
                is_birth_control: res?.response?.last_intake?.data?.birthcontrol
                  ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
                  : false || false,
                reported_symptoms:
                  res?.response?.last_intake?.data?.experience_symptoms.length >
                  0
                    ? res?.response?.last_intake?.data?.experience_symptoms.join(
                        ","
                      )
                    : "",

                reported_conditions:
                  res?.response?.last_intake?.data?.dr_prev_dignosed.length > 0
                    ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
                        ","
                      )
                    : "",
              });
              const LastIndex = res.response.all_order.findIndex(
                (el) =>
                  el.custom_id == res?.response?.last_recorder_order?.custom_id
              );
              dispatch({
                type: "setCheckToken",
                payload: {
                  prev_order_data: res.response.all_order[LastIndex + 1] || {},
                  last_order: res?.response?.last_order || {},
                  last_recorder_order: res?.response?.last_recorder_order || {},
                  last_intake: res?.response?.last_intake || {},
                  user_data: res?.response?.user_data || {},
                  discount: res.response.discount || {},
                  all_order: res?.response?.all_order || [],
                  updated: true,
                  version: res?.response?.version || "",
                },
              });
            }
            if (!res.status) {
              setloading(false);
              localStorage.clear();
              return navigate("/login");
            }
          }
        );
      }
    }
  }, []);
  // const [SeeMoreOrLess, setSeeMoreOrLess] = useState(false);
  const [UniqueList, setUniqueList] = useState([]);
  const [CurrentFilter, setCurrentFilter] = useState("All");
  const NewArray = [
    "Fertility 101",
    "Fertility Wellness",
    "Fertility x PCOS",
    "Fertility Treatments",
    "Pregnancy",
  ];
  //
  useEffect(() => {
    // const topics = [
    //   ...new Set(List.map((item) => item.topic).filter((topic) => topic)),
    // ];
    const topics = [...new Set(List.map((item) => item.topic).sort())]
      .filter((topic) => topic !== undefined && topic !== null)
      .map((topic) => ({
        topic,
        topicSort: List.find((item) => item.topic === topic)?.topicSort,
      }))
      .sort((a, b) => a.topicSort - b.topicSort)
      .map((el) => el.topic);

    setUniqueList(topics);
    // setUniqueList(topics);
    // setUniqueList(NewArray);
  }, [List]);
  //
  // useEffect(() => {
  //   setCurrentFilter("All");

  // }, [UniqueList]);
  // const [MaxHeight, setMaxHeight] = useState(0);
  // const [SeeMoreOrLessActive, setSeeMoreOrLessActive] = useState(false);

  //
  const SliderMenu2 = useRef();
  var Section2 = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    arrows: false,
    focusOnSelect: false,
    variableWidth: true,
  };
  //
  // let history = unstable_HistoryRouter()
  // let navigate = useNavigate
  const [ActiveProfile, setActiveProfile] = useState(false);

  //
  //

  // useLayoutEffect(() => {
  //   if (params.slug != "all") {
  //     CardClick({ slug: params.slug });
  //     setTrackFertility(true);
  //     // scrollToTop();
  //     //
  //   }
  // }, [params.slug]);
  const check_user_data = (token) => {
    checkToken(
      token || "",
      (success) => {
        if (success.customer == null) {
          navigate("/404");
        }

        MixPanelUsertype(success);
        //   setPagevalues((prev) => ({
        //     ...prev,
        //     tracking_arva: MultiSelectData,
        //   }));
        //   setlastdata((prev) => ({
        //     ...prev,
        //     user_current_goal: MultiSelectData,
        //   }));
        dispatch({
          type: "setCheckToken",
          payload: { ...success, token: token },
        });
      },
      (err) => {}
    );
  };
  useEffect(() => {
    if (store.checktoken_data.token) {
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        // window.open("http://localhost:3000/", "_self");
        navigate("/home");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);
  //
  return (
    <>
      {false ? (
        ""
      ) : (
        <>
          <div className="main_explore_container">
            <div className="article_main">
              {TrackFertility ? (
                <div className="blogs_parent">
                  <div
                    onClick={() => {
                      setTrackFertility(false);
                      navigate(-1);
                      // scrollToTop()
                      // navigate("/learn/all");
                    }}
                    className="back_btn_article"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3964_658)">
                        <path
                          d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
                          stroke="#5f1d1b"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3964_658">
                          <rect
                            width="20"
                            height="20"
                            rx="10"
                            transform="matrix(-1 0 0 1 20 0)"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <svg
                      class="logo"
                      xmlns="http://www.w3.org/2000/svg"
                      width="81"
                      height="20"
                      viewBox="0 0 81 20"
                      fill="none"
                    >
                      <path
                        d="M1.09588 19.83H4.09871C4.29701 19.83 4.52364 19.7167 4.60863 19.4901L6.28002 15.2125H15.8268L17.4981 19.4901C17.5831 19.7167 17.8098 19.83 18.0081 19.83H21.0109C21.4925 19.83 21.8324 19.4051 21.6341 18.9235L14.3253 0.339944C14.2404 0.113315 14.0137 0 13.8154 0H8.29135C8.09305 0 7.86642 0.113315 7.78143 0.339944L0.472652 18.9235C0.302681 19.3768 0.614295 19.83 1.09588 19.83ZM7.7531 11.5297L11.0676 2.91785L14.4103 11.5297H7.7531Z"
                        fill="#5F1D1B"
                      ></path>
                      <path
                        d="M40.0975 20C40.5791 20 40.9474 19.915 40.9474 19.1501V16.8839C40.9474 16.4589 40.8341 16.3173 40.3808 16.289C38.9927 16.2323 38.936 13.5694 37.123 11.898C38.9077 10.7932 39.8992 8.95184 39.8992 6.60057C39.8992 2.63456 37.0947 0 32.5904 0H23.9502C23.6952 0 23.5253 0.141643 23.5253 0.424929V19.4051C23.5253 19.6601 23.6952 19.83 23.9502 19.83H27.0664C27.3496 19.83 27.4913 19.6601 27.4913 19.4051V13.2011H31.8822C36.2165 13.2011 34.3185 20 40.0975 20ZM27.4913 9.51841V3.68272H32.7321C34.7151 3.68272 35.9332 4.70255 35.9332 6.60057C35.9332 8.49858 34.7151 9.51841 32.7321 9.51841H27.4913Z"
                        fill="#5F1D1B"
                      ></path>
                      <path
                        d="M49.4827 19.83H53.2787C53.477 19.83 53.7037 19.7167 53.7886 19.4901L61.664 0.906516C61.8623 0.424929 61.5224 0 61.0408 0H57.8396C57.6413 0 57.4147 0.113315 57.3297 0.339944L51.3807 15.2691L45.4317 0.339944C45.3467 0.113315 45.1201 0 44.9218 0H41.7207C41.2391 0 40.8991 0.424929 41.0974 0.906516L48.9728 19.4901C49.0578 19.7167 49.2844 19.83 49.4827 19.83Z"
                        fill="#5F1D1B"
                      ></path>
                      <path
                        d="M59.982 19.83H62.9848C63.1831 19.83 63.4097 19.7167 63.4947 19.4901L65.1661 15.2125H74.7129L76.3842 19.4901C76.4692 19.7167 76.6959 19.83 76.8942 19.83H79.897C80.3786 19.83 80.7185 19.4051 80.5202 18.9235L73.2114 0.339944C73.1265 0.113315 72.8998 0 72.7015 0H67.1775C66.9791 0 66.7525 0.113315 66.6675 0.339944L59.3588 18.9235C59.1888 19.3768 59.5004 19.83 59.982 19.83ZM66.6392 11.5297L69.9537 2.91785L73.2964 11.5297H66.6392Z"
                        fill="#5F1D1B"
                      ></path>
                    </svg>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3964_658)">
                        <path
                          d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
                          stroke="#5f1d1b"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3964_658">
                          <rect
                            width="20"
                            height="20"
                            rx="10"
                            transform="matrix(-1 0 0 1 20 0)"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  {Fertility_type?.description && (
                    <div className="blogs_main_inner_chip">
                      {Fertility_type?.description}
                    </div>
                  )}
                  <div className="blogs_main_inner">
                    <h1 className={"header" + " header_top"}>
                      {ListFilter?.data?.title}
                    </h1>
                    <h1 className="headerSmall_reloated_article">
                      {ListFilter?.data?.description}
                    </h1>
                    {ListFilter?.data?._createdAt ? (
                      <AllBlogsMaterial
                        {...{
                          type: "profile",
                          obj: {
                            type: "profile",
                            color: "#5F1D1B",
                            data: {
                              prof_img: "https://imgur.com/aclJEiE.jpeg",
                              prof_name: "The Arva Team",
                              prof_desc: date_formatter(
                                new Date(ListFilter?.data?._createdAt),
                                "m_long date, year_long"
                              ),
                            },
                          },
                          setFertility_type: setFertility_type,
                          setTrackFertility: setTrackFertility,
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {ListFilter?.data?.coverImage?.asset?._ref ? (
                      <AllBlogsMaterial
                        {...{
                          type: "imgstand",
                          obj: {
                            data: {
                              img: ListFilter?.data?.coverImage?.asset?._ref,
                              desc: ListFilter?.data?.coverImage?.alt,
                            },
                          },
                          setFertility_type: setFertility_type,
                          setTrackFertility: setTrackFertility,
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {!store.checktoken_data.token && (
                      <div
                        className="blogs_child"
                        style={{
                          "--heightChild": HeightChild,
                        }}
                      >
                        <div className="blogs_child_inner">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="blogs_child_inner_title">
                            This is for Arva members
                          </div>
                          <div
                            className="blogs_child_inner_btn"
                            onClick={() => {
                              navigate("/home");
                            }}
                          >
                            Subscribe for free
                          </div>
                          <div className="blogs_child_inner_already_a_member">
                            Already a member?{" "}
                            <span
                              onClick={() => {
                                navigate("/home");
                              }}
                            >
                              Sign in
                            </span>
                            .
                          </div>
                        </div>
                      </div>
                    )}
                    <div ref={refData}>
                      <ArticleContent content={ListFilter?.data?.content} />
                    </div>

                    {ListFilter?.data?._createdAt ? (
                      <AllBlogsMaterial
                        {...{
                          type: "horizontal_card",
                          obj: {
                            data: {
                              img: "https://imgur.com/heq9Tgb.jpg",
                              quet: "Want to understand your biological clock? Try our",
                              ans: "Fertility Test for Women.",
                            },
                          },
                          setFertility_type: setFertility_type,
                          setTrackFertility: setTrackFertility,
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {filteredResult?.length > 0 ? (
                      <>
                        <div className="headerSmall2">Related reading</div>
                        <AllBlogsMaterial
                          {...{
                            type: "card_type2_list",
                            obj: filteredResult?.splice(0, 2)?.map((el, i) => ({
                              imageUrl: el.imageUrl,
                              _id: el._id,
                              description: el.topic,
                              title: el.title,
                              slug: el.slug,
                            })),
                            setFertility_type: CardClick,
                            FurtherDataMixPanel: FurtherDataMixPanel,

                            setTrackFertility: setTrackFertility,
                          }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {filteredResult?.map((el, i) => (
                      <div key={i}>
                        <AllBlogsMaterial
                          {...{
                            type: el.type,
                            obj: el,
                            setFertility_type: setFertility_type,
                            setTrackFertility: setTrackFertility,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <CommonFooter />
                </div>
              ) : (
                <div className={"blogs_main_inner " + "article_main_inner"}>
                  <ExpNav />
                  <div className="blogs_title_list">
                    <SeeMoreOrLessComp
                      {...{
                        list: ["All", ...UniqueList],
                        curret: CurrentFilter,
                        setCurrent: setCurrentFilter,
                      }}
                    />
                  </div>
                  <div className="blog_main_list_data">
                    <AllBlogsMaterial
                      {...{
                        type: "card_type2_list",
                        UniqueList: UniqueList,
                        obj:
                          List.length > 0
                            ? List?.filter((el1) => {
                                if (CurrentFilter) {
                                  if (CurrentFilter == "All") {
                                    return true;
                                  } else {
                                    return el1.topic === CurrentFilter;
                                  }
                                }
                              }).map((el, i) => ({
                                imageUrl: el.imageUrl,
                                _id: el._id,
                                description: el.topic,
                                title: el.title,
                                slug: el.slug,
                              }))
                            : [],
                        setFertility_type: CardClick,
                        FurtherDataMixPanel: FurtherDataMixPanel,
                        setTrackFertility: setTrackFertility,
                      }}
                    />
                    <CommonFooter />
                  </div>

                  {/* </div>
            ))} */}
                </div>
              )}
            </div>
            {/* {Token ? <BottomNav /> : ""} */}
            <BottomNav />
          </div>
        </>
      )}
    </>
  );
};
