import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CheckTokenUser, get_blogs_list } from "../../../component/API/api";
import { useDispatch, useSelector } from "react-redux";
import { ExpNav } from "../../../component/Explore/ExpNav";
import {
  MixPanelUsertype,
  userProperties,
} from "../../../component/API/automation_api";
import { CommonFooter } from "../../../component/CommonFooter";
import { BottomNav } from "../../../component/BottomNav";
import { CommonLogin } from "../../../component/CommonLogin";
import { GuideAllPages } from "./GuideAllPages";
import { checktoken } from "../../../store/appdata";
import { logDOM } from "@testing-library/react";
import { checkToken, getGuide } from "../../../component/API/new_api";
// import { useNavigate, useParams } from "react-router-dom";
// import {
//     MixPanelArticle,
//     userProperties,
//   } from "../component/API/automation_api";
export const GuidePage = ({ setloading }) => {
  const store = useSelector((state) => state);

  const navigate = useNavigate();
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [AllData, setAllData] = useState({
    last_recorder_order: {},
    last_order: {
      published: false,
    },
    last_intake: {},
    user_data: {},
    base_color: "#5F1D1B",
    linear_grad: "linear-gradient(180deg, #FFECCB 0%, #FFB88F 100%)",
    fert_name: "",
    extradata: {
      join_community: false,
      guide: false,
      chat_council: false,
    },
    discount: {},
    third_obj: {
      ac_title: "",
      title: "",
      desc: "",
    },
    goal_plan: {
      title: "A comprehensive guide to Egg Freezing",
      list: [
        "30 topic breakdowns",
        "Egg Freezing Basics",
        "Sample Calendar",
        "Costs",
        "Effectiveness",
        "Detailed process",
        "So much more!",
      ],
      list_img: [
        "https://imgur.com/m0rIhny.jpeg",
        "https://imgur.com/sG1LPDF.jpeg",
        "https://imgur.com/tjfcv5I.jpeg",
      ],
    },
  });
  const [FurtherDataMixPanel, setFurtherDataMixPanel] = useState({
    sign_up_date: "",
    user_id: "",
    last_order_type: "",
    name: "",
    birthdate: "",
    is_test_user: false,
    user_current_goal: "",
    is_paid_user: false,
    reported_symptoms: "",
    reported_conditions: "",
    is_ultrasound_done: false,
    is_birth_control: false,
  });
  const params = useParams();
  const [PleaseLogin, setPleaseLogin] = useState(false);
  const [OpenCurated, setOpenCurated] = useState(false);
  const fertility_main = "fertility-test-for-women";
  const [Pages, setPages] = useState("");
  const [GuideList, setGuideList] = useState([]);
  const [GuideData, setGuideData] = useState({});
  useEffect(() => {
    if (
      !current_store.blogsanity_data.guide_list?.length &&
      store.checktoken_data.token
    ) {
      //
      setloading("type1");
      getGuide(
        store.checktoken_data.token,
        (data) => {
          let t_guides = data.map(({ title, goal, slug, product_images }) => ({
            _id: title,
            title: title,
            description: [],
            product_images: product_images,
            price: 0,
            goal: goal,
            slug: slug,
          }));
          dispatch({
            type: "setBlogSanity",
            payload: {
              guide_list: t_guides,
            },
          });
          setGuideList(t_guides);
          if (data?.length) {
            setloading(false);
            // dispatch({
            //   type: "setBlogSanity",
            //   payload: {
            //     blog_list: data.response.data.explore.article || {},
            //     qanda_list: data.response.data.explore.QA || {},
            //     guide_list: data.response.data.explore.guide || {},
            //     updated: true,
            //   },
            // });
          } else {
            setloading(false);
          }
        },
        () => {}
      );
      // get_blogs_list((data) => {
      //   //
      //   setGuideList(data.response.data.explore.guide);
      //   if (data.status) {
      //     // setBlankScreen(false);
      //     // setloading(false);
      //     dispatch({
      //       type: "setBlogSanity",
      //       payload: {
      //         // blog_list: data.response.data.explore.article || {},
      //         qanda_list: data.response.data.explore.QA || {},
      //         guide_list: data.response.data.explore.guide || {},
      //         updated: true,
      //       },
      //     });
      //   }
      // });
    } else {
      //
      setGuideList(current_store.blogsanity_data.guide_list);
      // setBlankScreen(false);
      // setloading(false);
    }
  }, [store]);
  //
  // useEffect(() => {
  //   // setloading("type1");
  //   if (current_store?.checktoken_data?.updated) {
  //     // setloading(false);
  //     setAllData((prev) => ({
  //       ...prev,
  //       ...current_store?.checktoken_data,
  //     }));
  //     setFurtherDataMixPanel((prev) => ({
  //       ...prev,
  //       sign_up_date: current_store?.checktoken_data?.user_data?.created_at,
  //       user_id: current_store?.checktoken_data?.user_data?._id,
  //       last_order_type: current_store?.checktoken_data?.last_order?.data
  //         ?.endPoint
  //         ? current_store?.checktoken_data?.last_order?.data?.endPoint ==
  //           fertility_main
  //           ? "During Menstrual Cycle"
  //           : "Any Day"
  //         : "",
  //       name: current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
  //         ?.f_name
  //         ? current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
  //             ?.f_name +
  //           " " +
  //           current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
  //             ?.l_name
  //         : "",
  //       birthdate:
  //         current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
  //           ?.birthdate || "",
  //       user_current_goal:
  //         current_store?.checktoken_data?.user_data?.tracking_arva ||
  //         current_store?.checktoken_data?.last_intake?.data?.tracking_arva ||
  //         "",
  //       is_ultrasound_done: current_store?.checktoken_data?.last_intake?.data
  //         ?.prev_dignosed_with_PCOS
  //         ? current_store?.checktoken_data?.last_intake?.data
  //             ?.prev_dignosed_with_PCOS == "Yes"
  //         : false || false,
  //       is_birth_control: current_store?.checktoken_data?.last_intake?.data
  //         ?.birthcontrol
  //         ? current_store?.checktoken_data?.last_intake?.data?.birthcontrol ==
  //           "Yes"
  //         : false || false,
  //       reported_symptoms:
  //         current_store?.checktoken_data?.last_intake?.data?.experience_symptoms
  //           .length > 0
  //           ? current_store?.checktoken_data?.last_intake?.data?.experience_symptoms.join(
  //               ","
  //             )
  //           : "",
  //       reported_conditions:
  //         current_store?.checktoken_data?.last_intake?.data?.dr_prev_dignosed
  //           .length > 0
  //           ? current_store?.checktoken_data?.last_intake?.data?.dr_prev_dignosed?.join(
  //               ","
  //             )
  //           : "",
  //     }));
  //   }
  // }, [current_store?.checktoken_data?.updated]);
  const [Token, setToken] = useState("");
  // useEffect(() => {
  //   const tok = localStorage.getItem("user_token");
  //   setToken(tok);
  // }, [PleaseLogin]);
  // useEffect(() => {
  //   const data = localStorage.getItem("user_token");
  //   //
  //   if (data) {
  //     if (!current_store?.checktoken_data?.updated) {
  //       // setloading("type1");
  //       CheckTokenUser(
  //         {
  //           token: data,
  //         },
  //         (res) => {
  //           //
  //           if (res.status) {
  //             // setloading(false);

  //             setFurtherDataMixPanel((prev) => ({
  //               ...prev,
  //               sign_up_date: res?.response?.user_data?.created_at,
  //               user_id: res?.response?.user_data?._id,
  //               last_order_type: res?.response?.last_order?.data?.endPoint
  //                 ? res?.response?.last_order?.data?.endPoint == fertility_main
  //                   ? "During Menstrual Cycle"
  //                   : "Any Day"
  //                 : "",
  //               name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
  //                 ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
  //                   " " +
  //                   res?.response?.last_order?.data?.pagevaluesinput?.l_name
  //                 : "",
  //               birthdate:
  //                 res?.response?.last_order?.data?.pagevaluesinput?.birthdate ||
  //                 "",
  //               user_current_goal:
  //                 res?.response?.user_data?.tracking_arva ||
  //                 res?.response?.last_intake?.data?.tracking_arva ||
  //                 "",
  //               is_ultrasound_done: res?.response?.last_intake?.data
  //                 ?.prev_dignosed_with_PCOS
  //                 ? res?.response?.last_intake?.data?.prev_dignosed_with_PCOS ==
  //                   "Yes"
  //                 : false || false,
  //               is_birth_control: res?.response?.last_intake?.data?.birthcontrol
  //                 ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
  //                 : false || false,
  //               reported_symptoms:
  //                 res?.response?.last_intake?.data?.experience_symptoms.length >
  //                 0
  //                   ? res?.response?.last_intake?.data?.experience_symptoms.join(
  //                       ","
  //                     )
  //                   : "",
  //               reported_conditions:
  //                 res?.response?.last_intake?.data?.dr_prev_dignosed.length > 0
  //                   ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
  //                       ","
  //                     )
  //                   : "",
  //             }));
  //             userProperties({
  //               sign_up_date: res?.response?.user_data?.created_at,
  //               first_visit_date: res?.response?.user_data?.created_at,
  //               last_order_type: res?.response?.last_order?.data?.endPoint
  //                 ? res?.response?.last_order?.data?.endPoint == fertility_main
  //                   ? "During Menstrual Cycle"
  //                   : "Any Day"
  //                 : "",
  //               user_id: res?.response?.user_data?._id,
  //               name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
  //                 ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
  //                   " " +
  //                   res?.response?.last_order?.data?.pagevaluesinput?.l_name
  //                 : "",
  //               gender: "Female",
  //               date_of_birth:
  //                 res?.response?.last_order?.data?.pagevaluesinput?.birthdate ||
  //                 "",
  //               is_test_user: false,
  //               is_paid_user: res?.response?.last_order ? true : false,

  //               user_current_goal:
  //                 res?.response?.last_intake?.data?.tracking_arva || "",
  //               is_ultrasound_done: res?.response?.last_intake?.data
  //                 ?.prev_dignosed_with_PCOS
  //                 ? res?.response?.last_intake?.data?.prev_dignosed_with_PCOS ==
  //                   "Yes"
  //                 : false || false,
  //               is_birth_control: res?.response?.last_intake?.data?.birthcontrol
  //                 ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
  //                 : false || false,
  //               reported_symptoms:
  //                 res?.response?.last_intake?.data?.experience_symptoms.length >
  //                 0
  //                   ? res?.response?.last_intake?.data?.experience_symptoms.join(
  //                       ","
  //                     )
  //                   : "",

  //               reported_conditions:
  //                 res?.response?.last_intake?.data?.dr_prev_dignosed.length > 0
  //                   ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
  //                       ","
  //                     )
  //                   : "",
  //             });
  //             const LastIndex = res.response.all_order.findIndex(
  //               (el) =>
  //                 el.custom_id == res?.response?.last_recorder_order?.custom_id
  //             );
  //             setAllData((prev) => ({
  //               ...prev,
  //               last_order: res?.response?.last_order || {},
  //               last_recorder_order: res?.response?.last_recorder_order || {},
  //               last_intake: res?.response?.last_intake || {},
  //               user_data: res?.response?.user_data || {},
  //               discount: res.response.discount || {},
  //               prev_order_data: res.response.all_order[LastIndex + 1] || {},
  //             }));
  //             dispatch({
  //               type: "setCheckToken",
  //               payload: {
  //                 last_order: res?.response?.last_order || {},
  //                 last_recorder_order: res?.response?.last_recorder_order || {},
  //                 last_intake: res?.response?.last_intake || {},
  //                 user_data: res?.response?.user_data || {},
  //                 discount: res.response.discount || {},
  //                 all_order: res?.response?.all_order || [],
  //                 updated: true,
  //                 version: res?.response?.version || "",
  //                 prev_order_data: res.response.all_order[LastIndex + 1] || {},
  //               },
  //             });
  //           }
  //           if (!res.status) {
  //             // setloading(false);
  //             localStorage.clear();
  //             return navigate("/login");
  //           }
  //         }
  //       );
  //     }
  //   }
  // }, [PleaseLogin]);
  // useEffect(() => {
  //   let obj = {};
  //   const checkGoal =
  //     AllData?.user_data?.tracking_arva ||
  //     AllData?.last_intake?.data?.tracking_arva;
  //   if (["I’m exploring options like Egg Freezing"].includes(checkGoal)) {
  //     obj = {
  //       fert_name: "Fertility preservation",
  //       base_color: "#5F1D1B",
  //       linear_grad: "linear-gradient(180deg, #FFE3DB 0%, #FFA977 100%)",
  //       third_obj: {
  //         ac_title: "Freeze your eggs",
  //         title: "Partner clinics in your city",
  //         desc: "Arva-certified, curated quality clinics, with transparent pricing, an Egg Freezing concierge service, and 24x7 support (all kinds!).",
  //       },
  //     };
  //   } else if (
  //     [
  //       "Just curious to learn about my fertility",
  //       "I don’t want biological kids. I’m here to learn more about my body.",
  //     ].includes(checkGoal)
  //   ) {
  //     obj = {
  //       fert_name: "Proactive fertility care",
  //       base_color: "#2B3A33",
  //       linear_grad: "linear-gradient(180deg, #EBF5DD 0%, #E7EDE0 100%)",
  //       third_obj: {
  //         ac_title: "Ongoing fertility care",
  //         title: "Optimise your fertility health",
  //         desc: "Evidence-based programs with holisitic health coaches designed to optimise your health and maximise your conception potential.",
  //       },
  //     };
  //   } else if (
  //     [
  //       "I want kids but I won’t get started for at least another year",
  //     ].includes(checkGoal)
  //   ) {
  //     obj = {
  //       fert_name: "Pre-conception",
  //       base_color: "#5F1D1B",
  //       linear_grad: "linear-gradient(180deg, #FFECCB 0%, #FFB88F 100%)",
  //       third_obj: {
  //         ac_title: "Ongoing fertility care",
  //         title: "Optimise your fertility health",
  //         desc: "Evidence-based programs with holisitic health coaches designed to optimise your health and maximise your conception potential.",
  //       },
  //     };
  //   } else if (
  //     [
  //       "I’m trying for kids as we speak (with fertility treatment)",
  //       "I’m trying for kids as we speak (without assistance)",
  //     ].includes(checkGoal)
  //   ) {
  //     obj = {
  //       fert_name: "Conception",
  //       base_color: "#5F1D1B",
  //       linear_grad:
  //         "linear-gradient(180deg, #FFF0D7 0%, #F9D6B9 61.5%, #F2C0B2 100%)",
  //       third_obj: {
  //         ac_title: "Ongoing fertility care",
  //         title: "Partner clinics in your city",
  //         desc: "Arva-certified, curated quality clinics, with transparent pricing, a concierge service, and 24x7 support (all kinds!).",
  //       },
  //       // linear_grad: "linear-gradient(180deg, #FFECCB 0%, #FFB88F 100%)",
  //     };
  //   } else {
  //     obj = {
  //       fert_name: "",
  //       base_color: "#5F1D1B",
  //       linear_grad: "#FFFBF2",
  //       third_obj: {
  //         ac_title: "Freeze your eggs",
  //         title: "Partner clinics in your city",
  //         desc: "Arva-certified, curated quality clinics, with transparent pricing, an Egg Freezing concierge service, and 24x7 support (all kinds!).",
  //       },
  //     };
  //   }
  //   setAllData((prev) => ({
  //     ...prev,
  //     ...obj,
  //   }));
  // }, [FurtherDataMixPanel, PleaseLogin]);

  // useEffect(() => {
  //   if (true) {
  //     setPages("buyingPage");
  //   }
  // }, []);
  const [PayedTick, setPayedTick] = useState([]);
  const [show_guide, setshow_guide] = useState(false);

  useEffect(() => {}, []);

  // const CardClick = (obj) => {
  //   if (PayedTick?.includes(obj.slug)) {
  //     setPages("planDetail");
  //   } else {
  //     setPages("buyingPage");
  //   }
  //   setOpenCurated(true);
  //   const data = GuideList?.filter((el) => el.slug == obj.slug)[0];
  //   setGuideData(data);
  // };

  const check_user_data = (token) => {
    checkToken(
      token || "",
      (success) => {
        if (success.customer == null) {
          navigate("/404");
        }
        MixPanelUsertype(success);
        dispatch({
          type: "setCheckToken",
          payload: { ...success, token: token },
        });
      },
      (err) => {}
    );
  };
  useEffect(() => {
    if (store.checktoken_data.token) {
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        // window.open("http://localhost:3000/", "_self");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);
  // useLayoutEffect(() => {
  //   if (params.slug != "all") {
  //     CardClick({ slug: params.slug });
  //   }
  // }, [params.slug, GuideList, PayedTick]);
  // // useEffect(() => {
  //   let paidGuides = AllData?.user_data?.guides?.map((el) => el.slug);
  //   if (paidGuides?.length) {
  //     setPayedTick(paidGuides);
  //   }
  // }, [AllData?.user_data?.guides]);

  useEffect(() => {
    if (store.checktoken_data.token) {
      // setPayedTick(paidGuides);

      setToken(store.checktoken_data.token);
      let bought = store?.checktoken_data?.orderItems?.filter(
        (el) => el?.product_type == "guide"
      );
      setPayedTick(bought);
    }
  }, [store.checktoken_data.token]);
  // useEffect(() => {
  //   if (params.slug != "all") {
  //     CardClick({ slug: params.slug });
  //   }
  // }, [params.slug]);
  useLayoutEffect(() => {
    if (params.slug != "all") {
      setPages("planDetail");
    }
  }, [params, store]);
  return (
    <>
      {PleaseLogin ? (
        <div className="main_login_container">
          <CommonLogin
            {...{
              PleaseLogin,
              setPleaseLogin,
            }}
          />
        </div>
      ) : (
        <div className="main_explore_container">
          {Pages == "planDetail" ? (
            <div className="inner_guide_all">
              <GuideAllPages
                {...{
                  page_name: Pages,
                  otherData: {
                    AllData: AllData,
                    setAllData: setAllData,
                    setPages: setPages,
                    setOpenCurated: setOpenCurated,
                    GuideData: GuideData,
                  },
                }}
              />
            </div>
          ) : (
            <>
              <div className="main_guide_page">
                <ExpNav />
                <div className="curated_section">
                  <div className="curated_section_title">CURATED</div>
                  {GuideList?.map((el, i) => (
                    <div
                      className="curated_section_box"
                      onClick={() => {
                        // setPages("buyingPage");
                        // setOpenCurated(true);
                        //
                        if (
                          store?.checktoken_data?.orderItems?.filter(
                            (item) =>
                              item.product_id.match("GU01") ||
                              item.product_id.match("DC02")
                          ).length > 0
                          // store?.checktoken_data?.orderItems?.filter((item) =>
                          //   item.product_name.match(el.title)
                          // ).length > 0
                        ) {
                          navigate(`/guide/${el.slug}`);
                          // setshow_guide(true);
                          setPages("planDetail");
                        } else {
                          window.open(
                            "https://shop.arva.health/cart?ProductId=GU01"
                          );
                        }
                        // return;
                      }}
                    >
                      <div className="curated_section_box_top">
                        <div className="curated_section_box_top_title">NEW</div>
                        <div>
                          {/* {store?.checktoken_data?.orderItems?.filter((item) =>
                            item.product_name.match(el.title)
                          ).length > 0 ? ( */}
                          {store?.checktoken_data?.orderItems?.filter(
                            (item) =>
                              item.product_id.match("GU01") ||
                              item.product_id.match("DC02")
                          ).length > 0 ? (
                            // <svg
                            //   width="20"
                            //   height="20"
                            //   viewBox="0 0 20 20"
                            //   fill="none"
                            //   xmlns="http://www.w3.org/2000/svg"
                            // >
                            //   <path
                            //     d="M10 2C8.67392 2 7.40215 2.52678 6.46447 3.46447C5.52678 4.40215 5 5.67392 5 7V9C4.46957 9 3.96086 9.21071 3.58579 9.58579C3.21071 9.96086 3 10.4696 3 11V16C3 16.5304 3.21071 17.0391 3.58579 17.4142C3.96086 17.7893 4.46957 18 5 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V11C17 10.4696 16.7893 9.96086 16.4142 9.58579C16.0391 9.21071 15.5304 9 15 9H7V7C6.99975 6.26964 7.26595 5.56428 7.74866 5.01618C8.23138 4.46809 8.89747 4.11491 9.622 4.02289C10.3465 3.93087 11.0798 4.10631 11.6842 4.51633C12.2886 4.92635 12.7227 5.54277 12.905 6.25C12.9713 6.50686 13.1369 6.72686 13.3654 6.86161C13.4786 6.92833 13.6038 6.97211 13.7338 6.99045C13.8639 7.00879 13.9963 7.00133 14.1235 6.9685C14.2507 6.93567 14.3702 6.87811 14.4751 6.79911C14.58 6.7201 14.6684 6.6212 14.7351 6.50806C14.8018 6.39491 14.8456 6.26973 14.8639 6.13966C14.8823 6.00959 14.8748 5.87719 14.842 5.75C14.5645 4.67676 13.9384 3.7261 13.062 3.04734C12.1856 2.36857 11.1085 2.00017 10 2Z"
                            //     fill="#5F1D1B"
                            //   />
                            // </svg>
                            ""
                          ) : (
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5 9V7C5 5.67392 5.52678 4.40215 6.46447 3.46447C7.40215 2.52678 8.67392 2 10 2C11.3261 2 12.5979 2.52678 13.5355 3.46447C14.4732 4.40215 15 5.67392 15 7V9C15.5304 9 16.0391 9.21071 16.4142 9.58579C16.7893 9.96086 17 10.4696 17 11V16C17 16.5304 16.7893 17.0391 16.4142 17.4142C16.0391 17.7893 15.5304 18 15 18H5C4.46957 18 3.96086 17.7893 3.58579 17.4142C3.21071 17.0391 3 16.5304 3 16V11C3 10.4696 3.21071 9.96086 3.58579 9.58579C3.96086 9.21071 4.46957 9 5 9ZM13 7V9H7V7C7 6.20435 7.31607 5.44129 7.87868 4.87868C8.44129 4.31607 9.20435 4 10 4C10.7956 4 11.5587 4.31607 12.1213 4.87868C12.6839 5.44129 13 6.20435 13 7Z"
                                fill="#5F1D1B"
                              />
                            </svg>
                          )}
                        </div>
                      </div>

                      <div className="curated_section_box_title">
                        {el.title}
                      </div>
                      <div className="curated_section_box_list">
                        {[...el?.product_images?.slice(0, 2)].map((el1, i) => (
                          <div
                            className="curated_section_box_list_1"
                            key={i}
                            style={{
                              backgroundImage: `url(${el1})`,
                            }}
                          >
                            {/* <img src={el1} /> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                {/* // <div
                  //   className="curated_section_login"
                  //   onClick={() => {
                  //     setPleaseLogin(true);
                  //   }}
                  // >
                  //   Please Login
                  // </div>
                  // <div className="curated_section">
                  //   <div className="curated_section_title">CURATED</div>
                  //   {GuideList?.map((el, i) => (
                  //     <div
                  //       className="curated_section_box"
                  //       onClick={() => {
                  //         // setPages("buyingPage");
                  //         // setOpenCurated(true);
                  //         
                  //         navigate(`/guide/${el.slug}`);
                  //       }}
                  //     >
                  //       <div className="curated_section_box_top">
                  //         <div className="curated_section_box_top_title">
                  //           NEW
                  //         </div>
                  //         <div>
                  //           {PayedTick.includes(el.slug) ? (
                  //             <svg
                  //               width="20"
                  //               height="20"
                  //               viewBox="0 0 20 20"
                  //               fill="none"
                  //               xmlns="http://www.w3.org/2000/svg"
                  //             >
                  //               <path
                  //                 d="M10 2C8.67392 2 7.40215 2.52678 6.46447 3.46447C5.52678 4.40215 5 5.67392 5 7V9C4.46957 9 3.96086 9.21071 3.58579 9.58579C3.21071 9.96086 3 10.4696 3 11V16C3 16.5304 3.21071 17.0391 3.58579 17.4142C3.96086 17.7893 4.46957 18 5 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V11C17 10.4696 16.7893 9.96086 16.4142 9.58579C16.0391 9.21071 15.5304 9 15 9H7V7C6.99975 6.26964 7.26595 5.56428 7.74866 5.01618C8.23138 4.46809 8.89747 4.11491 9.622 4.02289C10.3465 3.93087 11.0798 4.10631 11.6842 4.51633C12.2886 4.92635 12.7227 5.54277 12.905 6.25C12.9713 6.50686 13.1369 6.72686 13.3654 6.86161C13.4786 6.92833 13.6038 6.97211 13.7338 6.99045C13.8639 7.00879 13.9963 7.00133 14.1235 6.9685C14.2507 6.93567 14.3702 6.87811 14.4751 6.79911C14.58 6.7201 14.6684 6.6212 14.7351 6.50806C14.8018 6.39491 14.8456 6.26973 14.8639 6.13966C14.8823 6.00959 14.8748 5.87719 14.842 5.75C14.5645 4.67676 13.9384 3.7261 13.062 3.04734C12.1856 2.36857 11.1085 2.00017 10 2Z"
                  //                 fill="#5F1D1B"
                  //               />
                  //             </svg>
                  //           ) : (
                  //             <svg
                  //               width="20"
                  //               height="20"
                  //               viewBox="0 0 20 20"
                  //               fill="none"
                  //               xmlns="http://www.w3.org/2000/svg"
                  //             >
                  //               <path
                  //                 fill-rule="evenodd"
                  //                 clip-rule="evenodd"
                  //                 d="M5 9V7C5 5.67392 5.52678 4.40215 6.46447 3.46447C7.40215 2.52678 8.67392 2 10 2C11.3261 2 12.5979 2.52678 13.5355 3.46447C14.4732 4.40215 15 5.67392 15 7V9C15.5304 9 16.0391 9.21071 16.4142 9.58579C16.7893 9.96086 17 10.4696 17 11V16C17 16.5304 16.7893 17.0391 16.4142 17.4142C16.0391 17.7893 15.5304 18 15 18H5C4.46957 18 3.96086 17.7893 3.58579 17.4142C3.21071 17.0391 3 16.5304 3 16V11C3 10.4696 3.21071 9.96086 3.58579 9.58579C3.96086 9.21071 4.46957 9 5 9ZM13 7V9H7V7C7 6.20435 7.31607 5.44129 7.87868 4.87868C8.44129 4.31607 9.20435 4 10 4C10.7956 4 11.5587 4.31607 12.1213 4.87868C12.6839 5.44129 13 6.20435 13 7Z"
                  //                 fill="#5F1D1B"
                  //               />
                  //             </svg>
                  //           )}
                  //         </div>
                  //       </div>

                  //       <div className="curated_section_box_title">
                  //         {el.title}
                  //       </div>
                  //       <div className="curated_section_box_list">
                  //         {[
                  //           ...el?.product_images?.slice(0, 2),
                  //           // {
                  //           //   img: "https://imgur.com/ykLhUHJ.jpeg",
                  //           //   link: "https://www.arva.health/guides/fertility-the-basics",
                  //           // },
                  //           // {
                  //           //   img: "https://imgur.com/YhqpOnl.jpeg",
                  //           //   link: "https://www.arva.health/guides/fertility-the-basics",
                  //           // },
                  //         ].map((el1, i) => (
                  //           <div
                  //             className="curated_section_box_list_1"
                  //             key={i}
                  //             // style={{
                  //             //   backgroundImage: `url(${el1})`,
                  //             // }}
                  //           >
                  //             <img src={el1} />
                  //           </div>
                  //         ))}
                  //       </div>
                  //     </div>
                  //   ))}
                  // </div> */}

                <div className="onenotone_section">
                  <div className="onenotone_section_title">101s</div>
                  <div className="onenotone_section_list">
                    {[
                      {
                        img: "https://framerusercontent.com/images/lswkIvclP1zpJPxnjkv65gNbzw.png",
                        name: "Fertility: The basics",
                        color: "#5f1d1b",
                        navi: "https://www.arva.health/guides/fertility-the-basics",
                      },
                      {
                        img: "https://framerusercontent.com/images/eEseD6uXUPVkWhppzT6Mc4RIkJY.png",
                        name: "Improving Fertility",
                        navi: "https://www.arva.health/guides/improving-fertility",
                        color: "#5f1d1b",
                      },
                      {
                        img: "https://framerusercontent.com/images/MEOJXxZej4bzxlAzgjI5Vq1JrlA.png",
                        name: "Egg Quality",
                        navi: "https://www.arva.health/guides/egg-quality",
                        color: "#2b3a33",
                      },
                      {
                        img: "https://framerusercontent.com/images/OzR8p0zZvIls3jBvhfvHOCgpcrA.png",
                        name: "AMH levels",
                        navi: "https://www.arva.health/guides/amh-levels",
                        color: "#5f1d1b",
                      },
                      {
                        img: "https://framerusercontent.com/images/BAaw9veTcmbyFuVoUQyQVZ0.png",
                        name: "Hormonal imbalance",
                        navi: "https://www.arva.health/guides/hormone-imbalances",
                        color: "#5f1d1b",
                      },
                      {
                        img: "https://framerusercontent.com/images/iUMcNXeRioo39U3Fxvyohaqq8ZE.png",
                        name: "Egg freezing",
                        color: "#5f1d1b",
                        navi: "https://www.arva.health/guides/egg-freezing",
                      },
                      {
                        img: "https://framerusercontent.com/images/Ni2527ngDoZnmOs2M0bwWmeIX8s.png",
                        name: "Thyroid levels",
                        navi: "https://www.arva.health/guides/thyroid-levels",
                        color: "#5f1d1b",
                      },
                      {
                        img: "https://framerusercontent.com/images/Gd2f5ObmnmNR1NN9w8QgdeLq88.png",
                        name: "PCOS diagnosis",
                        navi: "https://www.arva.health/guides/pcos-diagnosis",
                        color: "#5f1d1b",
                      },
                    ].map((el, i) => (
                      <div
                        className="onenotone_section_box"
                        style={{
                          backgroundImage: `url(${el.img})`,
                          color: el.color,
                        }}
                        onClick={() => {
                          window.open(el.navi);
                        }}
                      >
                        {el.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}

          <BottomNav />
        </div>
      )}
    </>
  );
};
