import React, { useEffect, useState } from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import { MultiSelect } from "../../../component/CheckOut/Intake/MultiSelect";
import { MixPanelIntake } from "../../../component/API/automation_api";

export const Pcos = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  setlastdata,
  goto,
}) => {
  const [PcosList, setPcosList] = useState(["Yes", "No"]);
  const [PcosStatus, setPcosStatus] = useState("");

  useEffect(() => {
    setPcosStatus(pagevalues?.prev_dignosed_with_PCOS);
  }, []);
  useEffect(() => {
    setPagevalues((prev) => ({
      ...prev,
      prev_dignosed_with_PCOS: PcosStatus,
    }));
    setlastdata((prev) => ({
      ...prev,
      is_ultrasound_done: PcosStatus == "Yes",
    }));
  }, [PcosStatus]);

  return (
    <>
      <div className="intake_body">
        <div className="container pcos_main">
          <div className="pcos_title">
            Were you diagnosed with PCOS after an ultrasound?
          </div>
          <div className="pcos_desc">
            An ultrasound is the best indicator for a complete PCOS diagnosis.
          </div>
          <MultiSelect
            {...{
              list: PcosList,
              onMultiClick: (el) => setPcosStatus(el),
              MultiSelectData: PcosStatus,
              multisel: false,
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (!PcosStatus) {
                return;
              }
              MixPanelIntake({
                step_name: "Were you diagnosed with PCOS after an ultrasound?",
                section_name: "Reproductive Health",
                value: pagevalues.prev_dignosed_with_PCOS,
                step_index: 2,
                total_steps: 8,
                remaining: lastdata,
              });
              if (singleBeginRange) {
                setRangeCount(2);
              } else {
                setRangeCount(19);
              }
              setPages("singleOrMingle");
            },
            goBackClick: () => {
              setRangeCount(17);
              setPages("doctordignosed");
            },
          },
        }}
      />
    </>
  );
};
