import React, { useEffect, useState } from "react";

import BottomBtnSection from "../components/BottomBtnSection";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CheckTokenUser } from "../../../component/API/api";
import { MultiSelectInput } from "../../../component/CheckOut/Intake/MultiSelectInput";
import { updateIntake } from "../../../component/API/new_api";
import { toast } from "react-toastify";
export const KnowArva = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
}) => {
  const store = useSelector((state) => state);
  const [SympData, setSympData] = useState([
    { name: "Instagram", inp: "Instagram", inptype: false, error: "" },
    { name: "Google", inp: "Google", inptype: false, error: "" },
    { name: "LinkedIn", inp: "LinkedIn", inptype: false, error: "" },
    { name: "Twitter", inp: "Twitter", error: "" },
    {
      name: "From a friend or family",
      inp: "From a friend or family",
      inptype: false,
      error: "",
    },
    {
      name: "News publications",
      inp: "News publications",
      inptype: false,
      error: "",
    },
    { name: "Something else type....", inp: "", inptype: true, error: "" },
  ]);
  const [DetailList, setDetailList] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });
  const navigate = useNavigate();
  const [MultiSelectData, setMultiSelectData] = useState(null);
  useEffect(() => {
    if (pagevalues.socialmedia) {
      let tindex = SympData.findIndex(
        (item) => item.inp == pagevalues.socialmedia
      );

      if (tindex == -1) {
        setMultiSelectData(6);
        setSympData((prev) => {
          const updatedList = prev.map((el) => {
            if (el.inptype) {
              return { ...el, inp: pagevalues.socialmedia, error: "" };
            }
            return el;
          });
          return updatedList;
        });
      } else {
        setMultiSelectData(tindex);
      }
    }
  }, []);

  useEffect(() => {
    setPagevalues((prev) => ({
      ...prev,
      socialmedia: SympData[MultiSelectData]?.inp || pagevalues.socialmedia,
    }));
  }, [MultiSelectData, SympData]);
  // useEffect(() => {
  //   const data = localStorage.getItem("user_token");
  //   CheckTokenUser({ token: data }, (res) => {
  //     setDetailList((prev) => ({
  //       ...prev,
  //       email: res?.response?.last_order?.data?.sheet_data[3],
  //     }));
  //   });
  // }, []);

  return (
    <>
      <div className="intake_body">
        <div className="container ofterngetperiod_main">
          <div className="ofterngetperiod_title">
            How did you hear about Arva?
          </div>

          <MultiSelectInput
            {...{
              list: SympData,
              setList: setSympData,
              onMultiClick: (el) => {
                setMultiSelectData(el);
              },
              MultiSelectData: MultiSelectData,
              multisel: false,
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              setRangeCount(25);
              if (pagevalues.socialmedia.length > 0) {
                updateIntake(
                  store.checktoken_data.token,
                  {
                    dob: new Date(pagevalues.birth_date).toISOString(),
                    personalize: {
                      height_feet: parseInt(pagevalues.tall_feet),
                      height_inch: parseInt(pagevalues.tall_inch),
                      weight: parseInt(pagevalues.weight),
                      waist_inch: parseInt(pagevalues.waist_inch),
                      where_did_you_hear_about_us: pagevalues.socialmedia,
                    },
                    menstrual_health: {
                      period_frequency: pagevalues.oftengetperois,
                      cycle_length_avg: parseInt(pagevalues.averagecyclelength),
                      period_description: pagevalues.descperiod,
                      experience_symptoms: pagevalues.experience_symptoms,
                      symptoms: pagevalues.exphair,
                    },
                    family_history: {
                      mothers_age_during_your_birth: parseInt(
                        pagevalues.momage
                      ),
                      pregnancy_difficulty_level: pagevalues.getpregnentstatus,
                      menopause_mom: parseInt(pagevalues.mommenopause),
                    },
                    reproductive_health: {
                      previous_diagnosis: pagevalues.prev_dignosed,
                      ultrasound_diagnosis: pagevalues.prev_dignosed_with_PCOS,
                      partner: pagevalues.singleormingle,
                      partner_details: pagevalues.partnerknowledge,
                      birth_control: pagevalues.birthcontrol,
                      medical_condition: pagevalues.medicalcondition,
                      current_medication: pagevalues.medication,
                      goal: pagevalues.tracking_arva,
                    },
                  },
                  (success) => {
                    toast.success("Intake completed");

                    let t_order_id = localStorage.getItem("user_data_to_show");
                    if (!t_order_id) {
                      navigate("/home");
                      return;
                    }
                    t_order_id = JSON.parse(t_order_id);
                    navigate("/" + t_order_id.goTo);
                  },
                  (err) => {
                    toast.error("Try again");
                  }
                );
              }
            },
            goBackClick: () => {
              setRangeCount(23);
              setPages("medication");
            },
          },
        }}
      />
    </>
  );
};
