import React from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import CustInputMuiClone from "../../../component/CustInput/CustInputMuiClone";
import { MixPanelIntake } from "../../../component/API/automation_api";

const WeightSection = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  lastdata,
  goto,
  startfrom,
  sectionno,
}) => {
  const handle_pagevalues = (updated_data) => {
    return setPagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  return (
    <>
      <div className="intake_body">
        <div className="container weight_section_main">
          <div className="weight_title">On average, how much do you weigh?</div>
          <div className="weight_desc">Please input your weight in kgs.</div>
          <CustInputMuiClone
            {...{
              attr: {
                autofocus: true,
                error: err_pagevalues.weight,
                error_msg: "",
                value: pagevalues.weight,
                inputMode: "numeric",
                event: {
                  change: ({ target }) => {
                    if (Number(target.value.slice(-1)).toString() == "NaN") {
                      return;
                    }

                    handle_pagevalues({ weight: target.value });
                    handle_errpagevalues({
                      weight: target.value ? "" : "Error message",
                    });
                  },
                },
              },
              data: {
                title: "",
                suffix: "kgs",
              },
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (!pagevalues.weight) {
                seterr_pagevalues((prev) => ({
                  ...prev,
                  weight: !pagevalues.weight,
                }));
                return;
              }
              MixPanelIntake({
                step_name: "On average, how much do you weigh?",
                section_name: "Personal Info",
                value: pagevalues.weight + "kgs",
                step_index: 4,
                total_steps: 5,
                remaining: lastdata,
              });
              setRangeCount(5);
              setPages("waist");
            },
            goBackClick: () => {
              setRangeCount(3);
              setPages("tall");
            },
          },
        }}
      />
    </>
  );
};

export default WeightSection;
