import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get_guide_data } from "../../../component/API/api";
import { getGuideBySlug } from "../../../component/API/new_api";
import { useSelector } from "react-redux";
export const GuidePlanDetail = ({
  AllData,
  setAllData,
  setPages,
  setOpenCurated,
  GuideData,
}) => {
  const params = useParams();
  const navi = useNavigate();
  const TopAnimation = useRef();
  const store = useSelector((state) => state);

  const [Bg_Change, setBg_Change] = useState(false);
  // window.addEventListener("scroll", (e) => {
  //   if (TopAnimation?.current?.getBoundingClientRect()?.top <= 90) {
  //     setBg_Change(true);
  //   } else {
  //     setBg_Change(false);
  //   }
  // });
  const [ListImage, setListImage] = useState({
    images: [],
  });
  // const CardClick = (obj) => {
  //   get_guide_data(obj, (res) => {
  //     if (res.response.data.status) {
  //
  //       setListImage(res.response.data.data);
  //     }
  //   });
  // };

  // useLayoutEffect(() => {
  //   // if (params.slug != "all") {
  //   //   CardClick(params.slug);
  //   // }
  //   getGuideBySlug(
  //     store.checktoken_data.token || "",
  //     params.slug,
  //     (success) => {
  //       if (
  //         store.checktoken_data.orderItems.filter((item) =>
  //           item.product_id.match("GU01")
  //         ).length > 0
  //       ) {
  //         setListImage({
  //           images: [...success.product_images],
  //         });
  //       } else {
  //         setOpenCurated(false);
  //         setPages("");
  //         navi("/guide/all");
  //       }
  //     },
  //     (err) => {}
  //   );
  // }, [params.slug]);
  useLayoutEffect(() => {
    // if (params.slug != "all") {
    //   CardClick(params.slug);
    // }
    if (store?.checktoken_data?.token) {
      getGuideBySlug(
        store.checktoken_data.token || "",
        params.slug,
        (success) => {
          if (
            store.checktoken_data.orderItems.filter(
              (item) =>
                item.product_id.match("GU01") || item.product_id.match("DC02")
            ).length > 0
          ) {
            setListImage({
              images: [...success.images],
            });
          } else {
            setOpenCurated(false);
            setPages("");
            navi("/guide/all");
          }
        },
        (err) => {}
      );
    } else {
    }
  }, [params.slug, store?.checktoken_data]);

  function asset(url) {
    //
    if (url.match("http")) {
      return url;
    }
    if (url) {
      let a = url.split("-");
      return `https://cdn.sanity.io/${a[0]}s/nao51hyy/production/${a
        .slice(1, -1)
        .join("-")}.${a[a.length - 1]}`;
    }
    return "";
  }
  return (
    <div className="inner_guide_page_3">
      <div
        className="inner_guide_page_3_back_arrow"
        onClick={() => {
          setOpenCurated(false);
          setPages("");
          navi("/guide/all");
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3996_376)">
            <path
              d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
              stroke={false ? "#FFFBF2" : "#5F1D1B"}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3996_376">
              <rect
                width="20"
                height="20"
                rx="10"
                transform="matrix(-1 0 0 1 20 0)"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          class="logo"
          xmlns="http://www.w3.org/2000/svg"
          width="81"
          height="20"
          viewBox="0 0 81 20"
          fill="none"
        >
          <path
            d="M1.09588 19.83H4.09871C4.29701 19.83 4.52364 19.7167 4.60863 19.4901L6.28002 15.2125H15.8268L17.4981 19.4901C17.5831 19.7167 17.8098 19.83 18.0081 19.83H21.0109C21.4925 19.83 21.8324 19.4051 21.6341 18.9235L14.3253 0.339944C14.2404 0.113315 14.0137 0 13.8154 0H8.29135C8.09305 0 7.86642 0.113315 7.78143 0.339944L0.472652 18.9235C0.302681 19.3768 0.614295 19.83 1.09588 19.83ZM7.7531 11.5297L11.0676 2.91785L14.4103 11.5297H7.7531Z"
            fill="#5F1D1B"
          ></path>
          <path
            d="M40.0975 20C40.5791 20 40.9474 19.915 40.9474 19.1501V16.8839C40.9474 16.4589 40.8341 16.3173 40.3808 16.289C38.9927 16.2323 38.936 13.5694 37.123 11.898C38.9077 10.7932 39.8992 8.95184 39.8992 6.60057C39.8992 2.63456 37.0947 0 32.5904 0H23.9502C23.6952 0 23.5253 0.141643 23.5253 0.424929V19.4051C23.5253 19.6601 23.6952 19.83 23.9502 19.83H27.0664C27.3496 19.83 27.4913 19.6601 27.4913 19.4051V13.2011H31.8822C36.2165 13.2011 34.3185 20 40.0975 20ZM27.4913 9.51841V3.68272H32.7321C34.7151 3.68272 35.9332 4.70255 35.9332 6.60057C35.9332 8.49858 34.7151 9.51841 32.7321 9.51841H27.4913Z"
            fill="#5F1D1B"
          ></path>
          <path
            d="M49.4827 19.83H53.2787C53.477 19.83 53.7037 19.7167 53.7886 19.4901L61.664 0.906516C61.8623 0.424929 61.5224 0 61.0408 0H57.8396C57.6413 0 57.4147 0.113315 57.3297 0.339944L51.3807 15.2691L45.4317 0.339944C45.3467 0.113315 45.1201 0 44.9218 0H41.7207C41.2391 0 40.8991 0.424929 41.0974 0.906516L48.9728 19.4901C49.0578 19.7167 49.2844 19.83 49.4827 19.83Z"
            fill="#5F1D1B"
          ></path>
          <path
            d="M59.982 19.83H62.9848C63.1831 19.83 63.4097 19.7167 63.4947 19.4901L65.1661 15.2125H74.7129L76.3842 19.4901C76.4692 19.7167 76.6959 19.83 76.8942 19.83H79.897C80.3786 19.83 80.7185 19.4051 80.5202 18.9235L73.2114 0.339944C73.1265 0.113315 72.8998 0 72.7015 0H67.1775C66.9791 0 66.7525 0.113315 66.6675 0.339944L59.3588 18.9235C59.1888 19.3768 59.5004 19.83 59.982 19.83ZM66.6392 11.5297L69.9537 2.91785L73.2964 11.5297H66.6392Z"
            fill="#5F1D1B"
          ></path>
        </svg>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3996_376)">
            <path
              d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
              stroke={false ? "#FFFBF2" : "#5F1D1B"}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3996_376">
              <rect
                width="20"
                height="20"
                rx="10"
                transform="matrix(-1 0 0 1 20 0)"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        {/* <div className="chip">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3996_376)">
              <path
                d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
                stroke={false ? "#FFFBF2" : "#5F1D1B"}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_3996_376">
                <rect
                  width="20"
                  height="20"
                  rx="10"
                  transform="matrix(-1 0 0 1 20 0)"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
          Guide
        </div> */}
      </div>
      {/* <div style={{ padding: "50px 0 0 0" }}></div> */}
      {/* <div className="inner_guide_page_3_title" ref={TopAnimation}>
        {GuideData?.title}
      </div> */}
      <div className="inner_guide_page_3_img_list">
        {[...ListImage?.images].map((el, i) => (
          // <span
          //   key={i}
          //   className="inner_guide_page_3_img_list_box"
          //   // style={{
          //   //   backgroundImage: `url(${asset(el)})`,
          //   // }}
          // >
          <img width="100%" src={asset(el)} />
          // </span>
        ))}
      </div>
    </div>
  );
};
