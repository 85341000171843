import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { utilsArray } from "../../../utils/utils";
import {
  MixPanelGoalChange,
  MixPanelIntake,
  MixPanelUsertype,
} from "../../../component/API/automation_api";
import { checkToken, updateGoal } from "../../../component/API/new_api";
import { useDispatch, useSelector } from "react-redux";
import BottomBtnSection from "../components/BottomBtnSection";
import { MultiSelect } from "../../../component/CheckOut/Intake/MultiSelect";

const TrackingArva = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  profile,
  lastdata,
  AllData,
  setBeginBtn,
}) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [TrackingData, setTrackingData] = useState([
    "Just curious to learn about my fertility",
    "I’m exploring options like Egg Freezing",
    "I want kids but I won’t get started for at least another year",
    "I’m trying for kids as we speak (without assistance)",
    "I’m trying for kids as we speak (with fertility treatment)",
    "I don’t want biological kids. I’m here to learn more about my body.",
  ]);
  const [MultiSelectData, setMultiSelectData] = useState([]);
  const [DetailList, setDetailList] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });
  const navigate = useNavigate();
  const [CohorFilter, setCohorFilter] = useState("");
  const check_user_data = (token) => {
    checkToken(
      token || "",
      (success) => {
        if (success.customer == null) {
          navigate("/404");
        }
        if (!MultiSelectData) {
          setMultiSelectData(
            success?.customer?.intake?.reproductive_health?.goal
          );
        }
        MixPanelUsertype(success);
        dispatch({
          type: "setCheckToken",
          payload: { ...success, token: token },
        });
      },
      (err) => {}
    );
  };
  useEffect(() => {
    if (store.checktoken_data.token) {
      setMultiSelectData(
        store?.checktoken_data?.customer?.intake?.reproductive_health?.goal
      );
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        // window.open("http://localhost:3000/", "_self");
        navigate("/home");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);

  useEffect(() => {
    setCohorFilter(utilsArray("cohortList")[pagevalues?.tracking_arva]);
    if (pagevalues?.tracking_arva) {
      setMultiSelectData(pagevalues.tracking_arva);
    }
  }, [pagevalues?.tracking_arva]);
  return (
    <>
      <div className="intake_body">
        <div className="container track_arva_main">
          <div className="track_arva_title">
            {/* Why are you tracking your fertility with Arva Health? */}
            What brings you to Arva Health?
          </div>
          <div className="track_arva_desc">
            Understanding your goal helps us customise your experience.{" "}
          </div>
          <MultiSelect
            {...{
              list: TrackingData,
              onMultiClick: (el) => setMultiSelectData(el),
              MultiSelectData: MultiSelectData,
              multisel: false,
            }}
          />
        </div>
      </div>
      {profile ? (
        <BottomBtnSection
          {...{
            control: {
              txt: "Update goal",
              // txt: "Next",
              onBtnClick: () => {
                if (MultiSelectData.length <= 0) {
                  return;
                }
                console.log(MultiSelectData);
                MixPanelGoalChange({
                  prev:
                    store?.checktoken_data?.customer?.intake
                      ?.reproductive_health?.goal || "Not Set",
                  curr: MultiSelectData || "",
                });
                // return;
                updateGoal(
                  store.checktoken_data.token,
                  MultiSelectData,
                  (success) => {
                    window.location.reload();
                  },
                  (err) => {}
                );
              },
            },
          }}
        />
      ) : (
        <BottomBtnSection
          {...{
            control: {
              txt: "Next",

              onBtnClick: () => {
                // if(){

                if (MultiSelectData.length <= 0) {
                  return;
                }
                if (window.location.pathname.match("intake")) {
                  setRangeCount(1);
                  setPages("birthdate");
                  setPagevalues((prev) => ({
                    ...prev,
                    tracking_arva: MultiSelectData,
                  }));
                  MixPanelIntake({
                    step_name:
                      // "Why are you tracking your fertility with Arva Health?",
                      "What brings you to Arva Health?",
                    // section_name: "Reproductive Health",
                    section_name: "Personal Info",
                    value: pagevalues.tracking_arva,
                    step_index: 1,
                    total_steps: 5,
                    remaining: lastdata,
                  });
                  return;
                }

                // if (singleBeginRange) {
                //   setRangeCount(6);
                // } else {
                //   setRangeCount(23);
                // }
                MixPanelGoalChange({
                  prev: AllData?.user_data?.tracking_arva || "Not Set",
                  curr: pagevalues?.tracking_arva || "",
                });
                setRangeCount(1);
                setPages("birthdate");
              },
              goBackClick: () => {
                setBeginBtn(false);
                setRangeCount(0);
                setPages("begin");

                // setRangeCount(21);
                // setPages("medication");
              },
            },
          }}
        />
      )}
    </>
  );
};

export default TrackingArva;
