import React, { useEffect, useState } from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import { MultiSelect } from "../../../component/CheckOut/Intake/MultiSelect";
import { MixPanelIntake } from "../../../component/API/automation_api";

export const DrprevDignosed = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  setlastdata,
  goto,
}) => {
  const [DrprevDignosedData, setDrprevDignosedData] = useState([
    "PCOS",
    "Thyroid disorder (like Hashimoto’s)",
    "Endometriosis",
    "Fibroids",
    "Cysts",
    "Ectopic pregnancy",
    "Fallopian tube blockage",
    "Hypothalamic Amenorrhea (or Hypogonadotropic Hypogonadism)",
    "Pelvic Inflammatory Disease (PID)",
    "Premature ovarian insufficiency (POI)",
    "Other",
  ]);

  const [MultiSelectData, setMultiSelectData] = useState([]);
  useEffect(() => {
    setMultiSelectData(pagevalues?.prev_dignosed);
  }, []);
  useEffect(() => {
    setPagevalues((prev) => ({
      ...prev,
      prev_dignosed: [...(MultiSelectData || [])],
    }));
    setlastdata((prev) => ({
      ...prev,
      reported_conditions: MultiSelectData?.length > 0 ? MultiSelectData : [],
    }));
  }, [MultiSelectData]);

  return (
    <>
      <div className="intake_body">
        <div className="container drprevdignosed_main">
          <div className="drprevdignosed_title">
            Has a doctor previously diagnosed you with any of the following?
          </div>
          <div className="drprevdignosed_desc">
            Along the journey, some people learn about underlying causes of
            their fertility challenges. A diagnosis can sometimes even bring
            relief because it gives you a place to focus your efforts. But, it’s
            also ok to not know if these apply to you.
          </div>
          <MultiSelect
            {...{
              list: DrprevDignosedData,
              onMultiClick: (el) => setMultiSelectData(el),
              MultiSelectData: MultiSelectData,
              multisel: true,
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              // if (pagevalues.dr_prev_dignosed.length <= 0) {
              //   return;
              // }
              MixPanelIntake({
                step_name:
                  "Has a doctor previously diagnosed you with any of the following?",
                section_name: "Reproductive Health",
                value: pagevalues.dr_prev_dignosed || "N/A",
                step_index: 1,
                total_steps: 8,
                remaining: lastdata,
              });
              if (singleBeginRange) {
                if (MultiSelectData.length > 0) {
                  if (
                    MultiSelectData?.filter((el) => el == "PCOS").length === 1
                  ) {
                    setRangeCount(1);

                    setPages("pcos");
                  } else {
                    setRangeCount(2);
                    setPages("singleOrMingle");
                  }
                } else {
                  setRangeCount(2);
                  setPages("singleOrMingle");
                }
              } else {
                if (MultiSelectData?.length > 0) {
                  if (MultiSelectData?.includes("PCOS")) {
                    // setRangeCount(1);
                    setRangeCount(18);

                    setPages("pcos");
                  } else {
                    // setRangeCount(2);
                    setRangeCount(19);
                    setPages("singleOrMingle");
                  }
                } else {
                  // setRangeCount(2);
                  setRangeCount(18);
                  setPages("singleOrMingle");
                }
                // if(MultiSelectData?.filter((el) => el == "PCOS").length === 1){
                //   setRangeCount(17);
                //   setPages("pcos");
                // }
                // else{
                //   setRangeCount(18);
                //   setPages("birthcontrol");
                // }
              }
            },
            goBackClick: () => {
              setRangeCount(16);
              setPages("reporeductivehealthbegin");
            },
          },
        }}
      />
    </>
  );
};
