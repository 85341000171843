import React, { useEffect, useState } from "react";
import { ReportType } from "./ReportType";
import { Summary } from "./Summary";
import { NextStep } from "./NextStep";
import { HomeSection3 } from "../Home/HomeSection3";
import { Faq } from "./Faq";
import { BaseOnResult } from "./BaseOnResult";

export const Pcos = ({
  PageValue,
  data,

  DataSelect,
  FurtherDataMixPanel,
  allreport,
  reports,
}) => {
  const [ControlList, setControlList] = useState({
    name: "Insulin Resistant",
    relatedBiomarkers: [],
    description:
      "Think of your ovarian reserve as a basket of eggs. We’re typically born with a basket full of eggs, and every month, one mature egg is formed and released. By the time we reach the end of our reproductive window, also known as menopause, the basket is nearly empty.",
  });
  const [LabRangeObj, setLabRangeObj] = useState({
    chip: [],
    bcolor: "#F8E8FA",
    fcolor: "#B418D1",
  });
  const [AndrogenExcess, setAndrogenExcess] = useState("");
  //
  useEffect(() => {
    setLabRangeObj(PageValue?.last_order?.order_details?.testChipdata?.pcos);
  }, [PageValue]);
  //
  useEffect(() => {
    setControlList({ ...data?.basedOnYourResults[0] });
  }, []);

  const birthdateString = PageValue?.last_intake?.data?.birth_date;

  // Parse your birthdate string into a Date object
  const birthdate = new Date(birthdateString);

  // Get the current date
  const currentDate = new Date();

  // Calculate the age in years
  const ageInMilliseconds = currentDate - birthdate;
  const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);

  // const foundAMHItem = list_bioData?.filter((el, i) => el.name === "AMH")[0];
  // let labRangeItem = {};
  // if (foundAMHItem) {
  //   //
  //   labRangeItem = foundAMHItem.labRange.find(
  //     (item) =>
  //       Math.floor(ageInYears) >= item.ageMin &&
  //       Math.floor(ageInYears) <= item.ageMax
  //   );
  //   // setLabRangeObj(labRangeItem);
  // } else {
  // }

  return (
    <div>
      {/* <ReportType
        {...{
          clientName: `For ${PageValue?.last_recorder_order.data?.sheet_data[12]}`,
          reportType: "Signs of PCOS",
          desc: "PCOS, is a hormone imbalance that often results in irregular periods or lack of ovulation, which in turn, affects your fertility.",
          bgcolor: "#F8E8FA",
          fcolor: "#B418D1",
        }}
      /> */}
      <ReportType
        {...{
          clientName: `For ${reports[0]?.user?.name}`,
          reportType: data?.name,
          desc: data?.description,
          bgcolor: "#F8E8FA",
          fcolor: "#B418D1",
        }}
      />
      <Summary
        {...{
          img: reports[0]?.cards?.signs_of_pcos?.image,
          type: "pcos",
          bgcolor: "#F8E8FA",
          fcolor: "#B418D1",
          main_title: "Your Signs of PCOS summary",
          summarytitle:
            reports[0]?.cards?.signs_of_pcos?.summaryInsight?.heading,
          chipcolor: LabRangeObj,
          summarydesc:
            reports[0]?.cards?.signs_of_pcos?.summaryInsight?.description,
          typereportlearn: reports[0]?.cards?.signs_of_pcos?.chips,
          physicalsymptoms: [...reports[0].intake.experience_symptoms],
        }}
      />
      {/* <HomeSection3
        {...{
          title: "",
          data: [
            {
              name: "Our unique way of assessing PCOS at Arva Health",
              descti: "Article • 3 min",
              img: "",
            },
          ],
        }}
      /> */}
      <BaseOnResult
        {...{
          list: [...data?.basedOnYourResults],
          ControlList: ControlList,
          name: data?.name,
          setControlList: setControlList,
          age: Math.floor(ageInYears),
          // PageValue: PageValue?.user_data,
          FullList: PageValue,
          FurtherDataMixPanel: FurtherDataMixPanel,
          physicalsymptoms: [...reports[0].intake.experience_symptoms],
          accordianData: [
            {
              name: `${ControlList.name} `,
              desc: ControlList.description,
            },
          ],
          reports,
        }}
      />
      {reports[0]?.cards?.signs_of_pcos?.summaryInsight?.relatedArticles
        ?.length > 0 ? (
        <div className="homesection3_detail_shower_all_test">
          <HomeSection3
            {...{
              title: "Explore PCOS",
              FurtherDataMixPanel: FurtherDataMixPanel,
              screen: "Signs of PCOS",
              data:
                reports[0]?.cards?.signs_of_pcos?.summaryInsight
                  ?.relatedArticles?.length > 0
                  ? [
                      ...reports[0]?.cards?.signs_of_pcos?.summaryInsight?.relatedArticles?.map(
                        (el) => ({
                          name: el.title,
                          descti: el.topic,
                          img: el.image,
                          slug: el.slug,
                          description: el.description,
                        })
                      ),
                    ]
                  : [],
            }}
          />
        </div>
      ) : (
        ""
      )}

      <Faq
        {...{
          openAcc: false,
          unique: "pcos_faq",
          titleDis: true,
          data: [
            {
              que: "What now for my PCOS diagnosis?",
              ans: "Even if you have a higher quantity of eggs, it's possible to experience anovulatory cycles, where no egg is released—resulting in no chance for fertilisation. For folks dealing with PCOS and the associated weight management struggles, it's reassuring to know that proper treatment and management can significantly restore ovulatory function.",
            },
            {
              que: "How does a hormone test help understand PCOS?",
              ans: "PCOS is diagnosed by considering both your physical symptoms and hormone levels. Arva Health provides a comprehensive PCOS test that assesses over 8 hormones crucial for a PCOS diagnosis. These hormones are the same ones utilised by doctors in India and around the world to diagnose PCOS. ",
            },
            {
              que: "Trying to conceive with PCOS",
              ans: "Naturally conceiving with PCOS can delay the time to pregnancy. However, there are many medications like clomiphene and letrozole that can make a real difference. Additionally, assisted reproductive techniques such as in vitro fertilisation (IVF), intracytoplasmic sperm injection (ICSI), and intrauterine insemination (IUI) can also assist women with PCOS in getting pregnant, although these are invasive interventions. Consult a doctor to know more. ",
            },
          ],
        }}
      />
      <NextStep />
    </div>
  );
};
