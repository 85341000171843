import React, { useEffect, useState } from "react";
import Bottom_Btn_section from "./Bottom_Btn_section";
import { CustInput, date_formatter } from "../../CustInput/CustInput";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { MixPanelIntake } from "../../API/automation_api";
import BottomBtnSection from "../../../pages/NewIntake/components/BottomBtnSection";
// import AccessibleIcon from "@mui/icons-material/Accessible";

const BirtthdateSection = ({
  goto,
  setBeginBtn,
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  lastdata,
}) => {


  const handle_pagevalues = (updated_data) => {
    return setPagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const handleDayChange = (event) => {
    const inputValue = event.target.value;
    if (/^\d{0,2}$/.test(inputValue) && parseInt(inputValue, 10) <= 31) {
      setDay(inputValue);
    }
  };

  const handleMonthChange = (event) => {
    const inputValue = event.target.value;
    if (/^\d{0,2}$/.test(inputValue) && parseInt(inputValue, 10) <= 12) {
      setMonth(inputValue);
    }
  };

  const handleYearChange = (event) => {
    const inputValue = event.target.value;
    if (/^\d{0,4}$/.test(inputValue)) {
      setYear(inputValue);
    }
  };

  return (
    <>
      <div className="intake_body">
        <div className="container birthdate_section_main">
          <div className="birthdate_title">
            When is your birthday, {pagevalues.name}?
          </div>
          <div className="birthdate_desc">
            No triggers intended. While we know the fertility cliff of 35 is a
            myth, age is an important part of fertility and knowing it will help
            us tailor your results.
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className={
                "cust_input_birthdate" +
                (err_pagevalues.birth_date ? " inp_error" : "")
              }
              label="Date of birth"
              sx={{
                width: "100%",
                outline: 0,
                "&.MuiTextField-root": {
                  display: "flex",
                  flexDirection: "column-reverse",
                  "&:focus-within>label": {
                    display: "block",
                  },
                },
                "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                  border: "1.5px solid var(--gray-g-200)",
                  borderRadius: "5px",
                },
                " label": {
                  color: "#777777 !important",
                },
                "& input": {
                  color: "#000",
                  fontWeight: 500,
                },
                "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                  border: "1.5px solid var(--red) !important",
                  outline: "0px !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "var(--brand-peach-soda) !important",
                  borderWidth: "2px !important",
                },
              }}
              value={
                pagevalues.birth_date
                  ? dayjs(
                      date_formatter(
                        new Date(pagevalues.birth_date),
                        "year_long-month-date"
                      )
                    )
                  : pagevalues.birth_date
              }
              slotProps={{
                textField: {
                  error: err_pagevalues.birth_date, // Controls the error state
                },
              }}
              onChange={(value) => {
                handle_pagevalues({ birth_date: value });
                handle_errpagevalues({
                  birth_date: !value || value.$d == "Invalid Date",
                });
              }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (
                !pagevalues.birth_date ||
                new Date(pagevalues?.birth_date) == "Invalid Date"
              ) {
                seterr_pagevalues((prev) => ({
                  ...prev,
                  birth_date:
                    !pagevalues.birth_date ||
                    new Date(pagevalues?.birth_date) == "Invalid Date",
                }));
                return;
              }
              MixPanelIntake({
                step_name: "When is your birthday?",
                section_name: "Personal Info",
                value: pagevalues.birth_date,
                step_index: 2,
                total_steps: 5,
                remaining: lastdata,
              });
              setRangeCount(2);
              setPages("bmi");
            },
            goBackClick: () => {
              // setBeginBtn(false);
              setRangeCount(0);
              setPages("tracking");
            },
          },
        }}
      />
    </>
  );
};

export default BirtthdateSection;
