import React from "react";

import { MixPanelIntake } from "../../../component/API/automation_api";
import BottomBtnSection from "../components/BottomBtnSection";
import CustInputMuiClone from "../../../component/CustInput/CustInputMuiClone";

export const TallSection = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  lastdata,
  goto,
  startfrom,
  sectionno,
}) => {
  const handle_pagevalues = (updated_data) => {
    return setPagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  return (
    <>
      <div className="intake_body">
        <div className="container tall_section_main">
          <div className="tall_section_title">How tall are you?</div>
          <div className="tall_section_desc">
            Please input your height in feet.
          </div>
          <div className="inp_collection_height">
            <CustInputMuiClone
              {...{
                attr: {
                  autofocus: true,
                  error: err_pagevalues.tall_feet,
                  error_msg: "",
                  value: pagevalues.tall_feet,
                  inputMode: "numeric",
                  event: {
                    change: ({ target }) => {
                      if (Number(target.value.slice(-1)).toString() == "NaN") {
                        return;
                      }

                      handle_pagevalues({ tall_feet: target.value });
                      handle_errpagevalues({
                        tall_feet: target.value ? "" : "Error message",
                      });
                    },
                  },
                },
                data: {
                  title: "",
                  suffix: "ft",
                },
              }}
            />
            <CustInputMuiClone
              {...{
                attr: {
                  error: err_pagevalues.tall_inch,
                  error_msg: "",
                  value: pagevalues.tall_inch,
                  inputMode: "numeric",
                  event: {
                    change: ({ target }) => {
                      if (Number(target.value.slice(-1)).toString() == "NaN") {
                        return;
                      }

                      handle_pagevalues({ tall_inch: target.value });
                      handle_errpagevalues({
                        tall_inch: target.value ? "" : "Error message",
                      });
                    },
                  },
                },
                data: {
                  title: "",
                  suffix: "in",
                },
              }}
            />
          </div>
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (!pagevalues.tall_feet || !pagevalues.tall_inch) {
                seterr_pagevalues((prev) => ({
                  ...prev,
                  tall_feet: !pagevalues.tall_feet,
                  tall_inch: !pagevalues.tall_inch,
                }));
                return;
              }
              MixPanelIntake({
                step_name: "How tall are you?",
                section_name: "Personal Info",
                value:
                  pagevalues.tall_feet +
                  "ft" +
                  " " +
                  pagevalues.tall_inch +
                  "in",
                step_index: 3,
                total_steps: 5,
                remaining: lastdata,
              });
              setRangeCount(4);
              setPages("weight");
            },
            goBackClick: () => {
              setRangeCount(2);
              setPages("bmi");
            },
          },
        }}
      />
    </>
  );
};
