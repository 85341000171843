import React, { useState } from "react";
import TestCard from "./Cards/TestCard";
import { addAppoiuntment, dateFormatter } from "../../../component/API/new_api";
import CalendlyModal from "../../../component/Calendly/CalendlyModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getCalendlyUrl } from "../../../utils/utils";

const HomeNewUpcomin = ({ data, hide, ProductImages }) => {
  console.table(hide);
  const navigate = useNavigate();
  const store = useSelector((state) => state);

  const [curr_active, setcurr_active] = useState("");
  const [showall, setshowall] = useState(false);
  const [calendlyModalState, setcalendlyModalState] = useState(false);
  const [calendlyData, setcalendlyData] = useState({
    url: "",
    onSubmit: () => "",
  });
  console.log(data);
  return (
    <div className="home_new_next home_new_upcoming">
      {data?.filter((el) => el.product_type !== "guide")?.length ? (
        <div className="next_header">
          Upcoming
          {data?.filter(
            (el) => el.product_type !== "guide" && el._id !== hide?._id
          )?.length > 2 && (
            <span onClick={() => setshowall((prev) => !prev)}>See all</span>
          )}
        </div>
      ) : (
        ""
      )}
      {[
        ...data.map(
          ({
            _id,
            product_type,
            product_name,
            product_status,
            product_id,
            priority,
            orderId,
            orderDate,
            assignedTo,
            appointment,
            consideredDate,
            dates,
            discount_code,
            // not_show,
          }) => {
            //
            if (["guide"].includes(product_type.toLowerCase())) {
              return "";
            }
            if (["coaching", "consult"].includes(product_type.toLowerCase())) {
              // return appointment.map(
              return appointment
                .filter((el1) => el1.isEnabled && !el1?.isCompleted)
                .map((items) => {
                  if (_id == hide?._id && items._id === hide?.appointment_id) {
                    return null;
                  }
                  let url =
                    product_type.toLowerCase() == "coaching"
                      ? {
                          link: "https://calendly.com/anushreefertility/fertility-health-coaching",
                          name: "Anushree Mahajan",
                        }
                      : getCalendlyUrl(
                          discount_code + "_" + product_id,
                          store?.checktoken_data?.customer?.address?.state,
                          product_id
                        );
                  console.log({ url });
                  return {
                    id: _id,
                    item_id: items._id,
                    type: product_type,
                    product_id,
                    // title: product_name,
                    name: product_name,
                    // name: discount_code + "_" + product_id,
                    time: dateFormatter(
                      items?.calendlyData?.startTime,
                      // orderDate.split("/").reverse().join("-"),
                      "dddd, MMF DD, hh:mm A"
                      // "dddd, MMF D"
                    ),
                    // "Saturday, Apr 14, 11:00 AM",
                    action: () => {
                      if (!store?.checktoken_data?.customer?.intake) {
                        return navigate("/intake");
                      }
                      if (items?.calendlyData?.location) {
                        setcalendlyModalState(true);
                        // console.log(items?.calendlyData?.rescheduleUrl);
                        // return;
                        console.log(url);
                        setcalendlyData({
                          url: url.link,
                          onSubmit: (evenetData) => {
                            addAppoiuntment(
                              store.checktoken_data.token,
                              items._id,
                              {
                                ...evenetData,
                                type: product_type,
                                doctor_name: url.name,
                              },
                              (success) => {
                                window.location.reload();
                              },
                              (err) => {}
                            );
                          },
                        });
                      } else {
                        setcalendlyModalState(true);
                        setcalendlyData({
                          url: url.link,
                          // url: items.calendlyData.eventUrl,
                          // url: "https://calendly.com/arvahealth/testing-events",
                          onSubmit: (evenetData) => {
                            //
                            // return;
                            // console.error("add appointment aad;asdasd");
                            addAppoiuntment(
                              store.checktoken_data.token,
                              items._id,
                              {
                                ...evenetData,
                                type: product_type,
                                doctor_name: url.name,
                              },
                              (success) => {
                                window.location.reload();
                              },
                              (err) => {
                                // toast.error("calendy not updated");
                              }
                            );
                          },
                        });
                      }
                    },
                  };
                });
            }
            if (_id == hide?._id) {
              return null;
            }
            return {
              id: _id,
              type: product_type,
              // title: product_name,
              product_id,
              name: product_name,
              time: dateFormatter(
                [consideredDate?.split(" ")[0]].reduce((acc, curr) => {
                  let [dd, mm, yy] = curr.split("/");
                  return new Date(yy, mm - 1, dd);
                }, ""),
                "dddd, MMF DD YYYY"
                // "dddd, MMF D"
              ),
              // "Saturday, Apr 14, 11:00 AM",
              action: () => navigate("/bookingslot?" + _id),
              // btns:
              //   product_status.toLowerCase() == "order confirmed" ? (
              //     <>
              //       {/* NA */}
              //       <div
              //         className="btn type1"
              //         onClick={() => {
              //           //
              //           navigate("/bookingslot?" + _id);
              //           // toast.error("NA");
              //         }}
              //       >
              //         Pick a time
              //       </div>
              //       {/* <div className="btn type2">"Reschedule"</div> */}
              //     </>
              //   ) : (
              //     ""
              //   ),
              // log: orderDate,
            };
          }
        ),
      ]
        .flat()
        .filter((el) => el)
        .slice(0, showall ? data.length + 1 : 2)
        .map((el, i) => (
          <div
            className="card"
            onClick={
              () => (el?.action ? el.action() : "")
              // ""
            }
          >
            {el?.log}
            {/* <div className="type" onClick={""}>
              <div className="type_main">{el.type}</div>
            </div> */}
            {/* {console.log(el)} */}
            <div className="type_body">
              <div
                className="type_img"
                style={{
                  backgroundImage: `url(${ProductImages[el?.product_id]?.img})`,
                }}
              >
                {ProductImages[el.product_id]?.img ? (
                  ""
                ) : (
                  <div className="svg_floater_main">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 64 65"
                      fill="none"
                    >
                      <path
                        d="M64 32.9972C64 50.6712 49.674 64.9972 32 64.9972C14.326 64.9972 0 50.6712 0 32.9972C0 15.3232 14.326 0.997192 32 0.997192V4.99719C16.536 4.99719 4 17.5332 4 32.9972C4 48.4612 16.536 60.9972 32 60.9972C47.464 60.9972 60 48.4612 60 32.9972H64Z"
                        fill="#5F1D1B"
                        // fill="#1D1D1D"
                      />
                    </svg>
                  </div>
                )}
              </div>
              <div className="txt">
                <div>{el.title}</div>
                <div className="product_name">{el.name}</div>
                <div className="time">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g opacity="0.6">
                      <path
                        d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                        stroke="#5F1D1B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                  {el?.type == "test" ? (
                    <div>Schedule</div>
                  ) : (
                    <div>Book slot</div>
                  )}
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.29279 14.707C7.10532 14.5195 7 14.2652 7 14C7 13.7349 7.10532 13.4806 7.29279 13.293L10.5858 10L7.29279 6.70704C7.11063 6.51844 7.00983 6.26584 7.01211 6.00364C7.01439 5.74144 7.11956 5.49063 7.30497 5.30522C7.49038 5.11981 7.74119 5.01465 8.00339 5.01237C8.26558 5.01009 8.51818 5.11088 8.70679 5.29304L12.7068 9.29304C12.8943 9.48057 12.9996 9.73488 12.9996 10C12.9996 10.2652 12.8943 10.5195 12.7068 10.707L8.70679 14.707C8.51926 14.8945 8.26495 14.9998 7.99979 14.9998C7.73462 14.9998 7.48031 14.8945 7.29279 14.707Z"
                    fill="#5F1D1B"
                  />
                </svg>
              </div>
            </div>
            {el?.btns ? (
              <div
                className="btn_group_vertical"
                // style={{ height: curr_active == el.id ? "fit-content" : 0 }}
                style={{ display: curr_active == i + 1 ? "unset" : "none" }}
              >
                {el?.btns || ""}
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      <CalendlyModal
        {...{
          ...calendlyData,
          // url: "https://calendly.com/arvahealth/testing-events",
          isOpen: calendlyModalState,
          setIsOpen: (state) => setcalendlyModalState(state),
          // onSubmit: (data) => {
          //
          //   {
          //     "event": {
          //         "uri": "https://api.calendly.com/scheduled_events/da36d149-2402-4b2b-8581-903c90f15c3e"
          //     },
          //     "invitee": {
          //         "uri": "https://api.calendly.com/scheduled_events/da36d149-2402-4b2b-8581-903c90f15c3e/invitees/b96b5765-9153-463a-8cc0-950c9fc3ba44"
          //     }
          // }
          // },
        }}
      />
    </div>
  );
};

export default HomeNewUpcomin;

export const Cards = () => {};
