import React from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import CustInputMuiClone from "../../../component/CustInput/CustInputMuiClone";
import { MixPanelIntake } from "../../../component/API/automation_api";

export const MomAge = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  goto,
}) => {
  const handle_pagevalues = (updated_data) => {
    return setPagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  return (
    <>
      <div className="intake_body">
        <div className="container weight_section_main">
          <div className="weight_title">
            How old was your mom when she gave birth to you?
          </div>
          <div className="weight_desc">
            Your mother's fertility history can give you clues about your own.
          </div>
          <CustInputMuiClone
            {...{
              attr: {
                autofocus: true,
                error: err_pagevalues.momage,
                error_msg: "",
                inputMode: "numeric",
                value: pagevalues.momage,
                event: {
                  change: ({ target }) => {
                    if (Number(target.value.slice(-1)).toString() == "NaN") {
                      return;
                    }

                    handle_pagevalues({ momage: target.value });
                    handle_errpagevalues({
                      momage: target.value ? "" : "Error message",
                    });
                  },
                },
              },
              data: {
                title: "",
                suffix: "yrs",
              },
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (!pagevalues.momage) {
                seterr_pagevalues((prev) => ({
                  ...prev,
                  momage: !pagevalues.momage,
                }));
                return;
              }
              MixPanelIntake({
                step_name: "How old was your mom when she gave birth to you?",
                section_name: "Family History",
                value: pagevalues.momage + "yrs",
                step_index: 1,
                total_steps: 3,
                remaining: lastdata,
              });
              if (singleBeginRange) {
                setRangeCount(1);
              } else {
                setRangeCount(14);
              }
              setPages("fatherstrength");
            },
            goBackClick: () => {
              setRangeCount(12);
              setPages("familyhsitory");
            },
          },
        }}
      />
    </>
  );
};
