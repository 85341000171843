import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Icons } from "../../component/Icons/Icons";
import {
  calculateAge,
  checkToken,
  getResults,
} from "../../component/API/new_api";
import { MixPanelUsertype } from "../../component/API/automation_api";
import { createCards, labRangeMaker } from "../../utils/results";
import { LevelResultV2 } from "../../component/Results/LevelResultV2";
import { ListReports } from "../../component/Results/ListReports";
import { LevelResultV3 } from "../../component/ResultsV2/LevelResultV3";
import { LevelV2 } from "./LevelV2";
import { ReportsV2 } from "./ReportsV2";
import { PrescriptionsV2 } from "./PrescriptionsV2";
import { ScansV2 } from "./ScansV2";
import { result } from "lodash";
import Slider from "react-slick";
import { BottomNav } from "../../component/BottomNav";
import { ViewTests } from "./ViewTests";

export const ResultsV2 = ({ setloading }) => {
  const store = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type } = useParams();
  const [prescription, setprescription] = useState([]);
  const [scans, setscans] = useState([]);
  const [indexCtr, setIndexCtr] = useState(0);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // adaptiveHeight: true,

    customPaging: function (i) {
      return <div className={"dots "}></div>;
    },
  };
  const [BlankScreen, setBlankScreen] = useState(true);
  const [reports, setreports] = useState([]);
  const [DataSelect, setDataSelect] = useState("");

  const [MiniNavi, setMiniNavi] = useState(false);
  const [currreports, setcurrreports] = useState({});
  const [ListDates, setListDates] = useState([]);
  const [AllData, setAllData] = useState({
    last_recorder_order: {},
    last_order: {
      // ...CurrentShowingOrder,
      published: false,
      order_details: {
        level_list: [],
      },
    },
    last_intake: {},
    user_data: {},
    all_order: [],
  });
  const [FilterArrayTest, setFilterArrayTest] = useState([]);
  const [current_report_data, setcurrent_report_data] = useState({});
  const [FurtherDataMixPanel, setFurtherDataMixPanel] = useState({
    sign_up_date: "",
    user_id: "",
    last_order_type: "",
    name: "",
    birthdate: "",
    is_test_user: false,
    user_current_goal: "",
    is_paid_user: false,
    reported_symptoms: "",
    reported_conditions: "",
    is_ultrasound_done: false,
    is_birth_control: false,
  });
  //   console.log(type);

  // useEffect(() => {
  //   navigate("/result/levels");
  // }, []);

  const process_me = (result_data, user_data) => {
    console.log(result_data, user_data);
    if (user_data.customer == null) {
      setloading(false);
      navigate("/404");
    }

    MixPanelUsertype(user_data);

    console.log(1);
    if (result_data?.reports?.length == 0) {
      console.log(2);
      if (result_data?.presciptions?.length > 0) {
        console.log(3);
        navigate("/result/prescriptions");
      } else if (result_data?.scans?.length > 0) {
        console.log(4);
        navigate("/result/scans");
      }
    }
    let user_dob = user_data?.customer?.intake?.dob || "";
    if (!user_dob) {
      return;
    }
    let completed_report = result_data?.reports?.map((report) => {
      let obj = { ...report };
      return {
        ...obj,
        date: obj?.orderDetails?.orderDate,
      };
    });
    // console.log(completed_report);

    let t = completed_report.map((report) => {
      let age = calculateAge(user_dob);
      let t_ranges = labRangeMaker(report.labContent).map((el) => {
        let result_values = {};
        report.results.map(
          (el1) => (result_values[el1.shortCode] = { value: el1.value })
        );
        let t = {
          ...el,
          ...el.labRange(
            age,
            {
              value: "",
              score: "pending",
              ...(result_values ? result_values[el?.biomarkerCode] || {} : {}),
            },
            el?.lab_name || ""
          ),
        };
        delete t.labRange;
        return t;
      });
      return {
        ...report,
        age,
        dob: user_dob,
        user: {
          name: user_data?.customer?.user?.first_name,
        },
        intake: {
          experience_symptoms:
            user_data?.customer?.intake?.menstrual_health?.experience_symptoms,
        },
        biomarker: t_ranges,
        cards: createCards(report.labContent.report, t_ranges, {
          ...user_data?.customer.intake,
        }),
      };
    });
    // console.log({ t });
    t.sort((a, b) => new Date(b.date) - new Date(a.date));
    setListDates(t);
    setreports(t);
    setcurrent_report_data(t[0]);

    // return;
  };

  const check_user_data = (token) => {
    // setBlankScreen(true);
    checkToken(
      token || "",
      (success) => {
        localStorage.setItem("user_me", JSON.stringify({ success, token }));
        getResults(token, (data) => {
          localStorage.setItem("results", JSON.stringify(data));
          process_me(data, success);
          setprescription(data.presciptions);
          setscans(data.scans);
        });

        setloading(false);
        setBlankScreen(false);
      },
      (err) => {}
    );
  };
  useEffect(() => {
    const user_results = localStorage.getItem("results");
    const user_me = localStorage.getItem("user_me");
    if (user_results && user_me) {
      dispatch({
        type: "setCheckToken",
        payload: JSON.parse(user_me),
      });
      console.log();

      process_me(JSON.parse(user_results), JSON.parse(user_me).success);
      setprescription(JSON.parse(user_results).presciptions);
      setscans(JSON.parse(user_results).scans);
      setBlankScreen(false);
    }
    if (store.checktoken_data.token) {
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);
  const selectedComponent = DataSelect;
  // console.log(reports);

  // function downloadFile(url, filename) {
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = filename || "download";
  //   link.style.display = "none";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }
  // downloadFile(
  //   "https://arva-lab-reports.s3.amazonaws.com/Notes/66fa4439ba8e71f65f8f47d1/Files/png/1728460824090-1728026386577.png",
  //   "report.png"
  // );
  console.log({ reports });

  return !BlankScreen ? (
    <>
      {["fertility", "pcos", "thyroid"].includes(type) ? (
        {
          fertility: (
            <ViewTests
              {...{
                MiniNavi,
                setMiniNavi,
                AllData,
                selectedComponent,
                DataSelect,
                FurtherDataMixPanel,
                reports,
              }}
            />
          ),
          pcos: (
            <ViewTests
              {...{
                MiniNavi,
                setMiniNavi,
                AllData,
                selectedComponent,
                DataSelect,
                FurtherDataMixPanel,
                reports,
              }}
            />
          ),
          thyroid: (
            <ViewTests
              {...{
                MiniNavi,
                setMiniNavi,
                AllData,
                selectedComponent,
                DataSelect,
                FurtherDataMixPanel,
                reports,
              }}
            />
          ),
        }[type]
      ) : (
        <div className="results_main_page">
          {reports.length == 0 &&
          prescription.length == 0 &&
          scans.length == 0 ? (
            <div className="results_empty_section">
              <div className="results_inner_icons">
                <Icons img_var={"results_empty"} />
              </div>
              <div className="results_empty_title">
                Your results will be available in 48 hours
              </div>
              <div className="results_empty_desc">
                We will notify you when they are ready through Whatsapp and
                Email. While you wait...
              </div>
              <div
                className="results_empty_btn"
                onClick={() => {
                  navigate("/guide/all");
                }}
              >
                Read our guide
              </div>
            </div>
          ) : (
            <div className="results_data_section">
              {/* {reports.length != 0 ? (
                <div className="results_subsec1">
                  <div className="results_subsec1_title">Insights</div>
                  <Slider {...{ ...settings }} style={{ margin: "0 -10px" }}>
                    {reports[0]?.cards?.fertility && (
                      <LevelResultV3
                        {...{
                          list: [reports[0]?.cards?.fertility].filter(
                            (el) => el
                          ),
                          setMiniNavi: setMiniNavi,
                          setDataSelect: setDataSelect,
                          FurtherDataMixPanel: FurtherDataMixPanel,
                          insidetest: false,
                          title: "",
                          // age: Math.floor(ageInYears) || 28,
                          amh: reports[0]?.biomarker?.filter(
                            (el) => el.biomarkerCode === "AMH"
                          )[0],
                          age: reports[0]?.age || 28,
                        }}
                      />
                    )}
                    {reports[0]?.cards?.signs_of_pcos && (
                      <ListReports
                        {...{
                          list: [
                            reports[0]?.cards?.signs_of_pcos || "",
                            // current_report_data?.cards?.thyroid_health || "",
                          ].filter((el) => el),
                          navto: "pcos",
                          setMiniNavi: setMiniNavi,
                          setDataSelect: setDataSelect,
                          FurtherDataMixPanel: FurtherDataMixPanel,
                        }}
                      />
                    )}
                    {reports[0]?.cards?.thyroid_health && (
                      <ListReports
                        {...{
                          list: [
                            // current_report_data?.cards?.signs_of_pcos || "",
                            reports[0]?.cards?.thyroid_health || "",
                          ].filter((el) => el),
                          setMiniNavi: setMiniNavi,
                          navto: "thyroid",
                          setDataSelect: setDataSelect,
                          FurtherDataMixPanel: FurtherDataMixPanel,
                        }}
                      />
                    )}
                  </Slider>
                </div>
              ) : (
                ""
              )} */}
              <div className="results_subsec2">
                <div className="results_subsec2_nav">
                  <div>
                    {/* reports.length != 0 ?  */}
                    {[
                      "levels",
                      "insights",
                      "reports",
                      "prescriptions",
                      "scans",
                    ].map((el, i) =>
                      !el ? (
                        ""
                      ) : (
                        <div
                          key={i}
                          className={
                            "subsec2_nav_box" + (type == el ? " active" : "")
                          }
                          onClick={() => {
                            navigate(`/result/${el}`);
                          }}
                        >
                          {el}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="results_subsec2_seperator"></div>
                <div className="results_subsec2_content">
                  {
                    {
                      levels: (
                        <LevelV2
                          data={reports}
                          FurtherDataMixPanel={FurtherDataMixPanel}
                          {...{
                            current_report_data,
                            setcurrent_report_data,
                          }}
                        />
                      ),
                      reports: <ReportsV2 data={reports} />,
                      prescriptions: <PrescriptionsV2 data={prescription} />,
                      scans: <ScansV2 data={scans} />,
                      insights: (
                        <>
                          {reports[0]?.cards?.fertility &&
                          reports[0]?.cards?.signs_of_pcos &&
                          reports[0]?.cards?.thyroid_health ? (
                            // false ? (
                            <div className="results_subsec1">
                              {reports[0]?.cards?.fertility && (
                                <LevelResultV3
                                  {...{
                                    list: [reports[0]?.cards?.fertility].filter(
                                      (el) => el
                                    ),
                                    setMiniNavi: setMiniNavi,
                                    setDataSelect: setDataSelect,
                                    FurtherDataMixPanel: FurtherDataMixPanel,
                                    insidetest: false,
                                    title: "",
                                    // age: Math.floor(ageInYears) || 28,
                                    amh: reports[0]?.biomarker?.filter(
                                      (el) => el.biomarkerCode === "AMH"
                                    )[0],
                                    age: reports[0]?.age || 28,
                                  }}
                                />
                              )}
                              {reports[0]?.cards?.signs_of_pcos && (
                                <ListReports
                                  {...{
                                    list: [
                                      reports[0]?.cards?.signs_of_pcos || "",
                                      // current_report_data?.cards?.thyroid_health || "",
                                    ].filter((el) => el),
                                    navto: "pcos",
                                    setMiniNavi: setMiniNavi,
                                    setDataSelect: setDataSelect,
                                    FurtherDataMixPanel: FurtherDataMixPanel,
                                  }}
                                />
                              )}
                              {reports[0]?.cards?.thyroid_health && (
                                <ListReports
                                  {...{
                                    list: [
                                      // current_report_data?.cards?.signs_of_pcos || "",
                                      reports[0]?.cards?.thyroid_health || "",
                                    ].filter((el) => el),
                                    setMiniNavi: setMiniNavi,
                                    navto: "thyroid",
                                    setDataSelect: setDataSelect,
                                    FurtherDataMixPanel: FurtherDataMixPanel,
                                  }}
                                />
                              )}
                            </div>
                          ) : (
                            <div className="level_v2_section">
                              <div className="levelv2_emp_section">
                                <Icons
                                  {...{
                                    img_var: "levels_dummy",
                                  }}
                                />
                                <div className="levelv2_empty_title">
                                  Nothing to show! Your Insights will display
                                  here once available.
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ),
                    }[type]
                  }
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <BottomNav />
    </>
  ) : (
    ""
  );
};
