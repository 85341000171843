import React, { useEffect } from "react";
import Slider from "react-slick";
import { dateFormatter } from "../../component/API/new_api";
import { useRef } from "react";
import { useState } from "react";
import { LevelList } from "../../component/Results/LevelList";
import { Icons } from "../../component/Icons/Icons";

export const LevelV2 = ({
  data,
  FurtherDataMixPanel,
  current_report_data,
  setcurrent_report_data,
}) => {
  // const dates = [new Date(), new Date()];
  const [ArrayForHgighLow, setArrayForHgighLow] = useState([]);
  const [dates, setdates] = useState([]);

  const dateRef = useRef();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: false,
    touchMove: false,
    afterChange: (current) => {
      // console.log(current);
      setcurrent_report_data(data[current]);
    },
  };
  useEffect(() => {
    setdates(
      data.map((el) => new Date(el.date.split("/").reverse().join("/")))
    );
    setcurrent_report_data(data[0]);

    return () => {};
  }, [data]);

  return (
    <div className="level_v2_section">
      {data.length == 0 ? (
        <div className="levelv2_emp_section">
          <Icons
            {...{
              img_var: "levels_dummy",
            }}
          />
          <div className="levelv2_empty_title">
            Nothing to show! Your test results will appear here as soon as
            they’re available.
          </div>
        </div>
      ) : (
        <>
          <div className="level_v2_dates_slider">
            <div
              className="level_v2_arrows left_arr"
              onClick={() => {
                dateRef.current.slickPrev();
              }}
            >
              <svg
                width="6"
                height="8"
                viewBox="0 0 6 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.16593 0.234229C5.3159 0.384251 5.40015 0.587698 5.40015 0.799829C5.40015 1.01196 5.3159 1.21541 5.16593 1.36543L2.53153 3.99983L5.16593 6.63423C5.24233 6.70803 5.30328 6.7963 5.34521 6.89391C5.38713 6.99151 5.4092 7.09648 5.41013 7.20271C5.41105 7.30893 5.39081 7.41427 5.35058 7.51259C5.31036 7.61091 5.25096 7.70023 5.17584 7.77534C5.10073 7.85046 5.01141 7.90986 4.91309 7.95009C4.81477 7.99031 4.70943 8.01055 4.6032 8.00963C4.49698 8.00871 4.39201 7.98664 4.2944 7.94471C4.1968 7.90278 4.10852 7.84184 4.03473 7.76543L0.834726 4.56543C0.684749 4.41541 0.600497 4.21196 0.600497 3.99983C0.600497 3.7877 0.684749 3.58425 0.834726 3.43423L4.03473 0.234229C4.18475 0.0842524 4.38819 0 4.60033 0C4.81246 0 5.0159 0.0842524 5.16593 0.234229Z"
                  fill="#5F1D1B"
                />
              </svg>
            </div>
            <div className="level_v2_dates_slides">
              <Slider {...{ ...settings }} ref={dateRef}>
                {dates.map((el) => (
                  <div>
                    <div className="dates">
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.29167 5.94284H12.625M4.49802 2V3.02869M11.2917 2V3.02857M13.2917 4.82857V12.2C13.2917 13.1941 12.4957 14 11.5139 14H4.40278C3.42094 14 2.625 13.1941 2.625 12.2V4.82857C2.625 3.83445 3.42094 3.02857 4.40278 3.02857H11.5139C12.4957 3.02857 13.2917 3.83445 13.2917 4.82857Z"
                          stroke="#5F1D1B"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      {dateFormatter(el, "MMS DD, YYYY")}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div
              className="level_v2_arrows right_arr"
              onClick={() => {
                dateRef.current.slickNext();
              }}
            >
              <svg
                width="6"
                height="8"
                viewBox="0 0 6 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.834074 7.76577C0.684098 7.61575 0.599845 7.4123 0.599845 7.20017C0.599845 6.98804 0.684098 6.78459 0.834074 6.63457L3.46847 4.00017L0.834074 1.36577C0.757666 1.29197 0.69672 1.2037 0.654793 1.10609C0.612866 1.00849 0.590797 0.903515 0.589874 0.797292C0.588951 0.691069 0.609192 0.585725 0.649417 0.487408C0.689642 0.389091 0.749044 0.29977 0.824158 0.224656C0.899272 0.149541 0.988594 0.0901389 1.08691 0.0499144C1.18523 0.00968933 1.29057 -0.0105524 1.3968 -0.00962925C1.50302 -0.00870609 1.60799 0.0133629 1.7056 0.0552897C1.8032 0.0972171 1.89148 0.158164 1.96527 0.234571L5.16527 3.43457C5.31525 3.58459 5.3995 3.78804 5.3995 4.00017C5.3995 4.2123 5.31525 4.41575 5.16527 4.56577L1.96527 7.76577C1.81525 7.91575 1.61181 8 1.39967 8C1.18754 8 0.984097 7.91575 0.834074 7.76577Z"
                  fill="#5F1D1B"
                />
              </svg>
            </div>
          </div>
          <div>
            <LevelList
              {...{
                title: "",
                titleDate: current_report_data?.date
                  ? new Date(current_report_data?.date)
                  : new Date(),
                unique_id: "list_data",
                screen: "Result",
                FurtherDataMixPanel: FurtherDataMixPanel,
                age: Math.floor(current_report_data?.age || 0),
                firstopen: true,

                list:
                  current_report_data?.biomarker?.map((el, i) => ({
                    ...el,
                    desc: el.value_desc,
                    desc2: el.desc,
                    color: "",
                    count: el.value,

                    score: el?.score
                      ? `${el?.score[0].toUpperCase()}${el?.score.slice(1)}`
                      : "Pending",
                    insights_list: el.insights,
                    range_list: el.labRange,
                  })) || [],
                AccordainWork: false,
                setAccordainWork: () => "",
                setArrayForHgighLow: setArrayForHgighLow,
                ArrayForHgighLow: ArrayForHgighLow,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
