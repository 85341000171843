import React from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import { MixPanelIntake } from "../../../component/API/automation_api";

const Medication = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  goto,
}) => {
  return (
    <>
      <div className="intake_body">
        <div className="container medication_main">
          <div className="medication_title">
            Are you currently on any medication?
          </div>
          <div className="medication_desc">Leave blank if not applicable.</div>
          <textarea
            autoFocus
            placeholder="Type your answer here... "
            className="text_area2"
            rows={15}
            onChange={({ target }) => {
              setPagevalues((prev) => ({
                ...prev,
                medication: target.value,
              }));
            }}
          ></textarea>
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              MixPanelIntake({
                step_name: "Are you currently on any medication?",
                section_name: "Reproductive Health",
                value: pagevalues.medication || "N/A",
                step_index: 7,
                total_steps: 8,
                remaining: lastdata,
              });
              if (singleBeginRange) {
                setRangeCount(5);
              } else {
                setRangeCount(24);
              }

              setPages("socialmedia");
            },
            goBackClick: () => {
              setRangeCount(22);
              setPages("medicalcondition");
            },
          },
        }}
      />
    </>
  );
};

export default Medication;
