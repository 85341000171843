import React, { useEffect, useRef, useState } from "react";
import { Icons } from "../../component/Icons/Icons";
import { dateFormatter } from "../Reports";

export const ReportsV2 = ({ data }) => {
  const [modaldata, setModaldata] = useState({});
  // console.log(data);
  // const handleDownload = (txt) => {
  //   // Create a temporary anchor element
  //   const downloadAnchor = document.createElement("a");
  //   downloadAnchor.href = txt; // URL of the file to download
  //   downloadAnchor.download = txt.split("/").reverse()[0]; // Name for the downloaded file

  //   // Trigger the download by simulating a click on the anchor element
  //   downloadAnchor.click();
  // };
  return (
    <>
      {modaldata?.name ? (
        <div className="reportsv2_modal_section">
          <div>
            <div className="reportsv2_modal_header">
              <div>{modaldata?.name}</div>
              <div
                onClick={() => {
                  setModaldata({});
                }}
                className="reportsv2_modal_header_close"
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.292787 1.29259C0.480314 1.10512 0.734622 0.999806 0.999786 0.999806C1.26495 0.999806 1.51926 1.10512 1.70679 1.29259L5.99979 5.58559L10.2928 1.29259C10.385 1.19708 10.4954 1.1209 10.6174 1.06849C10.7394 1.01608 10.8706 0.988496 11.0034 0.987342C11.1362 0.986189 11.2678 1.01149 11.3907 1.06177C11.5136 1.11205 11.6253 1.18631 11.7192 1.2802C11.8131 1.37409 11.8873 1.48574 11.9376 1.60864C11.9879 1.73154 12.0132 1.86321 12.012 1.99599C12.0109 2.12877 11.9833 2.25999 11.9309 2.382C11.8785 2.504 11.8023 2.61435 11.7068 2.70659L7.41379 6.99959L11.7068 11.2926C11.8889 11.4812 11.9897 11.7338 11.9875 11.996C11.9852 12.2582 11.88 12.509 11.6946 12.6944C11.5092 12.8798 11.2584 12.985 10.9962 12.9873C10.734 12.9895 10.4814 12.8888 10.2928 12.7066L5.99979 8.41359L1.70679 12.7066C1.51818 12.8888 1.26558 12.9895 1.00339 12.9873C0.741188 12.985 0.490376 12.8798 0.304968 12.6944C0.11956 12.509 0.0143906 12.2582 0.0121121 11.996C0.00983372 11.7338 0.110629 11.4812 0.292787 11.2926L4.58579 6.99959L0.292787 2.70659C0.105316 2.51907 0 2.26476 0 1.99959C0 1.73443 0.105316 1.48012 0.292787 1.29259Z"
                    fill="#5F1D1B"
                  />
                </svg>
              </div>
            </div>
            <div className="reportsv2_modal_body">
              <iframe src={`${modaldata?.url}#toolbar=0&navpanes=0`} />
            </div>
            <div className="reportsv2_modal_footer">
              <div className="reportsv2_modal_footer_btn">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 10.0003L12.1667 4.16699V7.50033C6.33333 8.33366 3.83333 12.5003 3 16.667C5.08333 13.7503 8 12.417 12.1667 12.417V15.8337L18 10.0003Z"
                    fill="#5F1D1B"
                  />
                </svg>
                Share
              </div>
              <div
                className="reportsv2_modal_footer_btn download"
                onClick={() => {
                  window.open(modaldata?.url, "_blank");
                  // handleDownload(modaldata?.url);
                }}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5 16.6663H15.5M10.5 3.33301V13.333M10.5 13.333L13.4167 10.4163M10.5 13.333L7.58333 10.4163"
                    stroke="#FFFBF2"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Download
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="reportsv2_section">
        <div className="reportsv2_section_list">
          {data.length == 0 ? (
            <div className="reportsv2_emp_section">
              <Icons
                {...{
                  img_var: "reportsv2_empty",
                }}
              />
              <div className="reportsv2_empty_title">
                Nothing to show! Once your lab reports become available, they
                will be displayed here.
              </div>
            </div>
          ) : (
            data
              .filter((el) => el.report_url)
              .map((item, index) => (
                <div
                  key={index}
                  className="reportsv2_section_box"
                  onClick={() => {
                    setModaldata({
                      name: item?.orderDetails?.productName,
                      url: item?.report_url,
                    });
                  }}
                >
                  <div className="reportsv2_section_box_img">
                    <div>{item?.report_url?.split(".")?.reverse()[0]}</div>
                  </div>
                  <div className="reportsv2_section_box_detail">
                    <div className="reportsv2_section_box_title">
                      {item?.orderDetails?.productName}
                    </div>
                    <div className="reportsv2_section_box_date">
                      {dateFormatter(
                        item?.date?.split("/")?.reverse()?.join("/"),
                        "WKL, MMS DD"
                      )}
                    </div>
                  </div>
                  <div className="reportsv2_section_box_icon">
                    <svg
                      width="6"
                      height="9"
                      viewBox="0 0 6 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.834074 7.76577C0.684098 7.61575 0.599845 7.4123 0.599845 7.20017C0.599845 6.98804 0.684098 6.78459 0.834074 6.63457L3.46847 4.00017L0.834074 1.36577C0.757666 1.29197 0.69672 1.2037 0.654793 1.10609C0.612866 1.00849 0.590797 0.903515 0.589874 0.797292C0.588951 0.691069 0.609192 0.585725 0.649417 0.487408C0.689642 0.389091 0.749044 0.29977 0.824158 0.224656C0.899272 0.149541 0.988594 0.0901389 1.08691 0.0499144C1.18523 0.00968933 1.29057 -0.0105524 1.3968 -0.00962925C1.50302 -0.00870609 1.60799 0.0133629 1.7056 0.0552897C1.8032 0.0972171 1.89148 0.158164 1.96527 0.234571L5.16527 3.43457C5.31525 3.58459 5.3995 3.78804 5.3995 4.00017C5.3995 4.2123 5.31525 4.41575 5.16527 4.56577L1.96527 7.76577C1.81525 7.91575 1.61181 8 1.39967 8C1.18754 8 0.984097 7.91575 0.834074 7.76577Z"
                        fill="#5F1D1B"
                      />
                    </svg>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>
    </>
  );
};
