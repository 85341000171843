import React from "react";
import { Fertilty } from "../../component/Results/Fertilty";
import { Pcos } from "../../component/Results/Pcos";
import { ThyroidHealth } from "../../component/Results/ThyroidHealth";
import { useNavigate, useParams } from "react-router-dom";

export const ViewTests = ({
  MiniNavi,
  setMiniNavi,
  AllData,
  DataSelect,
  FurtherDataMixPanel,
  reports,
}) => {
  const nav = useNavigate();
  const { type } = useParams();
  const typeKeywords = {
    fertility: "fertility",
    pcos: "signs_of_pcos",
    thyroid: "thyroid_health",
  };
  //   const selectedComponent = reports?.filter((el) => {
  //     const keyword = typeKeywords[type.toLowerCase()];
  //     return el?.name?.includes(keyword);
  //   })[0];
  const selectedComponent = reports[0]?.cards[typeKeywords[type.toLowerCase()]];
  return (
    <div className="main_reports_insights">
      <div
        className="result_back_btn"
        onClick={() => {
          setMiniNavi(false);
          nav(-1);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
            stroke="#5f1d1b"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {selectedComponent.name}
      </div>
      <div>
        {selectedComponent.name.includes("Fertility (Egg Count)") ? (
          <Fertilty
            {...{
              PageValue: AllData,
              data: selectedComponent,
              DataSelect: DataSelect,
              // filteredData :
              list: [],
              FurtherDataMixPanel: FurtherDataMixPanel,
              // amh
              amh: reports[0].biomarker.filter(
                (el) => el.biomarkerCode === "AMH"
              )[0],
              age: reports[0]?.age || 0,
              allreport: reports,
              reports: [reports[0]],
            }}
          />
        ) : (
          ""
        )}
      </div>
      <div>
        {selectedComponent.name === "Signs of PCOS" ? (
          <Pcos
            {...{
              PageValue: AllData,
              data: selectedComponent,
              DataSelect: DataSelect,
              FurtherDataMixPanel: FurtherDataMixPanel,
              allreport: reports,
              reports: [reports[0]],
            }}
          />
        ) : (
          ""
        )}
      </div>
      <div>
        {selectedComponent.name === "Thyroid Health" ? (
          <ThyroidHealth
            {...{
              PageValue: AllData,
              data: selectedComponent,
              DataSelect: DataSelect,
              FurtherDataMixPanel: FurtherDataMixPanel,
              allreport: reports,
              reports: [reports[0]],
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
