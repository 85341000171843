import React, { useEffect, useReducer, useRef } from "react";

import BottomBtnSection from "../components/BottomBtnSection";
import { MixPanelIntake } from "../../../component/API/automation_api";

export const Medicalcondition = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  goto,
}) => {
  const textarea = useRef();
  useEffect(() => {
    textarea?.current?.focus();
  }, []);

  return (
    <>
      <div className="intake_body">
        <div className="container medicalcondition_main">
          <div className="medicalcondition_title">
            Do you have any medical conditions that we should know about?
          </div>
          <div className="medicalcondition_desc">
            This can include diabetes, unexplained fertility, amenhorrea,
            anxiety, diminished ovarian reserve... and so on
          </div>
          <textarea
            autoFocus
            placeholder="Type your answer here... "
            className={
              "text_area1 " +
              (err_pagevalues.medicalcondition ? " error_textarae" : "")
            }
            rows={15}
            onChange={({ target }) => {
              setPagevalues((prev) => ({
                ...prev,
                medicalcondition: target.value,
              }));
              seterr_pagevalues((prev) => ({
                ...prev,
                medicalcondition: !target.value,
              }));
            }}
          ></textarea>
        </div>
      </div>

      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              // if (!pagevalues.medicalcondition) {
              //   seterr_pagevalues((prev) => ({
              //     ...prev,
              //     medicalcondition: !pagevalues.medicalcondition,
              //   }));
              //   return;
              // }
              MixPanelIntake({
                step_name:
                  "Do you have any medical conditions that we should know about?",
                section_name: "Reproductive Health",
                value: pagevalues.medicalcondition || "N/A",
                step_index: 6,
                total_steps: 8,
                remaining: lastdata,
              });
              if (singleBeginRange) {
                setRangeCount(4);
              } else {
                setRangeCount(23);
              }
              setPages("medication");
            },
            goBackClick: () => {
              setRangeCount(21);
              setPages("birthcontrol");
            },
          },
        }}
      />
    </>
  );
};
