import React from "react";
import { date_formatter } from "../component/CustInput/CustInput";
import { OrderReminder } from "../pages/Orders/OrderReminder";

export const utilsArray = (type) => {
  let tarray = {
    status: {
      0: "",
      1: "Lead generated",
      2: "Test scheduled",
      3: "Awaiting period",
      4: "Technician assigned",
      5: "Sample collected",
      6: "Missing data",
      7: "Data uploaded",
      8: "Physician approval",
      9: "Order complete",
      10: "Canceled",
    },
    priority: {
      0: ["", "", ""],
      1: ["Low", "#FEF3C7", "#F59E0B"],
      2: ["Medium", "#FFEDD5", "#F97316"],
      3: ["High", "#FEE2E2", "#EF4444"],
      4: ["Semi High", "#FAE8FF", "#D946EF"],
    },
    //["name","bg_clr","txt_clr"]
    dr_priority: {
      1: ["Done", "#DFFFD3", "#64BE45"],
      2: ["Delayed", "#FEE2E2", "#EF4444"],
      3: ["Today", "#E2F9FE", "#446AEF"],
      4: ["Scheduled", "#FFEDD5", "#F97316"],
      5: ["Notified", "#FEF3C7", "#F59E0B"],
    },
  };
  const array = {
    testing: false,
    ...tarray,
    goal: [
      "Just curious to learn about my fertility",
      "I’m exploring options like Egg Freezing",
      "I want kids but I won’t get started for at least another year",
      "I’m trying for kids as we speak (without assistance)",
      "I’m trying for kids as we speak (with fertility treatment)",
      "I don’t want biological kids. I’m here to learn more about my body.",
    ],
    pintostate: [
      { state: "JAMMU AND KASHMIR", pincode: { start: 180, end: 194 } },
      { state: "HIMACHAL PRADESH", pincode: { start: 171, end: 177 } },
      { state: "PUNJAB", pincode: { start: 140, end: 160 } },
      { state: "CHANDIGARH", pincode: { start: 160, end: 160 } },
      { state: "UTTARAKHAND", pincode: { start: 244, end: 263 } },
      { state: "HARYANA", pincode: { start: 121, end: 136 } },
      { state: "DELHI", pincode: { start: 110, end: 110 } },
      { state: "RAJASTHAN", pincode: { start: 301, end: 345 } },
      { state: "UTTAR PRADESH", pincode: { start: 201, end: 285 } },
      { state: "BIHAR", pincode: { start: 800, end: 855 } },
      { state: "SIKKIM", pincode: { start: 737, end: 737 } },
      { state: "ARUNACHAL PRADESH", pincode: { start: 790, end: 792 } },
      { state: "NAGALAND", pincode: { start: 797, end: 798 } },
      { state: "MANIPUR", pincode: { start: 795, end: 795 } },
      { state: "MIZORAM", pincode: { start: 796, end: 796 } },
      { state: "TRIPURA", pincode: { start: 799, end: 799 } },
      { state: "MEGHALAYA", pincode: { start: 793, end: 794 } },
      { state: "ASSAM", pincode: { start: 781, end: 788 } },
      { state: "WEST BENGAL", pincode: { start: 700, end: 743 } },
      { state: "JHARKHAND", pincode: { start: 813, end: 835 } },
      { state: "ODISHA", pincode: { start: 751, end: 770 } },
      { state: "CHHATTISGARH", pincode: { start: 490, end: 497 } },
      { state: "MADHYA PRADESH", pincode: { start: 450, end: 488 } },
      { state: "GUJARAT", pincode: { start: 360, end: 396 } },
      { state: "DAMAN AND DIU", pincode: { start: 362, end: 362 } },
      { state: "DAMAN AND DIU", pincode: { start: 396, end: 396 } },
      { state: "DADRA AND NAGAR HAVELI", pincode: { start: 396, end: 396 } },
      { state: "MAHARASHTRA", pincode: { start: 400, end: 445 } },
      { state: "KARNATAKA", pincode: { start: 560, end: 591 } },
      { state: "GOA", pincode: { start: 403, end: 403 } },
      { state: "LAKSHADWEEP", pincode: { start: 682, end: 682 } },
      { state: "KERALA", pincode: { start: 670, end: 695 } },
      { state: "TAMIL NADU", pincode: { start: 600, end: 643 } },
      { state: "PUDUCHERRY", pincode: { start: 533, end: 533 } },
      { state: "PUDUCHERRY", pincode: { start: 605, end: 605 } },
      { state: "PUDUCHERRY", pincode: { start: 607, end: 607 } },
      { state: "PUDUCHERRY", pincode: { start: 609, end: 609 } },
      { state: "ANDAMAN AND NICOBAR", pincode: { start: 744, end: 744 } },
      { state: "TELANGANA", pincode: { start: 500, end: 509 } },
      { state: "ANDHRA PRADESH", pincode: { start: 500, end: 535 } },
    ],
    // cohortList: [
    //   {
    //     name: "Just curious to learn about my fertility",
    //     link: "curious-about-fertility",
    //   },
    //   {
    //     name: "I’m exploring options like Egg Freezing",
    //     link: "exploring-egg-freezing",
    //   },
    //   {
    //     name: "I want kids but I won’t get started for at least another year",
    //     link: "planning-for-kids",
    //   },
    //   {
    //     name: "I’m trying for kids as we speak (without assistance)",
    //     link: "trying-for-kids",
    //   },
    //   {
    //     name: "I’m trying for kids as we speak (with fertility treatment)",
    //     link: "trying-with-fertility-treatment",
    //   },
    //   {
    //     name: "I don’t want biological kids. I’m here to learn more about my body.",
    //     link: "doesnt-want-kids",
    //   },
    // ],
    cohortList: {
      "Just curious to learn about my fertility": "curious-about-fertility",

      "I’m exploring options like Egg Freezing": "exploring-egg-freezing",

      "I want kids but I won’t get started for at least another year":
        "planning-for-kids",

      "I’m trying for kids as we speak (without assistance)": "trying-for-kids",

      "I’m trying for kids as we speak (with fertility treatment)":
        "trying-with-fertility-treatment",

      "I don’t want biological kids. I’m here to learn more about my body.":
        "doesnt-want-kids",
    },

    history: {
      OrderGenerated: { run: () => "Order generated" },
      CommingPeriod: {
        run: (obj) =>
          `Period reminder scheduled for ${date_formatter(
            new Date(obj?.date_time),
            "date mo_short year_long"
          )}`,
      },
      "OrderReminder-Updated": {
        run: (obj) =>
          `Reminder Updated to ${date_formatter(
            new Date(obj?.to.comming_period),
            "date mo_short year_long"
          )}`,
      },
      CollectionDate: {
        run: (obj) =>
          obj.err ? (
            <div
              style={{ color: "red" }}
            >{`Sample collection couldn't be scheduled ${date_formatter(
              new Date(obj?.date_time?.date),
              "date mo_short year_long"
            )} at ${obj?.date_time?.time?.slot?.split("-")[0]}`}</div>
          ) : (
            `Sample collection scheduled for ${date_formatter(
              new Date(obj?.date_time?.date),
              "date mo_short year_long"
            )} at ${obj?.date_time?.time?.slot?.split("-")[0]}`
          ),
      },
      "ReBookSlot-CommingPeriod": {
        run: (obj) =>
          `Period reminder scheduled for ${date_formatter(
            new Date(obj?.to?.comming_period),
            "date mo_short year_long"
          )}`,
      },
      "ReBookSlot-CollectionDate": {
        run: (obj) =>
          obj.err ? (
            <div
              style={{ color: "red" }}
            >{`Sample collection couldn't be scheduled ${date_formatter(
              new Date(obj?.to?.data?.bookingData?.date),
              "date mo_short year_long"
            )} at ${
              obj?.to?.data?.bookingData?.time?.slot?.split("-")[0]
            }`}</div>
          ) : (
            `Sample collection scheduled for ${date_formatter(
              new Date(obj?.to?.data?.bookingData?.date),
              "date mo_short year_long"
            )} at ${obj?.to?.data?.bookingData?.time?.slot?.split("-")[0]}`
          ),
      },
      "BookSlot-CommingPeriod": {
        run: (obj) =>
          `Period reminder scheduled for ${date_formatter(
            new Date(obj?.to?.comming_period),
            "date mo_short year_long"
          )}`,
      },
      "BookSlot-CollectionDate": {
        run: (obj) =>
          obj.err ? (
            <div
              style={{ color: "red" }}
            >{`Sample collection couldn't be scheduled ${date_formatter(
              new Date(obj?.to?.data?.bookingData?.date),
              "date mo_short year_long"
            )} at ${
              obj?.to?.data?.bookingData?.time?.slot?.split("-")[0]
            }`}</div>
          ) : (
            `Sample collection scheduled for ${date_formatter(
              new Date(obj?.to?.data?.bookingData?.date),
              "date mo_short year_long"
            )} at ${obj?.to?.data?.bookingData?.time?.slot?.split("-")[0]}`
          ),
      },
      AssignExpert: {
        run: (obj) => "Assign Expert",
      },
      AssignTeamCall: {
        run: (obj) => "Assign Team Call",
      },
      OrderCancel: {
        run: (obj) => "Order Cancel",
      },
      StatusChanged: {
        run: (obj) => {
          if (obj.status.to != obj.status.from) {
            if (obj.priority.to != obj.priority.from) {
              return `${tarray.status[obj.status.from]}(${
                tarray.priority[obj.priority.from][0]
              }) to ${tarray.status[obj.status.to]}(${
                tarray.priority[obj.priority.to][0]
              })`;
            } else {
              return `${tarray.status[obj.status.from]}(${
                tarray.priority[obj.priority.from][0]
              }) to ${tarray.status[obj.status.to]}(${
                tarray.priority[obj.priority.from][0]
              })`;
            }
          } else {
            if (obj.priority.to != obj.priority.from) {
              return `${tarray.status[obj.status.from]} - ${
                tarray.priority[obj.priority.from][0]
              } to ${tarray.priority[obj.priority.to][0]}`;
            } else {
              return `No Status changed`;
            }
          }
        },
      },
      OrderPublished: {
        run: (obj) => "Order Published",
      },
    },
    rating_list: [
      {
        title: "We deserve to know about this",
        desc: "I feel like it’s important for women to know what’s going on with their own bodies without the pressure of whether they’re planning on having a family or not.",
        name: "Name",
        stars: (
          <div style={{ display: "flex", gap: "0 4px", alignItems: "center" }}>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        ),
      },
      {
        title: "I had a brilliant experience with Arva!",
        desc: "The concept of the start up normalised the whole conversation around fertility for me, which I realised was my main motivation to take the hormone test (also it’s super convenient!) to begin with. ",
        name: "Ankita",
        stars: (
          <div style={{ display: "flex", gap: "0 4px", alignItems: "center" }}>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        ),
      },
      {
        title: "Wish I did this sooner",
        desc: "I learnt so much about my body that I had no idea about before taking this test! I wish I did it sooner so I could’ve taken better control. I’m finally planning my fertility now that I know where I stand. ",
        name: "Name",
        stars: (
          <div style={{ display: "flex", gap: "0 4px", alignItems: "center" }}>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2730_969)">
                <path
                  d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                  fill="#FFA977"
                  stroke="#FFA977"
                />
              </g>
              <defs>
                <clipPath id="clip0_2730_969">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        ),
      },
    ],

    phone_formater: (phone) => {
      return phone.toString().replaceAll(" ", "").slice(-10);
    },
    // {
    //   name: "Low",
    //   color: "#FEE2E2",
    //   svgcolor: "#EF4444",
    //   active: false,
    //   id: 1,
    // },
    // {
    //   name: "Medium",
    //   color: "#FFEDD5",
    //   svgcolor: "#F97316",
    //   active: false,
    //   id: 2,
    // },
    // {
    //   name: "High",
    //   color: "#FEF3C7",
    //   svgcolor: "#F59E0B",
    //   active: false,
    //   id: 3,
    // },
  };

  if (type) {
    return array[type];
  }
  return "No Data";
};
export const flags = [
  {
    flag_code: "in",
    code: "+91",
    name: "India",
  },
  {
    flag_code: "us",
    code: "+1",
    name: "United States",
  },
  {
    flag_code: "af",
    code: "+93",
    name: "Afghanistan",
  },
  {
    flag_code: "al",
    code: "+355",
    name: "Albania",
  },
  {
    flag_code: "dz",
    code: "+213",
    name: "Algeria",
  },
  {
    flag_code: "ad",
    code: "+376",
    name: "Andorra",
  },
  {
    flag_code: "ao",
    code: "+244",
    name: "Angola",
  },
  {
    flag_code: "ag",
    code: "+1268",
    name: "Antigua and Barbuda",
  },
  {
    flag_code: "ar",
    code: "+54",
    name: "Argentina",
  },
  {
    flag_code: "am",
    code: "+374",
    name: "Armenia",
  },
  {
    flag_code: "aw",
    code: "+297",
    name: "Aruba",
  },
  {
    flag_code: "au",
    code: "+61",
    name: "Australia",
  },
  {
    flag_code: "at",
    code: "+43",
    name: "Austria",
  },
  {
    flag_code: "az",
    code: "+994",
    name: "Azerbaijan",
  },
  {
    flag_code: "bs",
    code: "+1242",
    name: "Bahamas",
  },
  {
    flag_code: "bh",
    code: "+973",
    name: "Bahrain",
  },
  {
    flag_code: "bd",
    code: "+880",
    name: "Bangladesh",
  },
  {
    flag_code: "bb",
    code: "+1246",
    name: "Barbados",
  },
  {
    flag_code: "by",
    code: "+375",
    name: "Belarus",
  },
  {
    flag_code: "be",
    code: "+32",
    name: "Belgium",
  },
  {
    flag_code: "bz",
    code: "+501",
    name: "Belize",
  },
  {
    flag_code: "bj",
    code: "+229",
    name: "Benin",
  },
  {
    flag_code: "bt",
    code: "+975",
    name: "Bhutan",
  },
  {
    flag_code: "bo",
    code: "+591",
    name: "Bolivia",
  },
  {
    flag_code: "ba",
    code: "+387",
    name: "Bosnia and Herzegovina",
  },
  {
    flag_code: "bw",
    code: "+267",
    name: "Botswana",
  },
  {
    flag_code: "br",
    code: "+55",
    name: "Brazil",
  },
  {
    flag_code: "io",
    code: "+246",
    name: "British Indian Ocean Territory",
  },
  {
    flag_code: "bn",
    code: "+673",
    name: "Brunei",
  },
  {
    flag_code: "bg",
    code: "+359",
    name: "Bulgaria",
  },
  {
    flag_code: "bf",
    code: "+226",
    name: "Burkina Faso",
  },
  {
    flag_code: "bi",
    code: "+257",
    name: "Burundi",
  },
  {
    flag_code: "kh",
    code: "+855",
    name: "Cambodia",
  },
  {
    flag_code: "cm",
    code: "+237",
    name: "Cameroon",
  },
  {
    flag_code: "ca",
    code: "+1",
    name: "Canada",
  },
  {
    flag_code: "cv",
    code: "+238",
    name: "Cape Verde",
  },
  {
    flag_code: "bq",
    code: "+599",
    name: "Caribbean Netherlands",
  },
  {
    flag_code: "cf",
    code: "+236",
    name: "Central African Republic",
  },
  {
    flag_code: "td",
    code: "+235",
    name: "Chad",
  },
  {
    flag_code: "cl",
    code: "+56",
    name: "Chile",
  },
  {
    flag_code: "cn",
    code: "+86",
    name: "China",
  },
  {
    flag_code: "co",
    code: "+57",
    name: "Colombia",
  },
  {
    flag_code: "km",
    code: "+269",
    name: "Comoros",
  },
  {
    flag_code: "cd",
    code: "+243",
    name: "Congo",
  },
  {
    flag_code: "cg",
    code: "+242",
    name: "Congo",
  },
  {
    flag_code: "cr",
    code: "+506",
    name: "Costa Rica",
  },
  {
    flag_code: "ci",
    code: "+225",
    name: "Côte d’Ivoire",
  },
  {
    flag_code: "hr",
    code: "+385",
    name: "Croatia",
  },
  {
    flag_code: "cu",
    code: "+53",
    name: "Cuba",
  },
  {
    flag_code: "cw",
    code: "+599",
    name: "Curaçao",
  },
  {
    flag_code: "cy",
    code: "+357",
    name: "Cyprus",
  },
  {
    flag_code: "cz",
    code: "+420",
    name: "Czech Republic",
  },
  {
    flag_code: "dk",
    code: "+45",
    name: "Denmark",
  },
  {
    flag_code: "dj",
    code: "+253",
    name: "Djibouti",
  },
  {
    flag_code: "dm",
    code: "+1767",
    name: "Dominica",
  },
  {
    flag_code: "do",
    code: "+1",
    name: "Dominican Republic",
  },
  {
    flag_code: "ec",
    code: "+593",
    name: "Ecuador",
  },
  {
    flag_code: "eg",
    code: "+20",
    name: "Egypt",
  },
  {
    flag_code: "sv",
    code: "+503",
    name: "El Salvador",
  },
  {
    flag_code: "gq",
    code: "+240",
    name: "Equatorial Guinea",
  },
  {
    flag_code: "er",
    code: "+291",
    name: "Eritrea",
  },
  {
    flag_code: "ee",
    code: "+372",
    name: "Estonia",
  },
  {
    flag_code: "et",
    code: "+251",
    name: "Ethiopia",
  },
  {
    flag_code: "fj",
    code: "+679",
    name: "Fiji",
  },
  {
    flag_code: "fi",
    code: "+358",
    name: "Finland",
  },
  {
    flag_code: "fr",
    code: "+33",
    name: "France",
  },
  {
    flag_code: "gf",
    code: "+594",
    name: "French Guiana",
  },
  {
    flag_code: "pf",
    code: "+689",
    name: "French Polynesia",
  },
  {
    flag_code: "ga",
    code: "+241",
    name: "Gabon",
  },
  {
    flag_code: "gm",
    code: "+220",
    name: "Gambia",
  },
  {
    flag_code: "ge",
    code: "+995",
    name: "Georgia",
  },
  {
    flag_code: "de",
    code: "+49",
    name: "Germany",
  },
  {
    flag_code: "gh",
    code: "+233",
    name: "Ghana",
  },
  {
    flag_code: "gr",
    code: "+30",
    name: "Greece",
  },
  {
    flag_code: "gd",
    code: "+1473",
    name: "Grenada",
  },
  {
    flag_code: "gp",
    code: "+590",
    name: "Guadeloupe",
  },
  {
    flag_code: "gu",
    code: "+1671",
    name: "Guam",
  },
  {
    flag_code: "gt",
    code: "+502",
    name: "Guatemala",
  },
  {
    flag_code: "gn",
    code: "+224",
    name: "Guinea",
  },
  {
    flag_code: "gw",
    code: "+245",
    name: "Guinea-Bissau",
  },
  {
    flag_code: "gy",
    code: "+592",
    name: "Guyana",
  },
  {
    flag_code: "ht",
    code: "+509",
    name: "Haiti",
  },
  {
    flag_code: "hn",
    code: "+504",
    name: "Honduras",
  },
  {
    flag_code: "hk",
    code: "+852",
    name: "Hong Kong",
  },
  {
    flag_code: "hu",
    code: "+36",
    name: "Hungary",
  },
  {
    flag_code: "is",
    code: "+354",
    name: "Iceland",
  },

  {
    flag_code: "id",
    code: "+62",
    name: "Indonesia",
  },
  {
    flag_code: "ir",
    code: "+98",
    name: "Iran",
  },
  {
    flag_code: "iq",
    code: "+964",
    name: "Iraq",
  },
  {
    flag_code: "ie",
    code: "+353",
    name: "Ireland",
  },
  {
    flag_code: "il",
    code: "+972",
    name: "Israel",
  },
  {
    flag_code: "it",
    code: "+39",
    name: "Italy",
  },
  {
    flag_code: "jm",
    code: "+1876",
    name: "Jamaica",
  },
  {
    flag_code: "jp",
    code: "+81",
    name: "Japan",
  },
  {
    flag_code: "jo",
    code: "+962",
    name: "Jordan",
  },
  {
    flag_code: "kz",
    code: "+7",
    name: "Kazakhstan",
  },
  {
    flag_code: "ke",
    code: "+254",
    name: "Kenya",
  },
  {
    flag_code: "ki",
    code: "+686",
    name: "Kiribati",
  },
  {
    flag_code: "xk",
    code: "+383",
    name: "Kosovo",
  },
  {
    flag_code: "kw",
    code: "+965",
    name: "Kuwait",
  },
  {
    flag_code: "kg",
    code: "+996",
    name: "Kyrgyzstan",
  },
  {
    flag_code: "la",
    code: "+856",
    name: "Laos",
  },
  {
    flag_code: "lv",
    code: "+371",
    name: "Latvia",
  },
  {
    flag_code: "lb",
    code: "+961",
    name: "Lebanon",
  },
  {
    flag_code: "ls",
    code: "+266",
    name: "Lesotho",
  },
  {
    flag_code: "lr",
    code: "+231",
    name: "Liberia",
  },
  {
    flag_code: "ly",
    code: "+218",
    name: "Libya",
  },
  {
    flag_code: "li",
    code: "+423",
    name: "Liechtenstein",
  },
  {
    flag_code: "lt",
    code: "+370",
    name: "Lithuania",
  },
  {
    flag_code: "lu",
    code: "+352",
    name: "Luxembourg",
  },
  {
    flag_code: "mo",
    code: "+853",
    name: "Macau",
  },
  {
    flag_code: "mk",
    code: "+389",
    name: "Macedonia",
  },
  {
    flag_code: "mg",
    code: "+261",
    name: "Madagascar",
  },
  {
    flag_code: "mw",
    code: "+265",
    name: "Malawi",
  },
  {
    flag_code: "my",
    code: "+60",
    name: "Malaysia",
  },
  {
    flag_code: "mv",
    code: "+960",
    name: "Maldives",
  },
  {
    flag_code: "ml",
    code: "+223",
    name: "Mali",
  },
  {
    flag_code: "mt",
    code: "+356",
    name: "Malta",
  },
  {
    flag_code: "mh",
    code: "+692",
    name: "Marshall Islands",
  },
  {
    flag_code: "mq",
    code: "+596",
    name: "Martinique",
  },
  {
    flag_code: "mr",
    code: "+222",
    name: "Mauritania",
  },
  {
    flag_code: "mu",
    code: "+230",
    name: "Mauritius",
  },
  {
    flag_code: "mx",
    code: "+52",
    name: "Mexico",
  },
  {
    flag_code: "fm",
    code: "+691",
    name: "Micronesia",
  },
  {
    flag_code: "md",
    code: "+373",
    name: "Moldova",
  },
  {
    flag_code: "mc",
    code: "+377",
    name: "Monaco",
  },
  {
    flag_code: "mn",
    code: "+976",
    name: "Mongolia",
  },
  {
    flag_code: "me",
    code: "+382",
    name: "Montenegro",
  },
  {
    flag_code: "ma",
    code: "+212",
    name: "Morocco",
  },
  {
    flag_code: "mz",
    code: "+258",
    name: "Mozambique",
  },
  {
    flag_code: "mm",
    code: "+95",
    name: "Myanmar",
  },
  {
    flag_code: "na",

    code: "+264",
    name: "Namibia",
  },
  {
    flag_code: "nr",
    code: "+674",
    name: "Nauru",
  },
  {
    flag_code: "np",
    code: "+977",
    name: "Nepal",
  },
  {
    flag_code: "nl",
    code: "+31",
    name: "Netherlands",
  },
  {
    flag_code: "nc",
    code: "+687",
    name: "New Caledonia",
  },
  {
    flag_code: "nz",
    code: "+64",
    name: "New Zealand",
  },
  {
    flag_code: "ni",
    code: "+505",
    name: "Nicaragua",
  },
  {
    flag_code: "ne",
    code: "+227",
    name: "Niger",
  },
  {
    flag_code: "ng",
    code: "+234",
    name: "Nigeria",
  },
  {
    flag_code: "kp",
    code: "+850",
    name: "North Korea",
  },
  {
    flag_code: "no",
    code: "+47",
    name: "Norway",
  },
  {
    flag_code: "om",
    code: "+968",
    name: "Oman",
  },
  {
    flag_code: "pk",
    code: "+92",
    name: "Pakistan",
  },
  {
    flag_code: "pw",
    code: "+680",
    name: "Palau",
  },
  {
    flag_code: "ps",
    code: "+970",
    name: "Palestine",
  },
  {
    flag_code: "pa",
    code: "+507",
    name: "Panama",
  },
  {
    flag_code: "pg",
    code: "+675",
    name: "Papua New Guinea",
  },
  {
    flag_code: "py",
    code: "+595",
    name: "Paraguay",
  },
  {
    flag_code: "pe",
    code: "+51",
    name: "Peru",
  },
  {
    flag_code: "ph",
    code: "+63",
    name: "Philippines",
  },
  {
    flag_code: "pl",
    code: "+48",
    name: "Poland",
  },
  {
    flag_code: "pt",
    code: "+351",
    name: "Portugal",
  },
  {
    flag_code: "pr",
    code: "+1",
    name: "Puerto Rico",
  },
  {
    flag_code: "qa",
    code: "+974",
    name: "Qatar",
  },
  {
    flag_code: "re",
    code: "+262",
    name: "Réunion",
  },
  {
    flag_code: "ro",
    code: "+40",
    name: "Romania",
  },
  {
    flag_code: "ru",
    code: "+7",
    name: "Russia",
  },
  {
    flag_code: "rw",
    code: "+250",
    name: "Rwanda",
  },
  {
    flag_code: "kn",
    code: "+1869",
    name: "Saint Kitts and Nevis",
  },
  {
    flag_code: "lc",
    code: "+1758",
    name: "Saint Lucia",
  },
  {
    flag_code: "vc",
    code: "+1784",
    name: "Saint Vincent and the Grenadines",
  },
  {
    flag_code: "ws",
    code: "+685",
    name: "Samoa",
  },
  {
    flag_code: "sm",
    code: "+378",
    name: "San Marino",
  },
  {
    flag_code: "st",
    code: "+239",
    name: "São Tomé and Príncipe",
  },
  {
    flag_code: "sa",
    code: "+966",
    name: "Saudi Arabia",
  },
  {
    flag_code: "sn",
    code: "+221",
    name: "Senegal",
  },
  {
    flag_code: "rs",
    code: "+381",
    name: "Serbia",
  },
  {
    flag_code: "sc",
    code: "+248",
    name: "Seychelles",
  },
  {
    flag_code: "sl",
    code: "+232",
    name: "Sierra Leone",
  },
  {
    flag_code: "sg",
    code: "+65",
    name: "Singapore",
  },
  {
    flag_code: "sk",
    code: "+421",
    name: "Slovakia",
  },
  {
    flag_code: "si",
    code: "+386",
    name: "Slovenia",
  },
  {
    flag_code: "sb",
    code: "+677",
    name: "Solomon Islands",
  },
  {
    flag_code: "so",
    code: "+252",
    name: "Somalia",
  },
  {
    flag_code: "za",
    code: "+27",
    name: "South Africa",
  },
  {
    flag_code: "kr",
    code: "+82",
    name: "South Korea",
  },
  {
    flag_code: "ss",
    code: "+211",
    name: "South Sudan",
  },
  {
    flag_code: "es",
    code: "+34",
    name: "Spain",
  },
  {
    flag_code: "lk",
    code: "+94",
    name: "Sri Lanka",
  },
  {
    flag_code: "sd",
    code: "+249",
    name: "Sudan",
  },
  {
    flag_code: "sr",
    code: "+597",
    name: "Suriname",
  },
  {
    flag_code: "sz",
    code: "+268",
    name: "Swaziland",
  },
  {
    flag_code: "se",
    code: "+46",
    name: "Sweden",
  },
  {
    flag_code: "ch",
    code: "+41",
    name: "Switzerland",
  },
  {
    flag_code: "sy",
    code: "+963",
    name: "Syria",
  },
  {
    flag_code: "tw",
    code: "+886",
    name: "Taiwan",
  },
  {
    flag_code: "tj",
    code: "+992",
    name: "Tajikistan",
  },
  {
    flag_code: "tz",
    code: "+255",
    name: "Tanzania",
  },
  {
    flag_code: "th",
    code: "+66",
    name: "Thailand",
  },
  {
    flag_code: "tl",
    code: "+670",
    name: "Timor-Leste",
  },
  {
    flag_code: "tg",
    code: "+228",
    name: "Togo",
  },
  {
    flag_code: "to",
    code: "+676",
    name: "Tonga",
  },
  {
    flag_code: "tt",
    code: "+1868",
    name: "Trinidad and Tobago",
  },
  {
    flag_code: "tn",
    code: "+216",
    name: "Tunisia",
  },
  {
    flag_code: "tr",
    code: "+90",
    name: "Turkey",
  },
  {
    flag_code: "tm",
    code: "+993",
    name: "Turkmenistan",
  },
  {
    flag_code: "tv",
    code: "+688",
    name: "Tuvalu",
  },
  {
    flag_code: "ug",
    code: "+256",
    name: "Uganda",
  },
  {
    flag_code: "ua",
    code: "+380",
    name: "Ukraine",
  },
  {
    flag_code: "ae",
    code: "+971",
    name: "United Arab Emirates",
  },
  {
    flag_code: "gb",
    code: "+44",
    name: "United Kingdom",
  },
  {
    flag_code: "uy",
    code: "+598",
    name: "Uruguay",
  },
  {
    flag_code: "uz",
    code: "+998",
    name: "Uzbekistan",
  },
  {
    flag_code: "vu",
    code: "+678",
    name: "Vanuatu",
  },
  {
    flag_code: "va",
    code: "+39",
    name: "Vatican City",
  },
  {
    flag_code: "ve",
    code: "+58",
    name: "Venezuela",
  },
  {
    flag_code: "vn",
    code: "+84",
    name: "Vietnam",
  },
  {
    flag_code: "ye",
    code: "+967",
    name: "Yemen",
  },
  {
    flag_code: "zm",
    code: "+260",
    name: "Zambia",
  },
  {
    flag_code: "zw",
    code: "+263",
    name: "Zimbabwe",
  },
];
// if(customerState.toLowerCase() == "karnataka"){
//   // Consult - Rishina Bansal
// }
// else if(customerState.toLowerCase() == "maharashtra"){
//   // Consult - Dr. Aditi Tandon
// }
// else{
//   // Egg Freezing Consult - Rishina
//   // Else - Dr. Aditi Tandon
// }

const calendlyUrlByPerson = [
  {
    name: "Rishina Bansal",
    link: "https://calendly.com/rishina/fertility-health-consultation",
  },
  {
    name: "Dr. Aditi Tandon",
    link: "https://calendly.com/dradititandon/fertility-health-consultation",
  },
].reduce(
  (acc, curr) => ({
    ...acc,
    [curr.name.toLowerCase()]: curr,
  }),
  {
    "dr. shivani fhc": {
      name: "Dr. Shivani",
      // link: "https://calendly.com/drshivani-arva/fertility-consultation",
      link: "https://calendly.com/d/cqcf-hw7-tsp/fertility-health-consultation",
    },
    "dr. shivani efc": {
      name: "Dr. Shivani",
      link: "https://calendly.com/d/cmhh-qpj-czb/egg-freezing-consultation",
    },
  }
);
let calendlyDataobj = {
  arvaonplum100_dc01: calendlyUrlByPerson["dr. shivani fhc"],
  arvaonplum100_dc02: calendlyUrlByPerson["dr. shivani efc"],
  arvaongx20_dc01: calendlyUrlByPerson["dr. shivani fhc"],
  arvaongx20_dc02: calendlyUrlByPerson["dr. shivani efc"],
  // discount99: calendlyUrlByPerson["dipalie"],
  karnataka: calendlyUrlByPerson["rishina bansal"],
  maharashtra: calendlyUrlByPerson["dr. aditi tandon"],
  dc02: calendlyUrlByPerson["rishina bansal"],
};
export const getCalendlyUrl = (txt, state, sku) => {
  console.log(txt);

  return (
    calendlyDataobj[txt.trim().toLowerCase()] ||
    calendlyDataobj[state.trim().toLowerCase()] ||
    calendlyDataobj[sku.trim().toLowerCase()] ||
    calendlyUrlByPerson["dr. aditi tandon"]
  );
};
